import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, map, mergeMap, retryWhen } from 'rxjs/operators';
import { LoadingWidgetService } from '../widgets/loading-widget/loading-widget.service';
import { environment } from '../../environments/environment';
import { select, Store } from '@ngrx/store';
import { base64ToArrayBuffer } from '../../app/widgets/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class WebClaimsFormService {
  apiToken: any;
  constructor(
    private _http: HttpClient,
    private loadingWidgetService: LoadingWidgetService,
    private _store: Store<any>
  ) {
    this._store.pipe(select('iFrameData')).subscribe((state) => {
      if (state !== undefined && state['apiToken'] !== undefined) {
        this.apiToken = state['apiToken'];
      }
    });
  }

  responseAccessData: any;
  token_type: any;
  errorCode: any;
  errorDetail: any;
  getWebDropDownData(getWebDropDownHeaders) {
    const url = environment.webclaimURL.getWebDropdown;
    const mockURL = 'assets/mock-json/mock-getWebDropdown.json';
    // const mockURL = 'assets/mock-getWebdropdownUSA.json';
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: getWebDropDownHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: Response) => response));
  }

  getClaimantList(payload, getClaimantListHeaders, countryCode) {
    const mockURL = 'assets/mock-json/claimants.json';

    payload['Request0357ServiceCenter'] =
      environment.webclaimURL.Request0357ServiceCenter;
    if (countryCode === 'ca') {
      payload['Request0357ServiceCenter'] =
        environment.webclaimURL.Request0357ServiceCenter02;
    }
    const url = environment.webclaimURL.getMutitPolicyInsured;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: getClaimantListHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: Response) => response));
  }
  createClaim(payload, type, createClaimHeaders, regionParams = {}) {
    const mockURL = 'assets/mock-json/mock-createClaim.json';
    let url = environment.webclaimURL.createClaimAPI;
    if (type === 'temp') {
      url = environment.webclaimURL.tempCreateClaimAPI;
    }
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: createClaimHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    payload['Passkey'] = environment.webclaimURL.PassKey;
    payload['RegionName'] =
      regionParams && regionParams['ClaimRegionName']
        ? regionParams['ClaimRegionName']
        : environment.webclaimURL.RegionName;
    payload['UserId'] = environment.webclaimURL.UserId;
    // return this._http
    //   .post(url, payload)
    //   .pipe( map((response: any) => {
    //     if (response.ResponseCode === '0021') {
    //       this.delayRetry(20000, 3).pipe( map((resultObj: Response) => resultObj));
    //     } else {
    //       return response;
    //     }
    //   }));
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }
  delayRetry(delayMs: number, maxRetry) {
    let retries = maxRetry;
    return (src: any) =>
      src.pipe(
        retryWhen((errors: any) =>
          errors.pipe(
            delay(delayMs),
            mergeMap((error) =>
              retries-- > 0 ? of(error) : throwError(errors)
            )
          )
        )
      );
  }
  submitClaim(
    payload,
    submitClaimHeaders,
    customerCountry,
    regionParams = {},
    SrvcCentre
  ) {
    const url = environment.webclaimURL.submitClaim;
    let serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
    if (customerCountry !== undefined && customerCountry !== '') {
      if (customerCountry === 'ca') {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter02;
      } else {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
      }
    }
    payload['ServiceCenter'] = SrvcCentre;
    payload['RegionName'] =
      regionParams && regionParams['ClaimRegionName']
        ? regionParams['ClaimRegionName']
        : environment.webclaimURL.RegionName;
    payload['UserId'] = environment.webclaimURL.UserId;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: submitClaimHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  createInteraction(payload, page, interactionHeaders) {
    const mockURL = 'assets/mock-json/mock-createInteraction.json';
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: interactionHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      Authorization: this.apiToken,
      apiVersion: '1',
    });
    const url = environment.webclaimURL.createInteraction;
    if (page === 'start-new-claim') {
      payload['InteractionData']['Reason1'] = 'Claim Started';
      payload['InteractionData']['Title'] = 'Claim Started';
    }
    if (page === 'exit') {
      payload['InteractionData']['Reason1'] = 'User Voluntarily Exited';
      payload['InteractionData']['Title'] = 'User Voluntarily Exited';
    }
    if (page === 'sessionTimeout') {
      payload['InteractionData']['Reason1'] = 'Session Terminated';
      payload['InteractionData']['Title'] = 'Session Terminated';
    }
    if (page === 'submitClaim') {
      payload['InteractionData']['Reason1'] = 'Claim Submitted';
      payload['InteractionData']['Title'] = 'Claim Submitted';
    }
    if (page === 'createClaim') {
      payload['InteractionData']['Reason1'] = 'Claim created successfully';
      payload['InteractionData']['Title'] = 'Claim created successfully';
    }
    if (page === 'tempClaim') {
      payload['InteractionData']['Reason1'] = 'Temporary Claim created';
      payload['InteractionData']['Title'] = 'Temporary Claim created';
    }
    if (page === 'review-existing-claim') {
      payload['InteractionData']['Reason1'] = 'User reviewed existing claims';
      payload['InteractionData']['Title'] = 'User reviewed existing claims';
    }
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: Response) => response));
  }
  getToken() {
    const url = environment.webclaimURL.tokenURL;
    const headers = new HttpHeaders({
      App_ID: environment.webclaimURL.App_ID,
      App_Key: environment.webclaimURL.App_Key,
      Resource: environment.webclaimURL.tokenAPIResource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  getTokenAPI() {
    const url = environment.webclaimURL.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.tokenApiApp_ID,
      App_Key: environment.tokenApiApp_Key,
      apiVersion: '2',
      Resource: environment.tokenAPIResource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getPrintTiffAPI(payload: any, printTiffHeaders: any): Observable<any> {
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: printTiffHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    const url = environment.webclaimURL.printClaimTiffAPI;
    return this._http.post(url, payload, { headers: headers }).pipe(
      map((response: any) => {
        // If DocStream is present, convert it to a DocumentPath
        if (response && response.DocStream) {
          const byteArray = base64ToArrayBuffer(response.DocStream);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          response.DocumentBlob = blob;
        }
        return response;
      })
    );
  }

  docMetaDataSave(payload, tokenValue) {
    const headers = new HttpHeaders({
      Apiversion: '1',
      skip: 'true',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      Authorization: tokenValue,
    });
    const url = environment.webclaimURL.docMetaDataSaveAPI;
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  uploadFileAttachment(
    attachmentData,
    file_name,
    fileName,
    contentType,
    fName,
    lName,
    apiToken,
    emailAddress,
    claimNumber,
    claimType,
    isDuplicate,
    sessionId,
    isWebStatus
  ) {
    let fileNameVal = fileName.split(' ').join('');
    let claimNumberVal = claimNumber;
    if (claimType === 'temp' && isDuplicate === false) {
      claimNumberVal = environment.webclaimURL.tempClaimNumber;
      const fileNameSplit = fileName.split(' ').join('').split('.');
      const fileTypeLength = fileNameSplit.length - 1;
      const fileType = fileName.split(' ').join('').split('.')[fileTypeLength];
      const file_name_val = file_name
        .split(' ')
        .join('')
        .split('.')
        .splice(0, fileTypeLength)
        .join('')
        .trim();
      fileNameVal = file_name_val + '_' + claimNumber + '.' + fileType;
    }
    let firstName = fName;
    firstName = firstName.split(',').join('');
    firstName = firstName.split("'").join('');

    let lastName = lName;
    lastName = lastName.split(',').join('');
    lastName = lastName.split("'").join('');
    const url =
      environment.webclaimURL.uploadAttachmentURL +
      claimNumberVal +
      '/attachments';
    let attachmentDescVal =
      firstName + ',' + lastName + ',' + claimNumberVal + ',' + emailAddress;
    if (isWebStatus === 'true') {
      if (claimType === 'temp') {
        attachmentDescVal =
          attachmentDescVal +
          ',' +
          environment.WEBCLAIM +
          ',' +
          sessionId +
          ',' +
          claimNumber +
          ',';
      } else {
        attachmentDescVal =
          attachmentDescVal +
          ',' +
          environment.WEBCLAIM +
          ',' +
          sessionId +
          ',,';
      }
    }

    // ToDo email address need to pick from iframe
    const body = {
      attachmentDesc: attachmentDescVal,
      attachmentTypeCd: environment.webclaimURL.attachmentTypeCd,
      mimeContentTypeCd: contentType,
      attachmentFilename: fileNameVal,
      attachmentData: attachmentData,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      skip: 'true',
    });
    return this._http
      .post(url, body, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getNewClaimBen(payload, getNewClaimBenHeaders) {
    const mockURL = 'assets/mock-json/mock-getNewClaimBen.json';
    const url = environment.webclaimURL.newClaimBen;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: getNewClaimBenHeaders.sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }

  getWCSessionData(sessionId) {
    const url =
      environment.webclaimURL.getWCSessionData + '?SessionId=' + sessionId;
    const mockUrl = 'assets/mock-json/mock-getWCSessionData.json';
    return this._http.get(url).pipe(map((response: Response) => response));

    // return this._http
    //   .get(mockUrl)
    //   .pipe(map((response: Response) => response));
  }
  getClaimHistoryByPolicy(payload) {
    const mockURL = 'assets/mock-json/mock-getClaimHistory.json';
    const url = environment.webclaimURL.getClaimHistory;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }
  getClaimsStatusOT(payload: Array<String>, sessionId) {
    const mockURL = 'assets/mock-json/mock-getClaimsStatus.json';
    const url = environment.webclaimURL.getClaimsStatus;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: sessionId,
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    const body = { ClaimNumber: payload };
    if (payload && payload.length > 0) {
      return this._http
        .post(url, body, { headers: headers })
        .pipe(map((response: HttpResponse<any>) => response));
      // return this._http
      //   .get(mockURL)
      //   .pipe(map((response: HttpResponse<any>) => response));
    }
  }
  getPolicyBenefitClaim(claimNumber, language, customerCountry) {
    const mockURL = 'assets/mock-json/GetPolBenClaim.json';
    const url = environment.webclaimURL.getPolicyBenefitClaim;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    let serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
    if (customerCountry !== undefined && customerCountry !== '') {
      if (customerCountry === 'ca') {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter02;
      } else {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
      }
    }
    const body = {
      LimitToCurrentClaim: true,
      Request0443ClaimNumber: claimNumber,
      Request0443ServiceCenter: serviceCenter,
      Language: language,
    };
    return this._http.post(url, body, { headers: headers });

    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }
  getAddressList(addressLine, countryCode) {
    const url = environment.webclaimURL.addressSearch;
    const headers = new HttpHeaders({
      Origin: environment.webclaimURL.allowOrigin,
    });
    const payload = {
      AddressLine: addressLine,
      CountryCode: countryCode,
      Limit: 10,
    };
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getAddressListNew(addressLine, instanceKey, countryCode, castingVal) {
    let url = environment.webclaimURL.addressSearchNew;
    const authData = window.btoa(
      environment.addressUserName + ':' + environment.addresspassword
    );
    url =
      url +
      environment.addressLine1 +
      addressLine +
      environment.instanceKey +
      instanceKey +
      environment.country +
      countryCode +
      environment.castingKey +
      castingVal;
    const headers = new HttpHeaders({
      Authorization: `Basic ${authData}`,
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getAddressUniqueId() {
    const url = environment.webclaimURL.addressUniqueId;
    const authData = window.btoa(
      environment.addressUserName + ':' + environment.addresspassword
    );
    const headers = new HttpHeaders({
      Authorization: `Basic ${authData}`,
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  getDenialLetter(claimNumber, customerCountry) {
    const mockURL = 'assets/mock-json/denialLetter.json';
    const url = environment.webclaimURL.denialLetter;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
    if (customerCountry !== undefined && customerCountry !== '') {
      if (customerCountry === 'ca') {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter02;
      } else {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
      }
    }
    const body = {
      ClaimNumber: claimNumber,
      ServiceCenter: serviceCenter,
    };
    return this._http.post(url, body, { headers: headers });
    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }
  getActivityByClaim(claimNumber, customerCountry) {
    const mockURL = 'assets/mock-json/GetActivityByClaim.json';
    const url = environment.webclaimURL.getActivityByClaim;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      Authorization: this.apiToken,
    });
    let serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
    if (customerCountry !== undefined && customerCountry !== '') {
      if (customerCountry === 'ca') {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter02;
      } else {
        serviceCenter = environment.webclaimURL.Request0443ServiceCenter01;
      }
    }
    const body = {
      ClaimNumber: claimNumber,
      Request0415ServiceCenter: serviceCenter,
    };
    return this._http.post(url, body, { headers: headers });
    // return this._http
    //   .get(mockURL)
    //   .pipe(map((response: HttpResponse<any>) => response));
  }
}
