import { Action } from '@ngrx/store';

export const WEB_DROPDOWN_UPDATED = 'WEBDROPDOWN_UPDATED';

export interface WebDropDownAction extends Action {
  payload: {
    webDropDown: Object;
  };
}

export function getWebDropDown(webDropDown): WebDropDownAction {
  return {
    type: WEB_DROPDOWN_UPDATED,
    payload: {
      webDropDown,
    },
  };
}
export class WebDropDownFetchSuccessAction implements Action {
  readonly type: string = WEB_DROPDOWN_UPDATED;
  constructor(public payload: Object) {}
}
