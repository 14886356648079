import { Action } from '@ngrx/store';

export const CLAIM_TYPE = 'CLAIM_TYPE';

export interface ClaimTypeSelectionAction extends Action {
  payload: {
    claimSelected: String
  };
}

export function getClaimType(claimSelected): ClaimTypeSelectionAction {
  return {
    type: CLAIM_TYPE,
    payload: {
        claimSelected
    },
  };
}

export class ClaimTypeSuccessAction implements Action {
  readonly type: string = CLAIM_TYPE;
  constructor(public payload: Object) {}
}

