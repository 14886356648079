import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppContextService } from 'src/app/app.context.service';

@Component({
  selector: 'contact-us-popup',
  templateUrl: './contact-us-popup.component.html',
  styleUrls: ['./contact-us-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsPopupComponent implements OnInit {

  config: any;
  constructor(public dialogRef: MatDialogRef<ContactUsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _appContext: AppContextService) { 
      this.config = this._appContext.get("accountNotFoundPopUp");
  }

  ngOnInit(): void {
    console.log(this.data);
  }
  onClickOK(){
    this.dialogRef.close();
  }
}
