import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { LanguageChangePopupModule } from './language-change-popup.module';
import { AppContextService } from '../../app.context.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-language-change-popup',
  templateUrl: './language-change-popup.component.html',
  styleUrls: ['./language-change-popup.component.scss'],
})
export class LanguageChangePopupComponent implements OnInit {
  imageMap: any[];
  imageSize: any[];

  countDown: Subscription;
  counterTime: string;

  authProviderState: any;
  sessionId: any;
  apiToken: any;
  sessionToken: any;
  languageConfig: any;
  countryRoute = 'en-US';

  constructor(
    public dialogRef: MatDialogRef<LanguageChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LanguageChangePopupModule,
    public sanitizer: DomSanitizer,
    private _store: Store<any>,
    private appContext: AppContextService,
    private _router: Router
  ) {
    this.languageConfig = this.appContext.get('footer.languagePopUp');
    let url = this._router.url;
    this.countryRoute = url.split('/')[1];
    this._store.subscribe((state) => {
      if (state !== undefined) {
        if (state['iFrameData'] !== undefined) {
          const iFrameData = state['iFrameData'];
          this.sessionId = iFrameData['uniqueId'];
          if (iFrameData.authToken) {
            this.apiToken = iFrameData.authToken;
          }
          if (iFrameData.adToken) {
            this.sessionToken = iFrameData.adToken;
          }
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // this.imageMap = [
    //   `${this.data.popupImageLinkOne} ${this.data.imageSizeOne}`,
    //   `${this.data.popupImageLinkTwo} ${this.data.imageSizeTwo}`,
    // ];
    // this.imageSize = [
    //   `(max-width: ${this.data.widthOne}) ${this.data.imageSizeTwoInPx}`,
    //   `${this.data.imageSizeOneInPx}`,
    // ];
  }


  ngOnDestroy() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  onButtonClick(routeParameter) {
    if(routeParameter){
      this.dialogRef.close();
      let url = this._router.url;
      const country = url.split("/")[1];
      window.scroll(0,0);
      this._router.navigateByUrl(url.replace(country, routeParameter));
    }
  }
}
