<div *ngIf="!data.isImage && !data.isSuccessMsg" class="message-popup">
    <div class="header item-1 item">
      <button (click)="onNoClick()" class="close">X</button>
      <p class="common">{{ data.header }}</p>
    </div>
    <div class="main">
      <ng-container *ngIf="!data.errorMessage">
        <div class="textOne">
          <p class="common">{{ data.textOne }}</p>
        </div>
        <div class="textTwo">
          <p class="common">
            {{ data.textTwo }}
            <span class="link">{{ data.linkText }}</span>
          </p>
        </div>
        <div class="textTwo">
          <p class="common">
            {{ data.textThree }}
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="data && (data.errorResponse || data.errorMessage)">
        <div class="server-error-message" [innerHTML]="data.errorMessage"></div>
        <div class="server-error-response">
          {{ data.errorResponse }}
        </div>
      </ng-container>
      <div class="textThree">
        <crux-button (click)="onClose()">{{ data.removeButtonText }}</crux-button>
        <crux-button *ngIf="data.buttonTwoText" (click)="onButtonTwoClick()">{{
          data.buttonTwoText
        }}</crux-button>
      </div>
    </div>
  </div>
  <div *ngIf="data.isImage" class="image-popup">
    <div (click)="onNoClick()" class="cross">{{ data.crossIcon }}</div>
    <img
      src="{{ data.popupImageLinkOne }}"
      alt="{{ data.imageAlt }}"
      srcset="{{imageMap.toString()}}"
      sizes="{{ imageSize.toString() }}"
    />
  </div>
  <div *ngIf="data.isSuccessMsg" class="success-message">
    <div class="heading">
      <div class="title">{{ data.title }}</div>
      <div (click)="onNoClick()" class="cross">{{ data.crossIcon }}</div>
    </div>
    <div class="success-text">{{ data.successMsg }}</div>
    <div class="okButton">
      <crux-button (click)="onClickOk()">{{ data.OkButtonText }}</crux-button>
    </div>
  </div>
  