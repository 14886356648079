import { Routes } from '@angular/router';
import { PublicLayoutComponent } from './layout';
import {
  AppContextGuard,
  WebAppContextGuard,
  LoginContextGuard,
} from './guards';
import { environment } from '../environments/environment';
import { LoginComponent } from './pages/login/login.component';
import { NewLoginComponent } from './pages/login-new/login-new.component';
import { UserInfoSuccessComponent } from './pages/user-info-success/user-info-success.component';
import { SetUserIdComponent } from './pages/set-user-id/set-user-id.component';
import { LoginRedirectGuard } from './guards/login-redirect-guard';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SystemDownGuard } from './guards/system-down-guard';
import { ManualRegistrationComponent } from './pages/manual-registration/manual-registration.component';
import { UserInfoEcsrSuccessComponent } from './pages/user-info-ecsr-success/user-info-ecsr-success.component';
import { LoginCallbackComponent } from './widgets/shared/login-callback/login-callback.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [LoginContextGuard, AppContextGuard],
    children: [
      {
        path: 'us',
        redirectTo: 'en-US',
        pathMatch: 'full',
        // canActivate: [LoginContextGuard],
      },
      {
        path: 'en-US',
        // canActivate: [LoginContextGuard],
        loadChildren:
          './pages/language-routes/language-routes.module#LanguageRoutesModule',
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'us',
        redirectTo: 'es-US',
        pathMatch: 'prefix',
      },
      {
        path: 'es-US',
        // canActivate: [LoginContextGuard],
        loadChildren:
          './pages/language-routes/language-routes.module#LanguageRoutesModule',
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'ca',
        redirectTo: 'en-CA',
        pathMatch: 'prefix',
      },
      {
        path: 'en-CA',
        // canActivate: [LoginContextGuard],
        loadChildren:
          './pages/language-routes/language-routes.module#LanguageRoutesModule',
        data: {
          title: 'Dashboard',
        },
        pathMatch: 'prefix',
      },
      {
        path: 'ca',
        redirectTo: 'fr-CA',
        pathMatch: 'prefix',
      },
      {
        path: 'fr-CA',
        // canActivate: [LoginContextGuard],
        loadChildren:
          './pages/language-routes/language-routes.module#LanguageRoutesModule',
        data: {
          title: 'Dashboard',
        },
      },
    ],
  },
  {
    path: 'registration',
    redirectTo: 'en-US/registration'
  },
  {
    path: 'en-US/registration',
    canActivate: [AppContextGuard, SystemDownGuard],
    loadChildren: './pages/registration/registration.module#RegistrationModule',
    data: {
      title: 'Registration',
    },
    pathMatch: 'prefix',
  },
  {
    path: 'es-US/registration',
    canActivate: [AppContextGuard, SystemDownGuard],
    loadChildren: './pages/registration/registration.module#RegistrationModule',
    data: {
      title: 'Registration',
    },
    pathMatch: 'prefix',
  },
  {
    path: 'en-CA/registration',
    canActivate: [AppContextGuard, SystemDownGuard],
    loadChildren: './pages/registration/registration.module#RegistrationModule',
    data: {
      title: 'Registration',
    },
    pathMatch: 'prefix',
  },
  {
    path: 'fr-CA/registration',
    canActivate: [AppContextGuard, SystemDownGuard],
    loadChildren: './pages/registration/registration.module#RegistrationModule',
    data: {
      title: 'Registration',
    },
    pathMatch: 'prefix',
  },
  // {
  //   path: 'login',
  //   canActivate: [LoginContextGuard],
  //   component: LoginComponent,
  //   loadChildren: './pages/login/login.module#LoginModule',
  // },

  {
    path: 'login/callback',
    redirectTo: 'login-callback'
  },
  {
    path: 'login-callback',
    component: LoginComponent,
    canActivate: [AppContextGuard]
  },
  {
    path: 'login',
    redirectTo: 'en-US/login'
  },
  {
    path: 'en-US/login',
    component: NewLoginComponent,
    loadChildren: './pages/login-new/login-new.module#NewLoginModule',
    canActivate: [AppContextGuard]
  },
  {
    path: 'en-CA/login',
    component: NewLoginComponent,
    loadChildren: './pages/login-new/login-new.module#NewLoginModule',
    canActivate: [AppContextGuard]
  },
  {
    path: 'es-US/login',
    component: NewLoginComponent,
    loadChildren: './pages/login-new/login-new.module#NewLoginModule',
    canActivate: [AppContextGuard]
  },
  {
    path: 'fr-CA/login',
    component: NewLoginComponent,
    loadChildren: './pages/login-new/login-new.module#NewLoginModule',
    canActivate: [AppContextGuard]
  },
  {
    path: 'en-US/success-user-info',
    component: UserInfoSuccessComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'es-US/success-user-info',
    component: UserInfoSuccessComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-CA/success-user-info',
    component: UserInfoSuccessComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'fr-CA/success-user-info',
    component: UserInfoSuccessComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-US/success-user-info/ecsr',
    component: UserInfoEcsrSuccessComponent
  },
  {
    path: 'es-US/success-user-info/ecsr',
    component: UserInfoEcsrSuccessComponent
  },
  {
    path: 'en-CA/success-user-info/ecsr',
    component: UserInfoEcsrSuccessComponent
  },
  {
    path: 'fr-CA/success-user-info/ecsr',
    component: UserInfoEcsrSuccessComponent
  },
  {
    path: 'en-US/reset-password',
    component: ResetPasswordComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'es-US/reset-password',
    component: ResetPasswordComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-CA/reset-password',
    component: ResetPasswordComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'fr-CA/reset-password',
    component: ResetPasswordComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-US/select-user-id',
    component: SetUserIdComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'es-US/select-user-id',
    component: SetUserIdComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-CA/select-user-id',
    component: SetUserIdComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'fr-CA/select-user-id',
    component: SetUserIdComponent,
    canActivate: [LoginRedirectGuard, AppContextGuard]
  },
  {
    path: 'en-US/select-user-id/quick-register',
    component: SetUserIdComponent,
    canActivate: [AppContextGuard]
  },
  {
    path: 'es-US/select-user-id/quick-register',
    component: SetUserIdComponent,
    canActivate: [AppContextGuard]
  },
  {
    path: 'en-CA/select-user-id/quick-register',
    component: SetUserIdComponent,
    canActivate: [AppContextGuard]
  },
  {
    path: 'fr-CA/select-user-id/quick-register',
    component: SetUserIdComponent,
    canActivate: [AppContextGuard]
  },
  {
    path: 'login/callback',
    redirectTo: 'login',
  },
  {
    path: 'knock-out',
    loadChildren: './pages/knock-out/knock-out.module#KnockOutModule',
    data: {
      title: 'KnockOut',
    },
    // canActivate: [LoginContextGuard],
  },
  {
    path: 'register',
    component: ManualRegistrationComponent
  },
  // {
  //   path: 'en-US/registration/ecsr',
  //   component: ManualRegistrationComponent
  // },
  {
    path: "registration/ecsr",
    component: LoginCallbackComponent
  },
  {
    path: environment.url.pageNotFound,
    loadChildren:
      './pages/page-not-found/page-not-found.module#PageNotFoundModule',
    data: {
      title: 'Page Not Found',
    }
  },
  {
    path: '**',
    redirectTo: `/${environment.url.pageNotFound}`,
    pathMatch: 'full'
  }
];
