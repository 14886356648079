import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Adb2cState } from '@chubb-auth/types';
import { interval } from 'rxjs';
import { endWith, map, startWith } from 'rxjs/operators';
import { AuthProviderService } from '../../auth-provider-service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  authState: Adb2cState;
  timer: any;
  constructor(
    private _router: Router,
    private _authProviderService: AuthProviderService
  ) {
    const authProvider = this._authProviderService.getAuthProvider();
    let number = 1;
    let timerCount = 0;
    const loggedIn = localStorage.getItem("loggedIn");
    if(loggedIn === "true") {
      const language = localStorage.getItem("language");
      this._router.navigate([language + "/dashboard"]);
    } else {
      setTimeout(() => {
        this.timer = setInterval(() => {
          number++;
          timerCount = timerCount + 500;
          // console.log("Trying to get state");
          const state = authProvider.getState();
          if(state.isLoggedIn){
            // console.log("Reccieved auth state after " + (2 + timerCount/1000) + " seconds");
            this._authProviderService.saveLoggedInSessionInfo(state);
            // console.log(state);
            this.authState = state;
            localStorage.setItem("loggedIn", "true");
            this._authProviderService.firstLogin = true;
            this._authProviderService.setLoggedInFlg(true);
            clearInterval(this.timer);
          }
        }, 500);
      }, 2000);
  
      setTimeout(() => {
        if(!this.authState?.isLoggedIn){
          clearInterval(this.timer);
          let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
          const path = localStorage.getItem("language");
          if(path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA" )) {
            logoutUrl = logoutUrl + path + "/login";
          } else {
            logoutUrl = logoutUrl + "en-US/login";
          }
          localStorage.removeItem("loggedIn");
          authProvider.logout(logoutUrl);
        }
      }, 10000);
    }
  }
}