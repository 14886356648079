import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageChangePopupComponent } from './language-change-popup.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule, ButtonModule } from '@crux/components';
import { FormsModule } from '@angular/forms';
import { SharedNewDirectiveModule } from '../shared/shared-new/shared-new.directive';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    IconModule,
    ButtonModule,
    SharedNewDirectiveModule
  ],
  declarations: [LanguageChangePopupComponent],
  bootstrap: [LanguageChangePopupComponent],
  exports: [LanguageChangePopupComponent],
})
export class LanguageChangePopupModule {
  static rootComponent = LanguageChangePopupComponent;
}
