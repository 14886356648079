<div class="set-user-id-container">
    <div class="container">
        <div class="mainContentContainer">
            <div class="nav-header">
            <div class="logoContainer" [ngClass]="language === 'fr-CA' ? 'logoContainerCA' : ''">
                <img class="brandLogo" src="{{config.brandlogoImgUrl}}" alt="{{config.brandLogoAltText}}">
                <!-- <div class="toggle">
                    <app-studio-custom-multiple-toggle [config]="config.mobileToggleConfig" [toggleButtons]="config.toggleButtons" (toggleEmission)="handleToggleSelection($event)"></app-studio-custom-multiple-toggle>
                </div>  -->
            </div>
            <div class="header-bg"></div>
            </div>
            <div class="messageLoginContainer">
                <div class="messageContainer">
                    <app-stepper-new [stepperConfig]="stepperConfig" [productConfig]="productConfig" [stepsActivity]="stepsActivity"></app-stepper-new>
                </div>
                <div class="loginContainer">
                    <div class="keyLogo"><img class="keyLogoImg" src="{{config.keyLogoImgUrl}}" alt="Key Logo">
                        <div class="LoginMessageContainer">
                            <div><span class="smallHeader">{{config.smallHeader}}</span><span class="loginMessage">{{config.loginMessage}}</span></div>
                            <div class="subtext-container">
                                <div class="subtext">{{config.subtext}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="loginFormContainer">
                        <!-- <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder> -->
                        <div class="radio-buttons" *ngIf="radioValues.length > 1">
                            <crux-radio
                            name="crux-radio-demo"
                            [values]="radioValues"
                            displayProp="name"
                            valueProp="value"
                            mode="accent"
                            layout="column"
                            [formControl]="radioControl"
                            (valueSelected)="getSelectedRadioValue($event)"
                            ></crux-radio>
                        </div>
                        <div class="button-container">
                            <div class="add-user-id-form" *ngIf="showUserIdField">
                                <crux-form-builder [config]="formBuilderConfig1" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder>
                                <span class="subText">
                                    {{config.otpText}}
                                </span>
                                <!-- <div class="submit-button">
                                    <crux-button (click)="addUserId()" mode="primary">
                                        {{sendButtonText}}
                                    </crux-button>
                                </div> -->
                            </div> 
                            
                            <!-- <div class="otp-container" *ngIf="showOtpField">
                                <crux-form-builder [config]="formBuilderConfig2" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder>
                                <div class="submit-button">
                                    <crux-button (click)="submitOtp()" mode="primary">
                                        {{config.otpButtonLabel}}
                                    </crux-button>
                                </div>
                            </div> 
                            <div class="resend-text" *ngIf="showResendText === true">
                                {{config.resendText}}
                            </div> -->
                            <div class="user-id-error" *ngIf="showUserIdError === true">
                                {{config.userIdError}}
                            </div> 
                        </div>
                        <div class="add-user-id-buttons">
                            <span class="subtext" (click)="backToLoginPage()">{{config.Back}}</span>
                            <div class="continue-button">
                                <crux-button [disabled]="disableContinueButton" (click)="onContinue()" mode="primary">
                                    {{ config.buttonLabel }}
                                </crux-button>
                            </div>
                        </div>
                        <!-- <div class="back-to-landing-page-link">
                            <span (click)="backToLoginPage()">
                                {{config.backtoLandingPage}}
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <app-footer [footerConfig]="footerConfig" [languageConfig]="languageConfig"></app-footer>
        </div>
    </div>
</div>