import { Action } from '@ngrx/store';

export const IFRAME_UPDATED = 'IFRAME_UPDATED';

export interface IFrameAction extends Action {
  payload: {
    iFrameData: Object;
  };
}

export function getIFrameData(iFrameData): IFrameAction {
  return {
    type: IFRAME_UPDATED,
    payload: {
      iFrameData,
    },
  };
}
export class IFrameFetchSuccessAction implements Action {
  readonly type: string = IFRAME_UPDATED;
  constructor(public payload: Object) {}
}
