export interface LanguageSelection {
  en: boolean;
  es: boolean;
  fr: boolean;
}

export function getLanguage(): LanguageSelection {
  return {
    en: true,
    es: false,
    fr: false,
  };
}
