import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { delay, map, mergeMap, retryWhen } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private _http: HttpClient) {}

  getToken() {
    const url = environment.webclaimURL.tokenURL;
    const headers = new HttpHeaders({
      App_ID: environment.webclaimURL.App_ID,
      App_Key: environment.webclaimURL.App_Key,
      Resource: environment.webclaimURL.tokenAPIResource,
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getTokenAPI() {
    const url = environment.webclaimURL.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.tokenApiApp_ID,
      App_Key: environment.tokenApiApp_Key,
      apiVersion: '2',
      Resource: environment.tokenAPIResource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  getTokenAPIInvitationCode() {
    const url = environment.webclaimURL.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.tokenApiApp_ID_invitationCode,
      App_Key: environment.tokenApiApp_Key_invitationCode,
      apiVersion: '2',
      Resource: environment.tokenAPIResource_invitationCode,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  tokenizeApi(payload, apitoken) {
    const url = environment.webclaimURL.tokenizeAPI;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apitoken,
      apiVersion: '1',
      skip: 'true',
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssSaveRegister(payload, apiToken, adToken) {
    const url = environment.ssUrl.ssSaveRegister;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: adToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssUpdateRegister(payload, apiToken, adToken) {
    const url = environment.ssUrl.ssUpdateRegister;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: adToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getSSDashboardInfo(payload, apiToken, adToken) {
    const url = environment.ssUrl.ssDashboard;
    // const mockURL = 'assets/mock-json/mock-getSSDashboard.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: adToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  ssFindCustomer(payload, apiToken) {
    const url = environment.ssUrl.ssFindCustomer;
    // const mockURL = 'assets/mock-json/mock-SSFindCustomer.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      skip: 'true',
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    //return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  ssLoginInfoAPI(body, apiToken) {
    const url = environment.ssUrl.ssLoginInfo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: body.Token,
    });
    return this._http
      .post(url, body, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssFindPolicies(payload) {
    const url = environment.ssUrl.ssFindPolicies;
    // const mockURL = 'assets/mock-json/mock-SSFindPolicies.json';
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: apiToken,
    //   apiVersion: '1',
    //   'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    // });
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: any) => response));
  }

  getPolicyBillingInfo(payload, apiToken) {
    const url = environment.ssUrl.ssPaymentHist;
    // const mockURL = 'assets/mock-json/mock-billingHistory.json';
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: apiToken,
    //   apiVersion: '1',
    //   'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    // });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }
  addSetupPayor(payload) {
    const url = environment.payment.setupPayor;
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
  }
  getWalletCardInfo(payload) {
    const url = environment.ssUrl.ssSendResetWalletFeature;
    const mockURL = 'assets/mock-json/mock-walletFeature.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  deleteCard(payload) {
    const url = environment.ssUrl.ssFetchDocument;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
  }

  deleteWalletInfo(payload) {
    const url = environment.ssUrl.ssDeleteWallet;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  deleteWalletCardInfo(payload) {
    const url = environment.ssUrl.ssSendResetWalletFeature;
    const mockURL = 'assets/mock-json/mock-deleteWalletFeature.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }
  ssFindBeneficiaries(payload, apiToken) {
    const url = environment.ssUrl.ssFindBeneficiaries;
    const mockURL = 'assets/mock-json/mock-SSFindBeneficiaries.json';
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: apiToken,
    //   apiVersion: '1',
    //   'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    // });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }
  ssFindDependents(payload, apiToken) {
    const url = environment.ssUrl.ssFindDependents;
    const mockURL = 'assets/mock-json/mock-SSFindDependents.json';
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: apiToken,
    //   apiVersion: '1',
    //   'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    // });
    return this._http
      .post(url, payload, {})
      .pipe(map((response: HttpResponse<any>) => response));
    //return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  ssFindDocumentsHist(payload, apiToken) {
    let url = environment.ssUrl.ssFindDocumentsHist;
    const mockURL = 'assets/mock-json/mock-findDocumentsHist.json';
    url =
      url +
      environment.historyAPILabels.userId +
      payload.UserId +
      environment.historyAPILabels.customerId +
      payload.customerId +
      environment.historyAPILabels.policy +
      payload.PolicyList +
      environment.historyAPILabels.fName +
      payload.fName +
      environment.historyAPILabels.lName +
      payload.lName +
      environment.historyAPILabels.form +
      payload.form +
      environment.historyAPILabels.issueState +
      payload.issueState +
      environment.historyAPILabels.issueDate +
      payload.issueDate +
      environment.historyAPILabels.country +
      payload.Country +
      environment.historyAPILabels.serviceCenter +
      payload.ServiceCenter +
      environment.historyAPILabels.Channel +
      environment.ssrChannel;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: apiToken,
    //   apiVersion: '1',
    //   'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    // });
    return this._http
      .get(url, {})
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }

  ssFetchDocument(url) {
    const headers = new HttpHeaders({
      content: 'pdf',
    });
    return this._http.get(url, { headers: headers, responseType: 'blob' });
  }
  getTokenexIframeUrl(payload) {
    // const mockUrl = "assets/mock-json/mock-tokenExHtpUrl.json";
    // return this._http.get(mockUrl).pipe(map((response: Response) => response));
    payload['TokenScheme'] = environment.tokenScheme;
    const url = environment.payment.tokenexIframeUrl;
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
  }
  monerisPurchase(payload) {
    // const mockUrl = "assets/mock-json/mock-monerisPreAuth.json";
    // return this._http.get(mockUrl).pipe(map((response: Response) => response));
    payload['sourceDescription'] = environment.sourceDescription;
    payload['paymentType'] = environment.creditCardPaymentType;
    payload['paymentMethodTokenType'] = environment.paymentMethodTokenType;
    const url = environment.payment.tokenexMonerisPurchase;
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
  }
  monerisPreAuth(payload) {
    // const mockUrl = "assets/mock-json/mock-monerisPreAuth.json";
    // return this._http.get(mockUrl).pipe(map((response: Response) => response));
    payload['sourceDescription'] = environment.sourceDescription;
    payload['paymentType'] = environment.preAuthPaymentType;
    payload['paymentMethodTokenType'] = environment.paymentMethodTokenType;
    payload['transactionAmount'] = environment.preAuthTransactionAmount;
    const url = environment.payment.tokenexMonerisPreAuth;
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
  }
  lyonsAccountVerification(payload) {
    // const mockUrl = "assets/mock-json/mock-lyonsAccountVerification.json";
    // return this._http.get(mockUrl).pipe(map((response: Response) => response));
    const url = environment.payment.lyonsVerifyAccountAPI;
    return this._http
      .post(url, payload)
      .pipe(map((response: HttpResponse<any>) => response));
  }
  savePaymentDeatils(payload, apiToken, adToken) {
    // const mockUrl = "assets/mock-json/mock-savePayment.json";
    // return this._http.get(mockUrl).pipe(map((response: Response) => response));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      adToken: adToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      'Access-Control-Allow-Origin': 'http://localhost:3000',
    });
    const url = environment.payment.savePaymentUrl;
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  ssSaveDetails(payload, apiToken, adToken) {
    const mockURL = 'assets/mock-json/mock-ssSaveDetails.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      adToken: adToken,
    });
    const url = environment.ssUrl.ssSaveDetails;
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }
  userProfileTokenAPI() {
    const url = environment.userProfileTokenConfig.url;
    const headers = new HttpHeaders({
      App_ID: environment.userProfileTokenConfig.appId,
      App_Key: environment.userProfileTokenConfig.appKey,
      apiVersion: environment.userProfileTokenConfig.apiVersion,
      Resource: environment.userProfileTokenConfig.resource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  twilioTokenApi() {
    const url = environment.twilioTokenConfig.url;
    const headers = new HttpHeaders({
      App_ID: environment.twilioTokenConfig.appId,
      App_Key: environment.twilioTokenConfig.appKey,
      apiVersion: environment.twilioTokenConfig.apiVersion,
      Resource: environment.twilioTokenConfig.resource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  ssRegistrationTokenAPI() {
    const url = environment.registrationTokenConfig.url;
    const headers = new HttpHeaders({
      App_ID: environment.registrationTokenConfig.appId,
      App_Key: environment.registrationTokenConfig.appKey,
      apiVersion: environment.registrationTokenConfig.apiVersion,
      Resource: environment.registrationTokenConfig.resource,
      skip: 'true',
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
  ssRegistrationTokenBuilderAPI(token, payload) {
    const url = environment.ssUrl.ssRegistrationTokenBuilder;
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key':
        environment.registrationTokenConfig.subscriptionKey,
      Authorization: token,
      skip: 'true',
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssSendUserFourDigitOTP(payload, token?: string) {
    // const url = environment.ssUrl.ssVerificationRequest;
    // let headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key':
    //     environment.verificationRequestSubscriptionkey,
    //   apiVersion: environment.verificationRequestApiVersion,
    //   applicationName: environment.applicationName,
    //   channel: payload['channel'],
    //   language: payload['language'],
    //   skip: 'true',
    // });
    // if (token) {
    //   headers = headers.append('Authorization', token);
    // }
    // if (payload['emailAddress'] !== undefined) {
    //   headers = headers.append('emailAddress', payload['emailAddress']);
    // } else {
    //   headers = headers.append('phoneNumber', payload['phoneNumber']);
    // }
    // return this._http
    //   .get(url, { headers: headers, observe: 'response' })
    //   .pipe(map((response: HttpResponse<any>) => response));
  }

  ssVerificationRequest(payload, token?: string) {
    const url = environment.ssUrl.ssVerificationRequest;
    let headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key':
        environment.verificationRequestSubscriptionkey,
      apiVersion: environment.verificationRequestApiVersion,
      applicationName: environment.applicationName,
      channel: payload['channel'],
      language: payload['language'],
      skip: 'true',
    });
    if (token) {
      headers = headers.append('Authorization', token);
    }
    if (payload['emailAddress'] !== undefined) {
      headers = headers.append('emailAddress', payload['emailAddress']);
    } else {
      headers = headers.append('phoneNumber', payload['phoneNumber']);
    }
    return this._http
      .get(url, { headers: headers, observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssSendFourDigitOtpEmailCheck(payload, token?: string) {
    const url = environment.ssUrl.ssSendFourDigitOtpEmailCheck;
    payload['applicationName'] = environment.applicationName;
    let headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key':
        environment.verificationRequestSubscriptionkey,
      apiVersion: '1',
      skip: 'true',
    });
    if (token) {
      headers = headers.append('Authorization', token);
    }
    return this._http
      .post(url, payload, { headers: headers, observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssSendEmailOTPQUickRegistrationCheck(payload, token?: string) {
    const url = environment.ssUrl.ssSendEmailOTPQUickRegistration;
    payload['applicationName'] = environment.applicationName;
    let headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key':
        environment.verificationRequestSubscriptionkey,
      apiVersion: '1',
      skip: 'true',
    });
    if (token) {
      headers = headers.append('Authorization', token);
    }
    return this._http
      .post(url, payload, { headers: headers, observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssVerificationCheck(payload, token?: string) {
    const url = environment.ssUrl.ssVerificationCheck;
    payload['applicationName'] = environment.applicationName;
    let headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key':
        environment.verificationRequestSubscriptionkey,
      apiVersion: '1',
      skip: 'true',
    });
    if (token) {
      headers = headers.append('Authorization', token);
    }
    return this._http
      .post(url, payload, { headers: headers, observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssUserProfileCheck(payload, token) {
    const url = environment.ssUrl.ssUserProfile;
    let headers = new HttpHeaders({
      EMAIL: payload['email'],
      Authorization: token,
      'Ocp-Apim-Subscription-Key':
        environment.userProfileTokenConfig.subscriptionKey,
      skip: 'true',
    });
    return this._http
      .get(url, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssEcsrOtpVerify(payload: { attemptId: string; otp: string }, token: string) {
    const url = environment.ecsr.verifyOtp
      .replace('{ID}', payload.attemptId)
      .replace('{PIN}', payload.otp);

    let headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      apiVersion: '1',
      skip: 'true',
    });

    headers = headers.append('Authorization', 'Bearer ' + token);

    return this._http
      .post(url, null, { headers: headers, observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssSendResetPasswordEmail(payload, token) {
    const headers = new HttpHeaders({
      Authorization: token,
      'Ocp-Apim-Subscription-Key':
        environment.userProfileTokenConfig.subscriptionKey,
      skip: 'true',
    });
    const url = environment.ssUrl.ssSendResetPasswordEmail;
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  ssCustomEvent(payload, token, adToken) {
    const url = environment.ssUrl.ssCustomEvent;
    const headers = new HttpHeaders({
      Authorization: token,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: adToken,
      skip: 'true',
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getSystemDownTimeJson() {
    // const url = 'assets/mock-json/systemDownTime.json';
    const url = environment.systemDownJson;
    return this._http
      .get(url)
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(url).pipe(map((response: any) => response));
  }
  getEmailToken() {
    const url = environment.SendEmailUrl.tokenAPI;
    const headers = new HttpHeaders({
      App_ID: environment.SendEmailUrl.App_ID,
      App_Key: environment.SendEmailUrl.App_Key,
      Resource: environment.SendEmailUrl.Resource,
      apiVersion: environment.SendEmailUrl.apiVersion,
    });
    return this._http
      .post(url, {}, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  sendEmail(payload, tokenobj) {
    const url = environment.emailAPIURL;
    const headers = new HttpHeaders({
      Authorization: tokenobj,
      'Content-Type': `application/json`,
      apiVersion: environment.SendEmailUrl.apiVersion,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getSSMemberDashboardInfo(payload, apiToken, adToken) {
    const url = environment.ssUrl.ssMemberDashboard;
    // const mockURL = 'assets/mock-json/mock-getSSMemberDashboard.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
      adToken: adToken,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: any) => response));
  }
  getPolicyBenefits(payload, token) {
    payload['Region'] = environment.benefitRegion;
    const url = environment.ssUrl.getPolicyBenefits;
    const mockURL = 'assets/mock-json/mock-policybenefits.json';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
    // return this._http.get(mockURL).pipe(map((response: Response) => response));
  }
  getClaimDocumentList(payload, claimDocumentListHeaders) {
    const url = environment.ssUrl.getClaimDocumentList;
    const headers = new HttpHeaders({
      APIKey: environment.webclaimURL.apiKey,
      SessionId: claimDocumentListHeaders.sessionId,
      Authorization: claimDocumentListHeaders.apiToken,
      apiVersion: '1',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
    });
    return this._http
      .post(url, payload, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));
  }
}
