import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppContextService } from 'src/app/app.context.service';

@Component({
  selector: 'no-of-attempts-popup',
  templateUrl: './no-of-attempts-popup.component.html',
  styleUrls: ['./no-of-attempts-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumberOfAttemptsPopupComponent implements OnInit {

  config: any;
  constructor(public dialogRef: MatDialogRef<NumberOfAttemptsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NumberOfAttemptsPopupComponent,
    private _appContext: AppContextService) { 
      this.config = this._appContext.get("pages.userInfoSuccess.noOfAttemptsPopup");
  }

  ngOnInit(): void {
  }
  onClickOK(){
    this.dialogRef.close();
    window.location.reload();
  }
}
