<div class="stepper">
    <div class="stepperMainContainer">
        <div class="leftContainer desktopView">
            <div class="stepperHeading">
                <div class="stepHeading">
                    <div class="heading1">{{stepperConfig.stepHeading.heading1}}</div>
                    <div *ngIf="!isRecurringOption" class="heading2">{{stepperConfig.stepHeading.heading2}}</div>
                    <div *ngIf="isRecurringOption" class="heading2">{{stepperConfig.stepHeading.heading3}}</div>
                </div>
                <div *ngIf="claimNum" class="claim-num-container">
                    <span class="claimNumText"
                        *ngIf="claimType !== 'temp'">{{claimNumLabels?.claimNum}}{{claimNum}}</span>
                    <span class="claimNumText"
                        *ngIf="claimType === 'temp'">{{claimNumLabels?.tempClaimNum}}{{claimNum}}</span>
                    <ng-container *ngFor="let data of multipleClaimResult">
                      <span *ngIf="data.type !== 'temp'" class="claimNumText">{{claimNumLabels.claimNum}}{{data.claimId}}</span>
                      <span *ngIf="data.type === 'temp'" class="claimNumText">{{claimNumLabels.tempClaimNum}}{{data.claimId}}</span>
                    </ng-container>
                </div>
            </div>
            <div class="leftBorderLine">
                <div class="stepsContainer" [ngClass]="multipleClaimResult.length > 0 ? 'multi-steps': '' ">
                    <div class="stepsDiv">
                        <div class="stepTitle">
                            {{productConfig?.title}}
                        </div>
                        <div class="step" [ngClass]="{'top-margin': productConfig.title}"
                            *ngFor="let step of productConfig.steps;let i= index" matTooltip="{{getTooltip(i)}}"
                            matTooltipPosition="right">
                            <div class="img"
                                *ngIf="stepsActivity !== undefined && stepsActivity[i]!== undefined && stepsActivity[i].showImg && !stepsActivity[i].showPercentage">
                                <img [src]="stepsActivity[i].currentStep ? step.activeImg : stepsActivity[i].isActive && !stepsActivity[i].isError ? step.successImg : stepsActivity[i].isActive &&  stepsActivity[i].isError ? step.errorImg :step.inactiveImg"
                                    alt="{{step.altText}}" />
                            </div>
                            <div class="percentageDiv"
                                *ngIf="percentageStyle !== undefined && stepsActivity !== undefined && stepsActivity[i]!== undefined && stepsActivity[i].showPercentage">
                                <circle-progress
                                    [percent]="stepsActivity[i].percentage ? stepsActivity[i].percentage : defaultPercentageStyling.percentage"
                                    [radius]="percentageStyle.radius ? percentageStyle.radius : defaultPercentageStyling.radius"
                                    [outerStrokeWidth]="percentageStyle.outerStrokeWidth ? percentageStyle.outerStrokeWidth : defaultPercentageStyling.outerStrokeWidth"
                                    [innerStrokeWidth]="percentageStyle.innerStrokeWidth ? percentageStyle.innerStrokeWidth : defaultPercentageStyling.innerStrokeWidth"
                                    [space]="percentageStyle.space ? percentageStyle.space : defaultPercentageStyling.space"
                                    [outerStrokeColor]="percentageStyle.outerStrokeColor ? percentageStyle.outerStrokeColor : defaultPercentageStyling.outerStrokeColor"
                                    [innerStrokeColor]="percentageStyle.innerStrokeColor ? percentageStyle.innerStrokeColor : defaultPercentageStyling.innerStrokeColor"
                                    [titleFontSize]="percentageStyle.titleFontSize ? percentageStyle.titleFontSize : defaultPercentageStyling.titleFontSize"
                                    [unitsFontSize]="percentageStyle.unitsFontSize ? percentageStyle.unitsFontSize : defaultPercentageStyling.unitsFontSize"
                                    [titleColor]="percentageStyle.titleColor ? percentageStyle.titleColor : defaultPercentageStyling.titleColor"
                                    [titleFontWeight]="percentageStyle.titleFontWeight ? percentageStyle.titleFontWeight : defaultPercentageStyling.titleFontWeight"
                                    [unitsFontWeight]="percentageStyle.unitsFontWeight ? percentageStyle.unitsFontWeight : defaultPercentageStyling.unitsFontWeight"
                                    [showSubtitle]="percentageStyle.showSubtitle ? percentageStyle.showSubtitle : defaultPercentageStyling.showSubtitle"
                                    [animation]="percentageStyle.animation ? percentageStyle.animation : defaultPercentageStyling.animation"
                                    [animationDuration]="percentageStyle.animationDuration ? percentageStyle.animationDuration : defaultPercentageStyling.animationDuration"
                                    [startFromZero]="percentageStyle.startFromZero ? percentageStyle.startFromZero : defaultPercentageStyling.startFromZero"
                                    [responsive]="percentageStyle.responsive ? percentageStyle.responsive : defaultPercentageStyling.responsive"
                                    [backgroundColor]="percentageStyle.backgroundColor ? percentageStyle.backgroundColor : defaultPercentageStyling.backgroundColor">
                                </circle-progress>
                            </div>
                            <div class="stepText subheader"
                                *ngIf="stepsActivity !== undefined && stepsActivity[i]!== undefined && step.isSubheader">
                                <div class="flexAlignment">
                                    <div *ngIf="step.type!='Payment'" (click)="headerAction(1, i, '', stepsActivity[i])"
                                        [ngClass]="stepsActivity !== undefined && stepsActivity[i].isActive ? 'heading link stepperItem' : 'headingInactive stepperItem'">
                                        {{step.name}}
                                    </div>
                                    <div *ngIf="step.type==='Payment'"
                                        [ngClass]="stepsActivity !== undefined && stepsActivity[i].isActive ? 'heading link' : 'headingInactive'">
                                        {{step.name}}
                                    </div>
                                    <div (click)="headerAction(1, i, '', stepsActivity[i])"
                                        *ngIf="step.type!='Payment' && step.type!='submitSignature' && stepsActivity !== undefined && stepsActivity[i].isFormValid">
                                        <span class="viewSignLink link">{{step.viewSign}}</span>
                                    </div>
                                    <span (click)="headerAction(1, i, '', stepsActivity[i])" class="viewSignLink"
                                        *ngIf="step.type!='Payment' && step.type!='submitSignature' && stepsActivity !== undefined && !stepsActivity[i].isFormValid">{{step.viewSign}}</span>
                                </div>
                                <div *ngFor="let subHeader of step.supplimentalForms; let j=index">
                                    <div class="flexAlignment">
                                        <div (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type!='hipaa'"
                                            [ngClass]="subHeader.isActive ? 'heading stepperItem' : 'headingInactive stepperItem'">
                                            {{subHeader.name}}
                                        </div>
                                        <div (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type==='hipaa'"
                                            [ngClass]="subHeader.isActive ? 'heading' : 'headingInactive'">
                                            {{subHeader.name | uppercase}}
                                        </div>
                                        <div (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.isFormValid">
                                            <span class="viewSignLink link">{{subHeader.viewSign}}</span>
                                        </div>
                                        <span (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="!subHeader.isFormValid"
                                            class="viewSignLink">{{subHeader.viewSign}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="stepText"
                                *ngIf="stepsActivity !== undefined && stepsActivity[i]!== undefined && !step.isSubheader"
                                [ngClass]="{'stepTextPercentage': stepsActivity[i].showPercentage, 'stepTextEdit': stepsActivity[i].showEdit}">
                                <div
                                    [ngClass]="stepsActivity !== undefined && stepsActivity[i].isActive ? 'name' : 'nameInactive'">
                                    {{step.name}}</div>
                                <div
                                    [ngClass]="stepsActivity !== undefined && stepsActivity[i].isActive ? 'heading' : 'headingInactive'">
                                    <ng-container *ngIf="!isRecurringOption">{{step.heading}}</ng-container>
                                    <ng-container *ngIf="isRecurringOption">{{step.heading2}}</ng-container>

                                </div>
                            </div>
                            <div class="stepEdit" (click)="navigate(i)"
                                *ngIf="stepsActivity!==undefined && stepsActivity[i]!== undefined && stepsActivity[i].showEdit">
                                <span class="editLinkLabel">{{stepsActivity[i].showReview ?
                                    productConfig.reviewLinkLabel : productConfig.editLinkLabel}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightContainer desktopView" *ngIf="!isMobileView">
            <div *ngIf="stepperConfig.editTileLabels">
                <div class="edit-container edit-container-mobile"
                    [ngStyle]="item.accordionStyle ? item.accordionStyle : null"
                    *ngFor="let item of stepperConfig.editTileLabels">
                    <div class="edit-container__left">
                        <div class="edit-container__left__inside">
                            <span class="edit-container__left__inside__one">
                                <img src="{{item.leftIconUrl}}" alt="{{item.leftIconPlaceHolder}}">
                            </span>
                            <span *ngFor="let data of item.leftLabel1"
                                class="edit-container__left__inside__two">{{data.text}}</span>
                            <span class="edit-container__left__inside__three">{{item.leftLabel2}}</span>
                        </div>
                    </div>
                    <div class="edit-container__right" (click)="handleEdit(item.routeUrlLabel)">
                        {{item.rightLabel}}
                    </div>
                </div>
            </div>
            <div>
                <ng-container *ngTemplateOutlet="outletContent"></ng-container>
            </div>
        </div>
        <div class="leftContainer mobileView" *ngIf="isMobileView">

            <div class="stepperHeadingMobile">
                <div class="stepHeading">
                    <div *ngIf="!isRecurringOption" class="heading1">{{stepperConfig.stepHeading.heading2}}</div>
                    <div *ngIf="isRecurringOption" class="heading2">{{stepperConfig.stepHeading.heading3}}</div>
                </div>

                <div class="stepperSubHeading">
                    <div class="subHeading" *ngFor="let item of stepperConfig.stepHeading.subText">{{item.label}}</div>
                </div>
            </div>
            <div class="stepperHeadingOuter" *ngIf="!stepperConfig.isAccordionRequired">
                <div class="edit-container edit-container-mobile"
                    [ngStyle]="item.accordionStyle ? item.accordionStyle : null"
                    *ngFor="let item of stepperConfig.editTileLabels">
                    <div class="edit-container__left">
                        <div class="edit-container__left__inside">
                            <span class="edit-container__left__inside__one">
                                <img src="{{item.leftIconUrl}}" alt="{{item.leftIconPlaceHolder}}">
                            </span>
                            <span class="edit-container__left__inside__two">{{item.leftLabel}}</span>
                        </div>
                    </div>
                    <div class="edit-container__right" (click)="handleEdit(item.routeUrlLabel)">
                        {{item.rightLabel}}
                    </div>
                </div>
            </div>
            <div class="stepperHeadingOuter" *ngIf="stepperConfig.isAccordionRequired">
                <div class="edit-container edit-container-mobile edit-container-mobile-view"
                    [ngStyle]="item.accordionStyle ? item.accordionStyle : null"
                    *ngFor="let item of stepperConfig.editTileLabels">
                    <div class="edit-container__left">
                        <div class="edit-container__left__inside">
                            <span class="edit-container__left__inside__one">
                                <img src="{{item.leftIconUrl}}" alt="{{item.leftIconPlaceHolder}}">
                            </span>
                            <span *ngFor="let data of item.leftLabel1"
                                class="edit-container__left__inside__two">{{data.text}}</span>
                            <span class="edit-container__left__inside__three">{{item.leftLabel2}}</span>
                        </div>
                    </div>
                    <div class="edit-container__right" (click)="handleEdit(item.routeUrlLabel)">
                        {{item.rightLabel}}
                    </div>
                </div>
            </div>
            <div class="separatorMobileIcons" *ngIf="stepsActivity!==undefined">
                <div class="stepsContainerMobile">
                    <div class="stepsDivMobile spacingInSteps"
                        *ngIf="productConfig.steps && productConfig.steps.length > 0 && productConfig.steps[0].isSubheader">
                        <div class="mainStepMobile" *ngFor="let step of productConfig.steps;let i= index"
                            [ngClass]="(stepsActivity[i].currentStep && i != 0) ? 'currentActiveStepMobile' : (stepsActivity[i].currentStep && i == 0) ? 'currentFirstActiveStepMobile' : null">
                            <div class="stepMobile stepTextMobile" *ngIf="step.isSubheader">
                                <div class="img" *ngIf="stepsActivity !== undefined && stepsActivity[i].showImg"
                                    (click)="headerAction(1, i, '', stepsActivity[i])">
                                    <img [src]="stepsActivity[i].currentStep ? step.activeImg : stepsActivity[i].isActive && !stepsActivity[i].isError ? step.successImg : stepsActivity[i].isActive &&  stepsActivity[i].isError ? step.errorImg :step.inactiveImg"
                                        alt="{{step.altText}}" />
                                </div>
                                <div *ngFor="let subHeader of step.supplimentalForms; let j=index">
                                    <div class="subheader">
                                        <span (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type!='hipaa'" class="heading"
                                            [ngClass]="subHeader.isActive ? 'stepperItem' : 'headingInactive stepperItem'">
                                            {{subHeader.name}}
                                        </span>
                                        <span (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type==='hipaa'" class="heading"
                                            [ngClass]="!subHeader.isActive ? 'headingInactive' : ''">
                                            {{subHeader.name | uppercase}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="stepTextMobile" *ngIf="stepsActivity[i].currentStep"
                                [ngClass]="stepsActivity[i].showMobileSectionLabel && i === 0 ? 'stepTextMobileFirst' : stepsActivity[i].showMobileSectionLabel && i !== 0 ? 'stepTextMobileOther' : null">
                                <div class="stepTextLabel stepTextLabelItem_{{i}}">{{step.mobileViewName}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="stepsDivMobile"
                        *ngIf="productConfig.steps && productConfig.steps.length > 0 && !productConfig.steps[0].isSubheader">
                        <div class="mainStepMobile" *ngFor="let step of productConfig.steps;let i= index"
                            [ngClass]="(stepsActivity[i].currentStep && i != 0) ? 'currentActiveStepMobile activeStepMargin-rt' : (stepsActivity[i].currentStep && i == 0) ? 'currentFirstActiveStepMobile activeStepMargin-rt' : null"
                            matTooltip="{{getTooltip(i)}}" matTooltipPosition="right">
                            <div class="stepMobile" (click)="navigate(i)" *ngIf="!step.isSubheader">
                                <div class="img" *ngIf="stepsActivity !== undefined && stepsActivity[i].showImg">
                                    <img [src]="stepsActivity[i].currentStep ? step.activeImg : stepsActivity[i].isActive && !stepsActivity[i].isError ? step.successImg : stepsActivity[i].isActive &&  stepsActivity[i].isError ? step.errorImg :step.inactiveImg"
                                        alt="{{step.altText}}" />
                                </div>
                                <div class="percentageDivMobile"
                                    *ngIf="percentageStyle !== undefined && stepsActivity !== undefined && stepsActivity[i].showPercentage">
                                    <circle-progress
                                        [percent]="stepsActivity[i].percentage ? stepsActivity[i].percentage : defaultPercentageStyling.percentage"
                                        [radius]="percentageStyle.radius ? percentageStyle.radius : defaultPercentageStyling.radius"
                                        [outerStrokeWidth]="percentageStyle.outerStrokeWidth ? percentageStyle.outerStrokeWidth : defaultPercentageStyling.outerStrokeWidth"
                                        [innerStrokeWidth]="percentageStyle.innerStrokeWidth ? percentageStyle.innerStrokeWidth : defaultPercentageStyling.innerStrokeWidth"
                                        [space]="percentageStyle.space ? percentageStyle.space : defaultPercentageStyling.space"
                                        [outerStrokeColor]="percentageStyle.outerStrokeColor ? percentageStyle.outerStrokeColor : defaultPercentageStyling.outerStrokeColor"
                                        [innerStrokeColor]="percentageStyle.innerStrokeColor ? percentageStyle.innerStrokeColor : defaultPercentageStyling.innerStrokeColor"
                                        [titleFontSize]="percentageStyle.titleFontSize ? percentageStyle.titleFontSize : defaultPercentageStyling.titleFontSize"
                                        [unitsFontSize]="percentageStyle.unitsFontSize ? percentageStyle.unitsFontSize : defaultPercentageStyling.unitsFontSize"
                                        [titleColor]="percentageStyle.titleColor ? percentageStyle.titleColor : defaultPercentageStyling.titleColor"
                                        [titleFontWeight]="percentageStyle.titleFontWeight ? percentageStyle.titleFontWeight : defaultPercentageStyling.titleFontWeight"
                                        [unitsFontWeight]="percentageStyle.unitsFontWeight ? percentageStyle.unitsFontWeight : defaultPercentageStyling.unitsFontWeight"
                                        [showSubtitle]="percentageStyle.showSubtitle ? percentageStyle.showSubtitle : defaultPercentageStyling.showSubtitle"
                                        [animation]="percentageStyle.animation ? percentageStyle.animation : defaultPercentageStyling.animation"
                                        [animationDuration]="percentageStyle.animationDuration ? percentageStyle.animationDuration : defaultPercentageStyling.animationDuration"
                                        [startFromZero]="percentageStyle.startFromZero ? percentageStyle.startFromZero : defaultPercentageStyling.startFromZero"
                                        [responsive]="percentageStyle.responsive ? percentageStyle.responsive : defaultPercentageStyling.responsive"
                                        [backgroundColor]="percentageStyle.backgroundColor ? percentageStyle.backgroundColor : defaultPercentageStyling.backgroundColor">
                                    </circle-progress>
                                </div>
                            </div>
                            <div class="stepMobile stepTextMobile" *ngIf="step.isSubheader">
                                <div class="img" *ngIf="stepsActivity !== undefined && stepsActivity[i].showImg"
                                    (click)="headerAction(1, i, '', stepsActivity[i])">
                                    <img [src]="stepsActivity[i].currentStep ? step.activeImg : stepsActivity[i].isActive && !stepsActivity[i].isError ? step.successImg : stepsActivity[i].isActive &&  stepsActivity[i].isError ? step.errorImg :step.inactiveImg"
                                        alt="{{step.altText}}" />
                                </div>
                                <div *ngFor="let subHeader of step.supplimentalForms; let j=index">
                                    <div class="subheader">
                                        <span (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type!='hipaa'" class="heading"
                                            [ngClass]="subHeader.isActive ? 'stepperItem' : 'headingInactive stepperItem'">
                                            {{subHeader.name}}
                                        </span>
                                        <span (click)="subHeaderAction(2, i, j, subHeader)"
                                            *ngIf="subHeader.type==='hipaa'" class="heading"
                                            [ngClass]="!subHeader.isActive ? 'headingInactive' : ''">
                                            {{subHeader.name | uppercase}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="stepTextMobile" *ngIf="stepsActivity[i].currentStep"
                                [ngClass]="stepsActivity[i].showMobileSectionLabel && i === 0 ? 'stepTextMobileFirst' : stepsActivity[i].showMobileSectionLabel && i !== 0 ? 'stepTextMobileOther' : null">
                                <div class="stepTextLabel stepTextLabelItem_{{i}}">{{step.mobileViewName}}</div>
                                <div *ngIf="!isRecurringOption" class="heading2StepText heading2StepText_{{i}}">{{step.heading}}</div>
                                <div *ngIf="isRecurringOption" class="heading2StepText heading2StepText_{{i}}">{{step.heading2}}</div>
                                <div *ngIf="claimNum" class="claim-num-container claim-num-container_{{i}}">
                                    <span class="claimNumText"
                                        *ngIf="claimType !== 'temp'">{{claimNumLabels?.claimNum}}{{claimNum}}</span>
                                    <span class="claimNumText"
                                        *ngIf="claimType === 'temp'">{{claimNumLabels?.tempClaimNum}}{{claimNum}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="widgetContainerMobile">
                <ng-container *ngTemplateOutlet="outletContent"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #outletContent>
    <ng-content></ng-content>
</ng-template>
