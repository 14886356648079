import { createReducer, on } from '@ngrx/store';
import { resetRegistrationDetails, saveRegistrationDeatils, showOtpInRegisterationPage } from '../actions/registration-details.action';
import { registrationDetailsInitialState } from '../initial-state/registration-details.state';

const _registrationDetailsReducer = createReducer(
  registrationDetailsInitialState,
  on(saveRegistrationDeatils, (state, { payload }) => ({ ...state, ...payload })),
  on(resetRegistrationDetails, (state) => ({})),
  on(showOtpInRegisterationPage, (state, { payload }) => ({ ...state, ...payload })),
  );

export function registrationDetailsReducer(
  state = registrationDetailsInitialState,
  action
) {
  return _registrationDetailsReducer(state, action);
}
