import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  Pipe,
  PipeTransform,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Subscription, timer, Observable } from 'rxjs';
import { Adb2cState } from '@chubb-auth/types';
import { map, timeout } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { IdlingService } from '@crux/services';
import { environment } from 'src/environments/environment';
import { IFrameFetchSuccessAction } from '../../../state/actions/iFrame.action';
import { FormService } from 'src/app/services';
import { AuthProviderService } from 'src/app/auth-provider-service';
import { MessagePopupModel } from './message-popup.model';
import { MessagePopupService } from '../../../services/message-popup.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessagePopupComponent implements OnInit, OnDestroy {
  imageMap: any[];
  imageSize: any[];
  countDown: Subscription;
  counter = 600;
  tick = 1000;
  iFrameData = {};
  counterTime: string;

  authProviderState: any;
  sessionId: any;
  apiToken: any;
  sessionToken: any;

  constructor(
    public dialogRef: MatDialogRef<MessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessagePopupModel,
    public sanitizer: DomSanitizer,
    private idling: IdlingService,
    private _authProviderService: AuthProviderService,
    private _store: Store<any>,
    private _service: FormService,
    private _idlePopup: MessagePopupService,
    private _router: Router,
    private _jwtHelperService: JwtHelperService
  ) {
    this._store.subscribe((state) => {
      if (state !== undefined) {
        if (state['iFrameData'] !== undefined) {
          const iFrameData = state['iFrameData'];
          this.sessionId = iFrameData['uniqueId'];
          if (iFrameData.authToken) {
            this.apiToken = iFrameData.authToken;
          }
          if (iFrameData.adToken) {
            this.sessionToken = iFrameData.adToken;
          }
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onLogout(): void {
    this.callLoginInfoApi();
    const authProvider = this._authProviderService.getAuthProvider();
    let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
    const path = this._router.url.split("/")[1];
    if(path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA" )){
      logoutUrl = logoutUrl + path + "/login";
    } else {
      logoutUrl = logoutUrl + "en-US/login";
    }
    localStorage.removeItem("loggedIn");
    authProvider.logout(logoutUrl);
    this.dialogRef.close();
  }

  refreshToken() {

    const authProvider = this._authProviderService.getAuthProvider();
    authProvider.getTokenObject().subscribe((state) => {
      if (state !== undefined) {
        this.sessionToken = state.accessToken;
        this.iFrameData['adToken'] = this.sessionToken;
        this._store.dispatch(
          new IFrameFetchSuccessAction({ iFrameData: this.iFrameData })
        );
        this._idlePopup.callRefreshToken(this.sessionToken);
      }
    });
  }

  onExtendSession(): void {
    this.dialogRef.close();
    this.refreshToken();
  }

  callLoginInfoApi() {
    this.authProviderState = this._authProviderService.getLoggedInSessionInfo();
    const accessToken = this._authProviderService.logginSessionData
      ?.accessToken;
    let decodedToken;
    if (accessToken !== undefined) {
      decodedToken = this._jwtHelperService.decodeToken(accessToken);
    }
    if (this.authProviderState && this.authProviderState.userClaims) {
      const expiryDate = new Date(this.authProviderState.userClaims.exp * 1000);
      const requestBody = {
        UserId: decodedToken && decodedToken.email ? decodedToken.email : this.authProviderState.userClaims.email,
        UserAction: 'Logout',
        SessionId: this.sessionId,
        Token: this.authProviderState.accessToken,
        TokenValidEndDttm: moment(expiryDate).format('DD/MM/YYYY HH:mm:ss'),
      };

      this._service
        .ssLoginInfoAPI(requestBody, this.apiToken)
        .pipe(timeout(120000))
        .subscribe((response) => { });
    }
  }

  ngOnInit(): void {
    this.imageMap = [
      `${this.data.popupImageLinkOne} ${this.data.imageSizeOne}`,
      `${this.data.popupImageLinkTwo} ${this.data.imageSizeTwo}`,
    ];

    this.imageSize = [
      `(max-width: ${this.data.widthOne}) ${this.data.imageSizeTwoInPx}`,
      `${this.data.imageSizeOneInPx}`,
    ];

    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      const minutes: number = Math.floor(this.counter / 60);
      this.counterTime =
        ('00' + minutes).slice(-2) +
        ':' +
        ('00' + Math.floor(this.counter - minutes * 60)).slice(-2);

      if (this.counterTime === '00:00') {
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
        this.onLogout();
      }
    });
  }

  onClickOk() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }
}
