import { Action } from '@ngrx/store';
import { iFrameData } from '../initial-state/iFrame-state';
import { IFrameAction, IFRAME_UPDATED } from '../actions/iFrame.action';

export function iFrameReducer(state: {}, action: Action) {
  switch (action.type) {
    case IFRAME_UPDATED: {
      const typedAction = <IFrameAction>action;
      return { ...state, ...typedAction.payload.iFrameData };
    }
    default: {
      return state;
    }
  }
}
