import { Action } from '@ngrx/store';
import {
  WebDropDownAction,
  WEB_DROPDOWN_UPDATED,
} from '../actions/web-dropDown.action';

export function WebDropDownReducer(state: {}, action: Action) {
  switch (action.type) {
    case WEB_DROPDOWN_UPDATED: {
      const typedAction = <WebDropDownAction>action;
      return { ...state, ...typedAction.payload.webDropDown };
    }
    default: {
      return state;
    }
  }
}
