import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class FormValidationService {

  private dashboardPolicyDetails = new BehaviorSubject<any>(null);
  public dashboardPolicyDetails$ = this.dashboardPolicyDetails.asObservable();
  fetchPolicyDetails(obj) {
    this.dashboardPolicyDetails.next(obj);
  }
  private wellnessClaimDetailsFormToBeValidated = new BehaviorSubject<Boolean>(
    false
  );
  public wellnessClaimDetailsForm$ = this.wellnessClaimDetailsFormToBeValidated.asObservable();

  private wellnessReviewDetailsFormToBeValidated = new BehaviorSubject<Boolean>(
    false
  );
  public wellnessReviewDetailsForm$ = this.wellnessReviewDetailsFormToBeValidated.asObservable();

  private wellnessReviewDetailsCheckboxFormToBeValidated = new BehaviorSubject<
    Boolean
  >(false);
  public wellnessReviewDetailsCheckboxForm$ = this.wellnessReviewDetailsCheckboxFormToBeValidated.asObservable();

  private paymentMethodFormToBeValidated = new BehaviorSubject<Boolean>(false);
  public paymentMethodForm$ = this.paymentMethodFormToBeValidated.asObservable();

  private callDasboardApi = new BehaviorSubject<Boolean>(false);
  public callDasboardApi$ = this.callDasboardApi.asObservable();

  private saveDetails = new BehaviorSubject<String>('');
  public saveDetails$ = this.saveDetails.asObservable();

  private isLoggingInFirstTime = new BehaviorSubject<Boolean>(true);
  public isLoggingInFirstTime$ = this.isLoggingInFirstTime.asObservable();

  firstTimeApiCall = true;

  private isLanguageToggleChanged = new BehaviorSubject<Boolean>(false);
  public isLanguageToggleChanged$ = this.isLanguageToggleChanged.asObservable();

  private dashBoardApiResponse = new BehaviorSubject<Boolean>(undefined);
  public dashBoardApiResponse$ = this.dashBoardApiResponse.asObservable();

  private knockoutResponse = new Subject<Boolean>();
  public knockoutResponse$ = this.knockoutResponse.asObservable();


  handleWellnessClaimDetailsForm(val: Boolean): void {
    this.wellnessClaimDetailsFormToBeValidated.next(val);
  }

  handleWellnessReviewDetailsForm(val: Boolean): void {
    this.wellnessReviewDetailsFormToBeValidated.next(val);
  }

  handleWellnessCheckboxForm(val: Boolean): void {
    this.wellnessReviewDetailsCheckboxFormToBeValidated.next(val);
  }

  handlePaymentMethodForm(val: Boolean): void {
    this.paymentMethodFormToBeValidated.next(val);
  }

  updateDashboardApiFlg(flg) {
    this.callDasboardApi.next(flg);
  }

  updateMyDetails(val: String) {
    this.saveDetails.next(val);
  }

  handleLoggingInFirstTime(payload: Boolean) {
    this.isLoggingInFirstTime.next(payload);
  }

  onToggleChange(flag: any) {
    this.isLanguageToggleChanged.next(flag);
  }

  onDashboardResponse(flg: Boolean){
    this.dashBoardApiResponse.next(flg);
  }

  sendKnockoutResponse(flg: Boolean){
    this.knockoutResponse.next(flg);
  }
}
