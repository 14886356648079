import {
  StepWidgetsReadyActions,
  StepWidgetsReadyActionType,
} from '../actions/stepWidgets-ready.action';
import { SectionsReady } from '../../widgets/shared/section/sections-ready.model';

export function stepWidgetReadyReducer(
  state = {},
  action: StepWidgetsReadyActionType
) {
  switch (action.type) {
    case StepWidgetsReadyActions.STEPWIDGET_READY_SUCCESS:
      return { ...state, ...(<Partial<SectionsReady>>action.payload) };
    case StepWidgetsReadyActions.STEPWIDGET_READY_FAILURE:
      return { ...state, ...(<Partial<SectionsReady>>action.payload) };
    case StepWidgetsReadyActions.STEPWIDGET_READY_DESTROY_SUCCESS:
    case StepWidgetsReadyActions.STEPWIDGET_READY_DESTROY_FAILURE:
      const newState = { ...state };
      delete newState[<string>action.payload];
      return newState;
    default:
      return state;
  }
}
