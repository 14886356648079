import {
  SectionsReadyActions,
  SectionsReadyActionType,
} from '../actions/sections-ready.action';
import { SectionsReady } from '../../widgets/shared/section/sections-ready.model';

export function sectionsReadyReducer(
  state = {},
  action: SectionsReadyActionType
) {
  switch (action.type) {
    case SectionsReadyActions.SECTION_READY_SUCCESS:
      return { ...state, ...(<Partial<SectionsReady>>action.payload) };
    case SectionsReadyActions.SECTION_READY_FAILURE:
      return { ...state, ...(<Partial<SectionsReady>>action.payload) };
    case SectionsReadyActions.SECTION_READY_DESTROY_SUCCESS:
    case SectionsReadyActions.SECTION_READY_DESTROY_FAILURE:
      const newState = { ...state };
      delete newState[<string>action.payload];
      return newState;
    default:
      return state;
  }
}
