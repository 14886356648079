import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppContextService } from 'src/app/app.context.service';
import { RegistrationSuccessInfPopupModel } from './registration-success-info-popup.model';

@Component({
  selector: 'registration-success-info-popup',
  templateUrl: './registration-success-info-popup.component.html',
  styleUrls: ['./registration-success-info-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationSuccessInfoPopupComponent implements OnInit {

  config: any;
  constructor(public dialogRef: MatDialogRef<RegistrationSuccessInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegistrationSuccessInfPopupModel,
    private _appContext: AppContextService) { 
      //this.config = this._appContext.get("registrationSuccessInfoPopUp");
  }

  ngOnInit(): void {
  }
  onClickOK(){
    this.dialogRef.close();
  }
}
