import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomToggleComponent } from './custom-toggle.component';
import { ButtonToggleModule } from '@crux/components';

@NgModule({
  imports: [CommonModule, ButtonToggleModule],
  declarations: [CustomToggleComponent],
  exports: [CustomToggleComponent],
})
export class CustomToggleModule {
    static rootComponent = CustomToggleComponent;
}
