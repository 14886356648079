import {createReducer, on} from '@ngrx/store';
import {resetWellnessReviewDetails, saveWellnessReviewDeatils} from '../actions';
import { wellnessReviewDeatilsInitialState } from '../initial-state';

const _wellnessDetailsReducer = createReducer(
    wellnessReviewDeatilsInitialState,
    on(saveWellnessReviewDeatils, (state, { payload }) => ({ ...state, ...payload })),
    on(resetWellnessReviewDetails, (state) => ({}))
);

export function wellnesReviewDetailsReducer(state = wellnessReviewDeatilsInitialState, action) {
    return _wellnessDetailsReducer(state, action);
}
