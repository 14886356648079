import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppContextService } from 'src/app/app.context.service';

@Component({
  selector: 'invalid-invite-code-popup',
  templateUrl: './invalid-invite-code-popup.component.html',
  styleUrls: ['./invalid-invite-code-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvalidInviteCodePopupComponent implements OnInit {

  config: any;
  constructor(public dialogRef: MatDialogRef<InvalidInviteCodePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvalidInviteCodePopupComponent,
    private _appContext: AppContextService) { 
      this.config = this._appContext.get("pages.userInfoSuccess.invalidInviteCodePopup");
  }

  ngOnInit(): void {
  }
  onClickOK(){
    this.dialogRef.close();
  }
}
