import {
    FormBuilderInputControl,
    FormBuilderConfig,
    FormBuilderRadioControl,
  } from "@crux/components";
  
  export interface StudioFormBuilderConfig extends FormBuilderConfig {
    title?: string;
    expanded?: boolean;
    hasChevron?: boolean;
    id?: string;
    footer?: string;
  }
  
  export function id__sendCodeForm(
    formConfig
  ): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
          key: "otpMethod",
          name: "otpMethod",
          type: "radio",
          placeholder: '',
          label: "",
          required: true,
          cssClass: 'custom-radio-button-control',
          options: <FormBuilderRadioControl>{
            values: formConfig['radioButtons'],
            mode: 'primary',
            layout: 'row',
          },
          expressions: {
          }
        },
      ]
    };
  }

  export function id__otpForm(
    formConfig
  ): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
          key: "otp",
          name: "otp",
          type: "input",
          label: formConfig["otpLabel"],
          placeholder: formConfig["otpPlaceHolder"],
          required: true,
          layout: "column",
          cssClass: "custom-resident-zip-code-input-control",
          validationMessages: {
            required: formConfig["otpRequiredMessage"],
            invalid: formConfig["otpInvalidMessage"],
            minLength: formConfig["otpMinLengthMessage"],
          },
          options: <FormBuilderInputControl>{
            type: "text",
            appearance: "legacy",
            hideRequiredMarker: true,
            autoComplete: "off",
            minlength: formConfig["otpMinLength"],
            maxlength: formConfig["otpMaxLength"],
            onlyAllow: formConfig["otpOnlyAllow"]
          },
          expressions: {
            blur: (formState: any) => {
              formState["otp"].markAsTouched();
            },
            change: (formState: any) => {
              formState["otp"].markAsUntouched();
            }
          }
        },
      ]
    };
  }

