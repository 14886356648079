import {createReducer, on} from '@ngrx/store';
import {resetWellnessClaimDetails, saveWellnessClaimDeatils} from '../actions';
import { wellnessClaimDeatilsInitialState } from '../initial-state';

const _wellnessDetailsReducer = createReducer(
    wellnessClaimDeatilsInitialState,
    on(saveWellnessClaimDeatils, (state, { payload }) => ({ ...state, ...payload })),
    on(resetWellnessClaimDetails, (state) => ({}))
);

export function wellnessClaimDetailsReducer(state = wellnessClaimDeatilsInitialState, action) {
    return _wellnessDetailsReducer(state, action);
}
