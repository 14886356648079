import { Action } from '@ngrx/store';

export const CLAIMANT_UPDATED = 'CLAIMANT_UPDATED';

export interface ClaimantlistAction extends Action {
  payload: {
    claimantlist: Object;
  };
}

export function getClaimantlist(claimantlist): ClaimantlistAction {
  return {
    type: CLAIMANT_UPDATED,
    payload: {
      claimantlist,
    },
  };
}

export class ClaimantlistSuccessAction implements Action {
  readonly type: string = CLAIMANT_UPDATED;
  constructor(public payload: Object) {}
}
