import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppContextService } from '../../app.context.service';

@Component({
  selector: 'app-studio-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublicLayoutComponent implements OnInit {
  constructor(private _appContext: AppContextService) {}

  ngOnInit() {}
}
