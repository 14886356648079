import { createReducer, on } from '@ngrx/store';
import { resetDashboardDetails, saveDashboardDeatils } from '../actions';
import { dashboardDeatilsInitialState } from '../initial-state';

const _documentDetailsReducer = createReducer(
    dashboardDeatilsInitialState,
    on(saveDashboardDeatils, (state, { payload }) => ({ ...state, ...payload })),
    on(resetDashboardDetails, (state) => ({}))
);

export function documentDetailsReducer(state = dashboardDeatilsInitialState, action) {
    return _documentDetailsReducer(state, action);
}
