import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { LoadingIndicatorService } from '../../../services';

import { MsalService, BroadcastService } from "@azure/msal-angular";


@Component({
    selector: "app-login-callback",
    templateUrl: "./login-callback.component.html"
})
export class LoginCallbackComponent implements OnInit {

    constructor(
        private authService: MsalService,
        private _router: Router
    ) {

    }

    ngOnInit() {
        if (
            this.authService.getAccount() &&
            !this.authService.isCallback(window.location.hash)
        ) {
            debugger;
            const accountDetails = this.authService.getAccount();
            if (accountDetails) {
                this._router.navigate(["en-US/registration/registrationMethodTwo/ecsr"]);
            }
        } else {
            debugger;
            this.authService.loginRedirect();
        }
    }

}
