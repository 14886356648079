
import { Component, OnInit } from '@angular/core';
import { AuthProviderService } from 'src/app/auth-provider-service';

@Component({
    selector: 'app-register',
    templateUrl: './manual-registration.component.html',
    styleUrls: ['./manual-registration.component.scss']
})

export class ManualRegistrationComponent implements OnInit {
    constructor(private _authProviderService: AuthProviderService) { }

    ngOnInit() {
        const authProvider = this._authProviderService.getRegistrationProvider();
            const extraQueryParameters = {
              ui_locales: 'en',
              language: 'en',
              prompt: "login"
            }
            authProvider.login(extraQueryParameters);
     }
}
