import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";

@Injectable()
export class LoginRedirectGuard implements CanActivate {
    registrationDetails = {};

    constructor(
        private _router: Router,
        private _store: Store<any>,
        private _location: Location
        ){
            this._store.subscribe(state =>{
                this.registrationDetails = state['registrationDetails'];
            })
        }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot){
        if(state.url === "/en-US/registration" ||
        state.url === "/es-US/registration" ||
        state.url === "/en-CA/registration" ||
        state.url === "/fr-CA/registration"
        ){
            return true;
        } else if(this.registrationDetails['redirectFlag'] === undefined){
            const url = state.url.split("/")[1] + "/login";
            this._router.navigateByUrl(url);
            return true;
        } else {
           return true;
        }
    }
}