import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";


@Component({
    selector: 'app-studio-custom-multiple-toggle',
    templateUrl: './custom-multiple-toggle.component.html',
    styleUrls: ['./custom-multiple-toggle.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class CustomMultipleToggleComponent implements OnInit {
    @Input() config: any;
    @Input() toggleButtons: any;
    isDisabled = false;
    claimType: any;
    appConfig: any
    country: any;
    @ViewChildren('toggleSelector') toggleSelectorInstance: any;
    constructor(
        private _store: Store<any>,
        private _router: Router
    ){
      let url = this._router.url;
      this.country = url.split('/')[1];
    }

    ngOnInit(){
      this.claimType = this.config?.["claimType"];
    }
    ngAfterViewInit() {
      this.toggleSelectorInstance.forEach((toggle, inx) => {
        toggle.el.nativeElement.querySelector("input").onchange = (event) => {
          if (event.currentTarget.value === "on") {
            // this.onChangeFunction(inx === 1 ? "es-US" : "en-US", inx);
            this.onChangeFunction(this.toggleButtons[inx].route, inx);
          }
        }
      });
    }
    onChangeFunction(route, i) {
        this.country = route;
        let url = this._router.url;
        const countryRoute = url.split("/")[1];
        window.scroll(0,0);
        this._router.navigateByUrl(url.replace(countryRoute, route));
      }

  }