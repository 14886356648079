import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppContextService } from 'src/app/app.context.service';
import { WebClaimsAppContextService } from 'src/app/webclaims-app.content.service';

@Component({
  selector: 'app-autopay-popup',
  templateUrl: './autopay-popup.component.html',
  styleUrls: ['./autopay-popup.component.scss']
})
export class AutopayPopupComponent implements OnInit {
  autoPayConfig:any;
  constructor(    public dialogRef: MatDialogRef<AutopayPopupComponent>, private _appContext: AppContextService) { 
    this.autoPayConfig = this._appContext.get('pages.billingPage.activeStepperObj.payment-amount');
    
  }

  ngOnInit(): void {
   
  }
  onClose(): void {
    this.dialogRef.close("span");
  }
}
