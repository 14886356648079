<div class="notFound">
    <div class="middle-content">        
        <p class="header"><img class="right-section-icon" src="{{config.alertLogo}}" alt="Alert Logo">{{data.Header}}</p>
        <p class="sub-text">{{data.SubHeader}} </p>
        <!-- <p class="sub-text">issued policy, please wait 3 business days from the date </p>
        <p class="sub-text">of issuance to register.</p> -->
    </div>
    <!-- <p class="sub-text">If you need assistance, please contact our Customer</p>
    <p class="sub-text">Care Team at xxx-xxx-xxxx or via email at </p>
    <p class="sub-text">xxxx@xxxx.com</p> -->
    <button (click)="onClickOK()">Ok</button>
</div>