import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Loading } from './loading.model';

@Component({
  selector: 'studio-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent implements Loading {
  @Input() title = 'Loading...';

  @Input() message: string;

  @Input() mode: 'fullscreen' | 'container' = 'fullscreen';

  constructor() {}
}
