import {
    FormBuilderInputControl,
    FormBuilderConfig,
  } from "@crux/components";
  
  export interface StudioFormBuilderConfig extends FormBuilderConfig {
    title?: string;
    expanded?: boolean;
    hasChevron?: boolean;
    id?: string;
    footer?: string;
  }

  function patternValidator(formState, patternStr, fieldName) {
    if (patternStr) {
      const pattern = new RegExp(patternStr);
      const formValue = formState[fieldName].value.trim();
      if (
        formState[fieldName].value &&
        !pattern.test(formValue)
      ) {
        formState[fieldName].setErrors({ invalid: true });
      }
    }
  }

  export function id__loginForm(formConfig): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
            key: "email",
            name: "email",
            type: "input",
            layout: "column",
            label: formConfig["emailLabel"],
            placeholder: formConfig["emailPlaceHolder"],
            required: true,
            cssClass: "studio-large-input-control",
            validationMessages: {
              required: formConfig["emailRequiredMessage"],
              // email: formConfig["emailInvalidMessage"],
              invalid: formConfig["emailInvalidMessage"],
            },
            options: <FormBuilderInputControl>{
              type: "text",
              appearance: "legacy",
              hideRequiredMarker: true,
              autoComplete: "off",
              maxlength: formConfig["emailMaxLength"],
            },
            expressions: {
              blur: (formState: any) => {
                formState["email"].markAsTouched();
              },
              change: (formState: any) => {
                formState["email"].markAsUntouched();
                patternValidator(
                  formState,
                  formConfig["emailPattern"],
                  "email"
                );
              },
            },
          },
      ]
    };
  }

