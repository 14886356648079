import { Action } from '@ngrx/store';
import { UserIdleModel } from '../../widgets/user-idle/user-idle.model';

export enum UserIdleActions {
  USER_IDLE_START_TRACKING = '[USER IDLE] USER IDLE START TRACKING',
  USER_IDLE_START_TRACKING_SUCCESS = '[USER IDLE] USER IDLE START TRACKING SUCCESS',
  USER_IDLE_STOP_TRACKING = '[USER IDLE] USER IDLE STOP TRACKING',
  USER_IDLE_STOP_TRACKING_SUCCESS = '[USER IDLE] USER IDLE STOP TRACKING SUCCESS',
  USER_IDLE_SHOW = '[USER IDLE] USER IDLE SHOW',
  USER_IDLE_SHOW_SUCCESS = '[USER IDLE] USER IDLE SHOW SUCCESS',
  USER_IDLE_HIDE = '[USER IDLE] USER IDLE HIDE',
  USER_IDLE_HIDE_SUCCESS = '[USER IDLE] USER IDLE HIDE SUCCESS',

  USER_IDLE_LOGOUT_START_TRACKING = '[USER IDLE] USER IDLE LOGOUT START TRACKING',
  USER_IDLE_LOGOUT_START_TRACKING_SUCCESS = '[USER IDLE] USER IDLE LOGOUT START TRACKING SUCCESS',
}

export class UserIdleStartTrackingAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_START_TRACKING;

  constructor() {}
}

export class UserIdleStartTrackingSuccessAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_START_TRACKING_SUCCESS;

  constructor(public payload: UserIdleModel) {}
}

export class UserIdleStopTrackingAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_STOP_TRACKING;

  constructor() {}
}

export class UserIdleStopTrackingSuccessAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_STOP_TRACKING_SUCCESS;

  constructor(public payload: UserIdleModel) {}
}

export class UserIdleShowAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_SHOW;

  constructor() {}
}

export class UserIdleShowSuccessAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_SHOW_SUCCESS;

  constructor(public payload: Pick<UserIdleModel, 'isIdle'>) {}
}

export class UserIdleHideAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_HIDE;

  constructor() {}
}

export class UserIdleHideSuccessAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_HIDE_SUCCESS;

  constructor(public payload: Pick<UserIdleModel, 'isIdle'>) {}
}

export class UserIdleLogoutStartTrackingAction implements Action {
  readonly type: string = UserIdleActions.USER_IDLE_LOGOUT_START_TRACKING;

  constructor() {}
}

export class UserIdleLogoutStartTrackingSuccessAction implements Action {
  readonly type: string =
    UserIdleActions.USER_IDLE_LOGOUT_START_TRACKING_SUCCESS;

  constructor(public payload: UserIdleModel) {}
}

export type UserIdleActionType =
  | UserIdleStartTrackingAction
  | UserIdleStartTrackingSuccessAction
  | UserIdleStopTrackingAction
  | UserIdleStopTrackingSuccessAction
  | UserIdleShowAction
  | UserIdleShowSuccessAction
  | UserIdleHideAction
  | UserIdleHideSuccessAction
  | UserIdleLogoutStartTrackingAction
  | UserIdleLogoutStartTrackingSuccessAction;
