import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Adb2cState } from '@chubb-auth/types';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthProviderService } from '../auth-provider-service';
import { FormValidationService } from '../services/form-validation.service';
import {
  saveDashboardDeatils,
} from '../state/actions';
@Injectable()
export class LoginContextGuard implements CanActivate, OnDestroy {
  b2cState: any;
  isLoggedIn: any;
  registrationDetails = {};
  _routerSubscription: Subscription;
  dashboardStore = {};
  regionObj = {
    MSregion: '',
    CLregion: '',
    lsp: '',
    ClaimRegionName: '',
  };

  constructor(private _authProviderService: AuthProviderService,
    private _store: Store<any>,
    private _router: Router,
    private route: ActivatedRoute,
    private _formValidationService: FormValidationService) {
    this._store.subscribe(state => {
      this.registrationDetails = cloneDeep(state["registrationDetails"]);
      this.dashboardStore = cloneDeep(state['dashboardDetails']);
    });

    this._routerSubscription = this._router.events.subscribe(rt => {
      this.storeRegionParameters(rt['state']);
    });
  }

  canActivate(next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authProviderService.firstLogin) {
      this._authProviderService.firstLogin = false;
      return true;
    } else if (!this._authProviderService.firstLogin) {
      const authProvider = this._authProviderService.getAuthProvider();
      const loggedIn = localStorage.getItem("loggedIn");
      if (loggedIn === "true") {
        //refresh and new tab scenario
        return new Promise((resolve) => {
          authProvider.getAccessToken().pipe(catchError(error => {
            localStorage.clear();
            let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
            const path = routerState.url.split("/")[1];
            if (path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA")) {
              logoutUrl = logoutUrl + path + "/login";
            } else {
              logoutUrl = logoutUrl + "en-US/login";
            }
            authProvider.logout(logoutUrl);
            return throwError(error);
          })).subscribe((state: Adb2cState) => {
            if (state.accessToken && state.isLoggedIn) {
              this._authProviderService.saveLoggedInSessionInfo(state)
              this._authProviderService.setLoggedInFlg(true);
              this._authProviderService.setLanguage(routerState.url.split("/")[1]);
              this._formValidationService.dashBoardApiResponse$.subscribe(flg => {
                if (flg === true) {
                  resolve(true)
                } else {
                  resolve(false);
                }
              })
            } else {
              localStorage.clear();
              let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
              const path = routerState.url.split("/")[1];
              if (path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA")) {
                logoutUrl = logoutUrl + path + "/login";
              } else {
                logoutUrl = logoutUrl + "en-US/login";
              }
              authProvider.logout(logoutUrl);
              resolve(false);
            }
          }, (error) => {
            localStorage.clear();
            let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
            const path = routerState.url.split("/")[1];
            if (path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA")) {
              logoutUrl = logoutUrl + path + "/login";
            } else {
              logoutUrl = logoutUrl + "en-US/login";
            }
            authProvider.logout(logoutUrl);
            resolve(false);
          })
        })
      } else {
        localStorage.clear();
        // let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
        let logoutUrl;
        const path = routerState.url.split("/")[1];
        if (path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA")) {
          logoutUrl = path + "/login";
        } else {
          logoutUrl = "en-US/login";
        }
        this._router.navigate([logoutUrl]);
        return false;
      }
    } else {
      localStorage.clear();
      const path = routerState.url.split("/")[1];
      this._router.navigate([path + "/login"]);
      return false;
    }
  }

  storeRegionParameters(routerState: RouterStateSnapshot) {
    if (routerState && routerState.root && routerState.root.queryParams) {
      const params = routerState.root.queryParams;
      if (
        !this.dashboardStore['regionParams'] &&
        params &&
        (params['MSregion'] ||
          params['CLregion'] ||
          params['lsp'] ||
          params['ClaimRegionName'])
      ) {
        this.regionObj = {
          MSregion: params['MSregion'] ? params['MSregion'] : '',
          CLregion: params['CLregion'] ? params['CLregion'] : '',
          lsp: params['lsp'] ? params['lsp'] : '',
          ClaimRegionName: params['ClaimRegionName']
            ? params['ClaimRegionName']
            : environment.webclaimURL.RegionName,
        };
        this.dashboardStore['regionParams'] = this.regionObj;
        this._store.dispatch(
          saveDashboardDeatils({ payload: this.dashboardStore })
        );
      }
    }

  }

  ngOnDestroy() {
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }
}