export const b2cUatConfig = {
  loginConfig: {
    loginAuthProvider: 'MSAL2',
    loginTenantId: 'auth.uat.chubb.com',
    loginClientId: '79c77de6-a2d0-47d7-b1df-701a2ab41e46',
    loginRedirectUrl: 'https://my-uat.combinedinsurance.com/login-callback',
    loginPolicy: 'B2C_1A_SUSICICADIGITAL',
    loginScopes: [
      'https://uatb2c.chubbdigital.com/CICADigitalApi/read',
      'https://uatb2c.chubbdigital.com/CICADigitalApi/write',
    ],
  },

  logoutConfig: {
    logoutRedirectUrl: 'https://my-uat.combinedinsurance.com/',
  },

  changePasswordConfig: {
    changePasswordAuthProvider: 'MSAL2',
    changePasswordRedirectUrl:
      'https://my-uat.combinedinsurance.com/login-callback',
    changePasswordTenantId: 'auth.uat.chubb.com',
    changePasswordPolicy: 'B2C_1A_RESETPASSWORDCICA',
    changePasswordClientId: '79c77de6-a2d0-47d7-b1df-701a2ab41e46',
    changePasswordScope: [
      'https://uatb2c.chubbdigital.com/CICADigitalApi/read',
      'https://uatb2c.chubbdigital.com/CICADigitalApi/write',
    ]
  },

  registrationConfig: {
    registrationAuthProvider: 'MSAL2',
    registrationTenantId: 'auth.uat.chubb.com',
    registrationPolicy: 'B2C_1A_SignUpCICA',
    registrationClientId: '79c77de6-a2d0-47d7-b1df-701a2ab41e46',
    registrationRedirectUrl:
      'https://my-uat.combinedinsurance.com/login-callback',
    registrationScopes: [
      'https://uatb2c.chubbdigital.com/CICADigitalApi/read',
      'https://uatb2c.chubbdigital.com/CICADigitalApi/write',
    ],
  },

  registrationTokenConfig: {
    appId: 'e5cab4e4-a1fe-4aa6-9184-6f741b76ea5c',
    appKey: '=wAM.RJ#x-(/6\\6dX"82-d.4',
    apiVersion: '2',
    resource: '19a94f72-2dc5-4b2a-9795-2d4becf700f0',
    subscriptionKey: '0790ae03cb02400f9c95386b1d9ba7d9',
    url: 'https://nauat.chubbdigital.com/api/tokens',
  },

  userProfileTokenConfig: {
    appId: '22841036-2507-45f1-8f11-ecfbfb27c98e',
    appKey: 'mAI34g~5QK.2JKBkVyzoqFb0lJGP~l_F_O',
    apiVersion: '2',
    resource: '4d78f778-2ca3-4f32-9d0e-8d5e2fa6259e',
    subscriptionKey: '579775fd5cf645c8bb8214993d310466',
    url: 'https://nauat.chubbdigital.com/api/tokens',
    resetPasswordTemplate: 'https://my-uat.combinedinsurance.com/assets/en/reset-password-email-template.html'
  },

  twilioTokenConfig: {
    appId: 'e5cab4e4-a1fe-4aa6-9184-6f741b76ea5c',
    appKey: '=wAM.RJ#x-(/6\\6dX"82-d.4',
    apiVersion: '2',
    resource: '19a94f72-2dc5-4b2a-9795-2d4becf700f0',
    subscriptionKey: '0790ae03cb02400f9c95386b1d9ba7d9',
    url: 'https://nauat.chubbdigital.com/api/tokens',
  },

  // twilioTokenConfig:{
  //   appId: '4cacefe5-f69d-48e3-b363-4dad16548e54',
  //   appKey: 'mebD2j_K1ltyv~LLM.0jvqflixNb4g-Gi~',
  //   apiVersion: '2',
  //   resource: 'f835741e-c797-4a1e-81ed-9cde60970fce',
  //   subscriptionKey: '35eade137b7b447cb2700f5b57b6557e',
  //   url: 'https://nauat.chubbdigital.com/api/tokens'
  // },

  registrationTokenbuilderConfig: {
    clientId: '79c77de6-a2d0-47d7-b1df-701a2ab41e46',
    tentantId: 'auth.uat.chubb.com',
    policy: 'B2C_1A_SignUpCICA',
    replyUrl: 'https://my-uat.combinedinsurance.com',
  },
};
