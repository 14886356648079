<div class="custom-radio-group-container" [ngClass]="customClassId">
  <ng-container *ngFor="let item of radioValues; index as i">
    <div class="radio-group-item" [ngClass]="i === selectedIndex ? 'radio-group-item-active':'radio-group-item-inactive'">
      <div class="radio-circle-container">
        <crux-radio name="custom-crux-radio-{{customClassId}}" [values]="convertObjToArray(item)" displayProp="name"
          valueProp="value" layout="column" labelPosition="before" [formControl]="customFormControl"
          (valueSelected)="getSelectedRadioValue($event, i, item)">
        </crux-radio>
      </div>
      <div class="radio-label-container">
        <div class="main-label">
          {{item.name}}
        </div>
        <!-- <div class="sub-label" *ngIf="item.showForm">
          <div *ngIf="customFormControl.value && (selectedIndex === i)">
            <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event, i, item)"></crux-form-builder>
          </div>
        </div> -->
      </div>
    </div>
  </ng-container>
</div>