import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface RowTypes {
  POLICY_DUE: string;
  PAYMENT: string;
  MESSAGE: string;
  DUCUMENT: string;
}

export interface Notification {
  ClaimNumber: string;
  Title: string;
  Message: string;
  IsRead: boolean;
  AlertId: string;
  Category: string;
  Group: string;
  CreatedDate: string;
  MessageId: number;
}

export interface MessageList {
  Title: string;
  Body: string;
  MessageId: number;
  Language: string;
}

export interface NotifListResponse {
  Code: string;
  Message: any;
  UnreadCount: string;
  Notifications: Array<Notification>;
  MessageList: Array<MessageList>;
}

export interface NotificationReadRequest {
  SearchKey: {
    Channel: string,
    SessionId: string,
    UserId: string
  };
  ReadAlertList: Array<string>;
  UnReadAlertList: Array<string>;
}

export interface NotificationRequest {
  ClaimDetails: Array<{
    ClaimNumber: number;
    ClaimStatus: string;
    ClaimReportedDate: string;
    ClaimType: string;
    IsActive: boolean;
  }>;
  SearchKey: {
    Channel: string;
    SessionId: string;
    UserId: string;
  };
  PolicyDetails: Array<any>;
  CustomerData: Array<any>;
  FilterByDays: number;
  Country: string;
}

export const categoryStr = {
  CLAIM: 'claim',
  BILLING: 'billing',
  CUSTOM: 'custom'
};

const avalableLang = {
  English: 'English',
  Spanish: 'Spanish',
  French: 'French'
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private _http: HttpClient) { }

  getNotifications(requestBody: NotificationRequest | any, apiToken): Observable<NotifListResponse | any> {
    const mockURL = 'assets/mock-json/mock-notification-list.json';
    // return this._http.get(mockURL).pipe(map((response: NotifListResponse) => response));
    const url = environment.notification.notifHistory;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey
    });
    return this._http
      .post(url, requestBody, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

  }

  markRead(requestBody: NotificationReadRequest, apiToken): Observable<any> {
    // return of({
    //   Code: '0000',
    //   Message: 'Successful'
    // });
    const url = environment.notification.markRead;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: apiToken,
      apiVersion: '1',
      'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey
    });
    return this._http
      .post(url, requestBody, { headers: headers })
      .pipe(map((response: HttpResponse<any>) => response));

  }

  formatNotificationData(notifListResponse: NotifListResponse, rowtypes: RowTypes, selectedLang: string): Array<any> {
    if (notifListResponse && notifListResponse.Notifications) {
      return notifListResponse.Notifications.map(data => {
        return {
          ...data,
          Type: this.getType(data.Group, rowtypes),
          Category: this.getCategory(data.Group),
          Message: this.getContentForLang(data, notifListResponse.MessageList, selectedLang),
          Date: data.CreatedDate
        };
      });
    }
    return [];
  }

  private getType(group: string, rowtypes: RowTypes): string {
    switch (group) {
      case 'ClaimNotification':
        return rowtypes.DUCUMENT;
      case 'PastDueNotification':
        return rowtypes.POLICY_DUE;
      case 'RecurringNotification':
        return rowtypes.PAYMENT;
      case 'CustomNotification':
        return rowtypes.MESSAGE;
      case 'CreditCardExpiryNotification':
        return rowtypes.PAYMENT;
      default:
        return rowtypes.MESSAGE;
    }
  }

  private getCategory(group: string): string {
    switch (group) {
      case 'ClaimNotification':
        return categoryStr.CLAIM;
      case 'PastDueNotification':
        return categoryStr.BILLING;
      case 'RecurringNotification':
        return categoryStr.BILLING;
      case 'CustomNotification':
        return categoryStr.CUSTOM;

      default:
        return categoryStr.BILLING;
    }
  }

  private getContentForLang(notifObj: Notification, messageList: Array<MessageList>, selectedLang: string) {
    let langObj = {
      Body: '',
      Language: '',
      MessageId: 0,
      Title: ''
    };
    const existingMessage = messageList.find(msg => msg.MessageId === notifObj.MessageId && msg.Language === selectedLang);
    langObj = existingMessage ? existingMessage : langObj;
    return langObj;
  }
}

