import { Action } from '@ngrx/store';
import { SectionsReady } from '../../widgets/shared/section';

export enum SectionsReadyActions {
  SECTION_READY_SUCCESS = '[SECTION] SECTION READY SUCCESS',
  SECTION_READY_FAILURE = '[SECTION] SECTION READY FAILURE',
  SECTION_READY_DESTROY_SUCCESS = '[SECTION] SECTION READY DESTROY SUCCESS',
  SECTION_READY_DESTROY_FAILURE = '[SECTION] SECTION READY DESTROY FAILURE',
}

export class SectionsReadySuccessAction implements Action {
  readonly type: string = SectionsReadyActions.SECTION_READY_SUCCESS;

  constructor(public payload: Partial<SectionsReady>) {}
}

export class SectionsReadyFailureAction implements Action {
  readonly type: string = SectionsReadyActions.SECTION_READY_FAILURE;

  constructor(public payload: Partial<SectionsReady>) {}
}

export class SectionsReadyDestroySuccessAction implements Action {
  readonly type: string = SectionsReadyActions.SECTION_READY_DESTROY_SUCCESS;

  constructor(public payload: string) {}
}

export class SectionsReadyDestroyFailureAction implements Action {
  readonly type: string = SectionsReadyActions.SECTION_READY_DESTROY_FAILURE;

  constructor(public payload: string) {}
}

export type SectionsReadyActionType =
  | SectionsReadySuccessAction
  | SectionsReadyFailureAction
  | SectionsReadyDestroySuccessAction
  | SectionsReadyDestroyFailureAction;
