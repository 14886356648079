import {
  UserIdleActions,
  UserIdleActionType,
  UserIdleHideSuccessAction,
  UserIdleLogoutStartTrackingSuccessAction,
  UserIdleShowSuccessAction,
  UserIdleStartTrackingSuccessAction,
} from '../actions/user-idle.action';

export function userIdleReducer(state = {}, action: UserIdleActionType) {
  switch (action.type) {
    case UserIdleActions.USER_IDLE_START_TRACKING:
    case UserIdleActions.USER_IDLE_STOP_TRACKING:
      return state;
    case UserIdleActions.USER_IDLE_START_TRACKING_SUCCESS:
    case UserIdleActions.USER_IDLE_STOP_TRACKING_SUCCESS:
      return {
        ...state,
        ...(<UserIdleStartTrackingSuccessAction>action).payload,
      };
    case UserIdleActions.USER_IDLE_LOGOUT_START_TRACKING:
    case UserIdleActions.USER_IDLE_LOGOUT_START_TRACKING_SUCCESS:
      return {
        ...state,
        ...(<UserIdleLogoutStartTrackingSuccessAction>action).payload,
      };
    case UserIdleActions.USER_IDLE_SHOW:
      return state;
    case UserIdleActions.USER_IDLE_SHOW_SUCCESS:
      return { ...state, ...(<UserIdleShowSuccessAction>action).payload };
    case UserIdleActions.USER_IDLE_HIDE:
      return state;
    case UserIdleActions.USER_IDLE_HIDE_SUCCESS:
      return { ...state, ...(<UserIdleHideSuccessAction>action).payload };
    default:
      return state;
  }
}
