import { Action } from '@ngrx/store';

export enum PolicyDataActions {
  POLICY_SAVE_DATA = '[Policy Details] Save Data'
}

export class savePolicyDetails implements Action {
  readonly type: string = PolicyDataActions.POLICY_SAVE_DATA;
  constructor(public payload: Partial<any>) { }
}


export type PolicyDataActionsTypes =
  | savePolicyDetails;