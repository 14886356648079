import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormService } from 'src/app/services';
import { LanguageSelectionSuccessAction } from '../../state/actions/language-selection.action';
import { LanguageChangePopupComponent } from '../../widgets/language-change-popup/language-change-popup.component';
import { AppContextService } from '../../app.context.service';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  config: any;
  routerEventSub: Subscription;
  appConfig: any;
  dialogRef: any;
  footerConfig: any;
  navbarConfig: any;
  _opened = false;
  menuListMobile = [];
  languageRoute: any;
  languageConfig: any;
  constructor(
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _router: Router,
    private _service: FormService,
    private _store: Store<any>,
    private _dialog: MatDialog
  ) {
    this.navbarConfig = this._appContext.get('navbar');
    this.menuListMobile = this._appContext.get("pages.registrationNavbar.menuListMobile.default");
    this.config = this._appContext.get("pages.resetPasswordPage");
    this.footerConfig = this._appContext.get("footer");
    this.languageConfig = this._appContext.get("footer");
    this.languageRoute = this._appContext.get('language');
    this.routerEventSub = this._router.events.pipe(
      filter( (event: NavigationEvent) => {
        return (event instanceof NavigationStart)
      })
    ).subscribe((event: NavigationStart) =>{
      if(event.navigationTrigger === "popstate"){
        this._router.navigate([this.config["loginPageLink"]]);
      }
    });
    this._loadingService.closeLoadingIndicator();
  }

  ngOnInit() {
    
  }

  navigate(){
    this._router.navigate([this.config["clickHereLink"]]);
  }

  onCountrySelection() {
    this.dialogRef = this._dialog.open(LanguageChangePopupComponent, {
      height: 'auto',
      data: {},
      disableClose: true,
    });
  }
  _toggleSidebar() {
    this._opened = !this._opened;
  }
  _toggleSidebarClose() {
    this._opened = false;
  }
  onRedirect(item){
    this._opened = !this._opened;
    let url = this._router.url;
    const countryRoute = url.split("/")[1];
    window.scroll(0,0);
    this._router.navigateByUrl(url.replace(countryRoute, item.path));
  }

  ngOnDestroy(){
    this.routerEventSub.unsubscribe();
  }
}
