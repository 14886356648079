import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { LanguageChangePopupComponent } from '../../language-change-popup/language-change-popup.component';
import { Router } from '@angular/router';

const routesTODisable = [
  "accident",
  "sickness",
  "life",
  "cancer",
  "wellness",
  "start-new-claim",
  "contact-us",
  "payment-amount",
  "payment-method",
  "payment-confirmation"
];

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  @Input() footerConfig: any;
  @Input() languageConfig: any;
  contentSection: any;
  getInTouchSection: any;
  dialogRef: any;
  IframeData: any;
  isCwbUser: boolean;
  isRegistrationPage: boolean = false;
  isCountryBtnDisabled: boolean = false;

  constructor(private _store: Store<any>, private dialog: MatDialog,private _router: Router) {}

  ngOnInit(): void {
    if((this._router.url.includes('/registration')) || (this._router.url.includes('/success-user-info')) || (this._router.url.includes('/select-user-id'))){
      this.isRegistrationPage = true;
    }
    this.contentSection = this.footerConfig.content.default;
    this.getInTouchSection = this.footerConfig.getInTouchSection;
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    // Disable Country Language Button
    const currentPage = document.location.pathname.split('/');
    const routerStr = currentPage[currentPage.length - 1];
    const routerWellnessStr = currentPage[currentPage.length - 2];
    if (routesTODisable.indexOf(routerStr) !== -1 || routerWellnessStr === 'wellness') {
      this.isCountryBtnDisabled = true;
    }
    this._store.subscribe((state) => {
      // console.log(state);
      if (state !== undefined) {
        this.IframeData = state.iFrameData;
        if(this.IframeData){
          this.isCwbUser = this.IframeData.isCwbUser;
        }    
        if (state.iFrameData !== undefined && state.iFrameData['PolicyDetails'] &&
        state.iFrameData['PolicyDetails'].length > 0) {
          this.getInTouchSection.rightSectionList.forEach((element) => {
            if (element.id === '2') {
              if (state.iFrameData.HelplineIndicator !== undefined) {
                switch (state.iFrameData.HelplineIndicator) {
                  case 'CICA':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.cicaNumber;
                    break;
                  case 'CLICNY':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.cicaNYNumber;
                    break;
                  case 'WRKS':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.wrksNumber;
                    break;
                  case 'WRKSNY':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.wrksNYNumber;
                    break;
                  case 'PFP':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.pfpNumber;
                    break;
                  case 'PFPNY':
                    element.text2 = this.isCwbUser ? element.cwbNumber : element.pfpNYNumber;
                    break;
                  case 'CWB':
                    element.text2 = element.cwbNumber;
                    break;
                }
              }
            }
          });
        } else if (this.getInTouchSection.dashboardKnockout && this.getInTouchSection.dashboardKnockout === 'true') {
          this.getInTouchSection.rightSectionList.forEach((element) => {
            if (element.id === '2') {
              element.text1 = 'Call us US:'
              element.text2 = this.isCwbUser ? element.cwbNumber : element.cicaNumber;
              element['text3'] = 'CA:';
              element['text4'] = element.cicaCANumber;
            }
          });
        } else if ((this._router.url.includes('/registration')) || (this._router.url.includes('/success-user-info')) || 
        (this._router.url.includes('/select-user-id')) || (this._router.url.includes('/login')) ) {
        // else if ((this._router.url.includes('en-US/registration')) || (this._router.url.includes('es-US/registration')) 
        // || (this._router.url.includes('fr-CA/registration')) || (this._router.url.includes('en-CA/registration'))) {
          this.getInTouchSection.rightSectionList.forEach((element) => {

            if (element.id === '2' && this._router.url.includes('/en-US')) {
              element.text1 = 'Questions? Call'
              element.text2 = this.isCwbUser ? '866-445-8874' : '800-225-4500';
              // element['text3'] = 'Questions? Call';
              // element['text4'] = '800-225-4500';
             
            } else if(element.id === '2' && this._router.url.includes('/es-US')) {
              element.text1 = '¿Preguntas? Llame al'
              element.text2 = this.isCwbUser ? '866-445-8874' : '800-225-4500';
              // element['text3'] = '¿Preguntas? Llame al';
              // element['text4'] = '800-225-4500';
            } else if (element.id === '2' && this._router.url.includes('/en-CA')) {
              element.text1 = 'Questions? Call'
              element.text2 = this.isCwbUser ? '866-445-8874' : '1-888-234-4466';
              // element['text3'] = 'Questions? Call';
              // element['text4'] = '800-225-4500';
            } else if(element.id === '2' && this._router.url.includes('/fr-CA')) {
              element.text1 = 'Questions ? Appelez le'
              element.text2 = this.isCwbUser ? '866-445-8874' : '1-888-234-4466';
              // element['text3'] = 'Questions ? Appelez le';
              // element['text4'] = '800-225-4500';//element.cicaCANumber;

            }
          }); 

        }
    }
  });
}

   
  navigateTo(link) {
    if (link !== '' && link !== undefined && link !== '#') {
      window.open(link, '_blank');
    }
  }
  onCountrySelection() {
    this.dialogRef = this.dialog.open(LanguageChangePopupComponent, {
      height: 'auto',
      data: {},
      disableClose: true,
    });
  }

  goToSupportPage(){
    console.log(document.location.pathname);
    if(this.IframeData.CustomerDetails) {
      this._router.navigate([
        document.location.pathname.substr(1, 5) +
        '/support'
      ]);
    }else {
      let Path = document.location.pathname;
      if(Path.includes('US'))
      window.open(this.footerConfig.USSupportURL, '_blank');
      else
      window.open(this.footerConfig.CanadaSupportURL, '_blank');
    }
    
  }
}
