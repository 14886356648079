<div class="otp-container">
    <p class="header">{{config.otpPopup.headerText}}{{selectedOption?.type}}{{config?.otpPopup?.headerText2}}.</p>
    <p class="sub-text">{{config.otpPopup.SubHeaderText}}</p>
    <p class="sub-text1">{{config.otpPopup.SubHeaderText1}}  <span class=" sub-text1 text-link" (click)="sendOTP()">{{config.otpPopup.SubHeaderText2}}</span></p>
        <input type="text" [(ngModel)]="otp" maxlength="6" pattern="^[0-9]+$" (keypress)="onKeyPress($event)" class="form-field" (blur)="onBlurOtp()">
        <p class="error" *ngIf="incorrectCode">{{config.otpPopup.invalidCode}} </p>
        <p class="error" *ngIf="!incorrectCode && validationMessage">{{config.otpPopup.EmptyErrorCode}} </p>
    <br/>
    <!-- <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder> -->
    <button class="otp-button" (click)="otp?.length == 6 && callVerificiationCheck(otp)">{{config.otpPopup.SubmitButton}}</button>
    <p class="subtext text-link1" (click)="closeDialog()">{{config.otpPopup.Back}}</p>
</div> 
