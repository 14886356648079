<div class="language-change-popup">
    <div class="header">
        <div class="region">{{languageConfig.headerLabel}}</div>
        <div (click)="onNoClick()" class="close" appSharedNew tabindex="0" role="button">{{languageConfig.closeLabel}}</div>
    </div>
    <div class="mainDiv">
        <div class="langSelectDiv">
            <div class="countrySelector" *ngFor="let item of languageConfig.popUpConfigTop"  
            (click)="onButtonClick(item.route)" [ngClass]="item.route === countryRoute ? 'isActive' : null" appSharedNew tabindex="0" role="button">
                <span class="countryLabel">
                    <span class="label">{{item.label}}</span>
                    <span class="arrow">{{item.arrow}}</span>
                </span>
                <span class="nextLine">{{item.subLabel}}</span>
            </div>
        </div>
        <div  class="langSelectDiv" >
            <div class="countrySelector" *ngFor="let itemNew of languageConfig.popUpConfigBottom" [ngClass]="itemNew.route === countryRoute ? 'isActive' : null" (click)="onButtonClick(itemNew.route)" appSharedNew tabindex="0" role="button">
                <span class="countryLabel">
                    <span class="label">{{itemNew.label}}</span>
                    <span class="arrow">{{itemNew.arrow}}</span>
                </span>
                <span class="nextLine">{{itemNew.subLabel}}</span>
            </div>
        </div>
    </div>
</div>