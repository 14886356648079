import { Action } from '@ngrx/store';
import {
  LanguageSelection,
  getLanguage,
} from '../initial-state/language-selection.state';
import {
  LanguageSelectionAction,
  LANGUAGE_CHANGED,
} from '../actions/language-selection.action';

export function LanguageSelectionReducer(
  state: LanguageSelection = getLanguage(),
  action: Action
) {
  switch (action.type) {
    case LANGUAGE_CHANGED: {
      const typedAction = <LanguageSelectionAction>action;
      return {
        ...state,
        en: typedAction.payload.en,
        es: typedAction.payload.es,
        fr: typedAction.payload.fr,
      };
    }
    default: {
      return state;
    }
  }
}
