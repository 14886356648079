import {
    StepWizardReadyActions,
    StepWizardReadyActionType,
  } from '../actions/step-wizard.action';
  import { StepWizardReady } from '../actions/step-wizard.action';

  export function stepWizardReducer(
    state = {},
    action: StepWizardReadyActionType
  ) {
    switch (action.type) {
      case StepWizardReadyActions.STEPWIZARD_READY_SUCCESS:
        return { ...state, ...(<Partial<StepWizardReady>>action.payload) };
      case StepWizardReadyActions.STEPWIZARD_READY_FAILURE:
        return { ...state, ...(<Partial<StepWizardReady>>action.payload) };
      case StepWizardReadyActions.STEPWIZARD_READY_DESTROY_SUCCESS:
      case StepWizardReadyActions.STEPWIZARD_READY_DESTROY_FAILURE:
        const newState = { ...state };
        delete newState[<string>action.payload];
        return newState;
      default:
        return state;
    }
  }
