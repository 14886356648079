<div class="loading-widget-dialog">
  <!-- <h1 class="title" mat-dialog-title>loading widget</h1> -->
  <div class="loading-claims">
    <div class="loading-wheel">
      <img src="{{labels.wheelImg}}" />
    </div>
    <p align="center" class="loading-header">{{labels.header}}</p>
    <div class="loading-message">
      {{labels.message}}
    </div>
    <div class="loadingContent">
      <div class="applyBgColor"><span class="loadingContentMain">
          {{labels.mainContent}}
        </span>
      </div>
      <div class="imageFamily">
        <img src="{{labels.familyImage}}" class="familyClass">
      </div>
      <div class="applyBgColor increaseWidth">
        <span class="loadingContentSub">
          {{labels.loadingContentSub}}
        </span>
      </div>
      <div class="applyBgColor increaseWidth">
        <span class="loadingContentData">
          {{labels.loadingContentData}}
        </span>
      </div>

    </div>
  </div>
</div>