import { Action } from '@ngrx/store';
export interface StepWizardReady {
    [key: string]: any;
  }
export enum StepWizardReadyActions {
  STEPWIZARD_READY_SUCCESS = '[STEPWIZARD] STEPWIZARD READY SUCCESS',
  STEPWIZARD_READY_FAILURE = '[STEPWIZARD] STEPWIZARD READY FAILURE',
  STEPWIZARD_READY_DESTROY_SUCCESS = '[STEPWIZARD] STEPWIZARD READY DESTROY SUCCESS',
  STEPWIZARD_READY_DESTROY_FAILURE = '[STEPWIZARD] STEPWIZARD READY DESTROY FAILURE',
}

export class StepWizardReadySuccessAction implements Action {
  readonly type: string = StepWizardReadyActions.STEPWIZARD_READY_SUCCESS;

  constructor(public payload: Partial<StepWizardReady>) {}
}

export class StepWizardReadyFailureAction implements Action {
  readonly type: string = StepWizardReadyActions.STEPWIZARD_READY_FAILURE;

  constructor(public payload: Partial<StepWizardReady>) {}
}

export class StepWizardReadyDestroySuccessAction implements Action {
  readonly type: string =
    StepWizardReadyActions.STEPWIZARD_READY_DESTROY_SUCCESS;

  constructor(public payload: string) {}
}

export class StepWizardReadyDestroyFailureAction implements Action {
  readonly type: string =
    StepWizardReadyActions.STEPWIZARD_READY_DESTROY_FAILURE;

  constructor(public payload: string) {}
}

export type StepWizardReadyActionType =
  | StepWizardReadySuccessAction
  | StepWizardReadyFailureAction
  | StepWizardReadyDestroySuccessAction
  | StepWizardReadyDestroyFailureAction;
