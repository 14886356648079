import { createReducer, on } from '@ngrx/store';
import { resetMemberClaimDetails, saveMemberClaimDetails } from '../actions';
import { memberClaimDetailsInitialState } from '../initial-state';

const _memberClaimDetailsReducer = createReducer(
  memberClaimDetailsInitialState,
  on(saveMemberClaimDetails, (state, { payload }) => ({ ...state, ...payload })),
  on(resetMemberClaimDetails, (state) => ({}))
);

export function memberClaimDetailsReducer(
  state = memberClaimDetailsInitialState,
  action
) {
  return _memberClaimDetailsReducer(state, action);
}
