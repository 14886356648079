import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { FormService } from '../../services/form.services';
import { AppContextService } from '../../app.context.service';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import {
  StudioFormBuilderConfig,
  id__otpForm,
  id__adduserIdForm,
} from './set-user-id.constant';
import {
  Router,
  Event as NavigationEvent,
  NavigationStart,
} from '@angular/router';
import { filter, take, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { AuthProviderService } from '../../auth-provider-service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralizedPopUpComponent } from '../../widgets/shared/generalized-popup/generalized-popup.component';
import { formatApiError } from '../../widgets/shared/utilities';
import { OtpPopupComponent } from '../../widgets/shared/otp-popup/otp-popup.component';

@Component({
  selector: 'app-set-user-id',
  templateUrl: './set-user-id.component.html',
  styleUrls: ['./set-user-id.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SetUserIdComponent implements OnInit, OnDestroy {
  config: any;
  registrationDetailsObject: any;
  formBuilderConfig: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formBuilderConfig1: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formBuilderConfig2: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formGroup = new FormGroup({});
  radioControl = new FormControl();
  radioValues = [];
  showOtpField: boolean;
  showUserIdField: boolean;
  selectedUserId: string;
  resendCount: number;
  submitCount: number;
  authTokenValue: string;
  sessionToken: any;
  disableContinueButton: boolean;
  sendButtonText: string;
  routerEventSub: Subscription;
  storeSub: Subscription;
  authProvider: any;
  showResendText: boolean;
  appConfig: any;
  dialogRef: any;
  footerConfig: any;
  userProfileResponse: any;
  somethingWentWrongError: any;
  showUserIdError: boolean;
  overrideUserIdFlag: boolean;
  serverError: any;
  language: any;
  stepperConfig: any;
  productConfig: any;
  stepsActivity: any;
  navigationState: any;
  backToRegisterationPage: string;
  languageConfig: any;
  constructor(
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _store: Store<any>,
    private _service: FormService,
    private _router: Router,
    private _authProviderService: AuthProviderService,
    private _dialog: MatDialog
  ) {
    this.navigationState = _router.getCurrentNavigation().extras.state;
    this.somethingWentWrongError = this._appContext.get('somethingWentWrong');
    this.stepperConfig = this._appContext.get(
      'pages.registration.stepper.payment-amount'
    );
    // steps, editLinkLabel
    this.productConfig = this._appContext.get(
      'pages.registration.stepper.productInfo'
    );
    // showImg, currentStep
    this.stepsActivity = this._appContext.get(
      'pages.registration.stepper.payment-amount.stepperConfigLinkEMail'
    );
    this.serverError = this._appContext.get('serverError');
    this.config = this._appContext.get('pages.setUserIdPage');
    this.footerConfig = this._appContext.get('footer');
    this.languageConfig = this._appContext.get('footer');
    this.language = this._appContext.get('language.route');
    this.storeSub = this._store.subscribe((state) => {
      this.registrationDetailsObject = cloneDeep(state['registrationDetails']);
      this.backToRegisterationPage = this.registrationDetailsObject
        ? this.registrationDetailsObject['backToRegisterationPage']
        : this.config['loginPageLink'];
      console.log('state---', state);
    });

    this.resendCount = 0;
    this.submitCount = 0;
    this.sendButtonText = this.config['sendOtpLabels'][0];
    this.routerEventSub = this._router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this._router.navigate([this.config['loginPageLink']]);
        }
      });
    this.radioValues = [
      {
        name: this.config['addNewUser'],
        value: 'newUserId',
        verified: undefined,
      },
    ];
  }

  ngOnInit() {
    if (this.registrationDetailsObject['findCustomerResponse']['Email']) {
      this.formatRadioValues(
        this.registrationDetailsObject['findCustomerResponse']['Email'],
        true
      );
      this.radioControl.setValue(
        this.registrationDetailsObject['findCustomerResponse']['Email']
      );
      this.selectedUserId = this.registrationDetailsObject[
        'findCustomerResponse'
      ]['Email'];
    } else {
      this.radioControl.setValue('newUserId');
      this.getSelectedRadioValue('newUserId');
      this.showUserIdField = true;
    }
    this.retrieveFormBuilderConfig();
    this.twilioTokenApi();
    // this._loadingService.closeLoadingIndicator();
  }

  formatRadioValues(value, verified) {
    if (value) {
      const id = {
        name: value,
        value: value,
        verified: verified,
      };
      this.radioValues.unshift(id);
    }
    console.log(this.radioValues);
    // if (this.radioValues.length >= 1) {
    //   this.showUserIdField = true;
    // }
  }

  retrieveFormBuilderConfig() {
    // this.formBuilderConfig = cloneDeep(
    //     id__selectUserIdForm(this.radioValues)
    //   );
    this.formBuilderConfig1 = cloneDeep(
      id__adduserIdForm(this.config['formConfig'])
    );
    this.formBuilderConfig2 = cloneDeep(id__otpForm(this.config['formConfig']));
    this.formGroup.updateValueAndValidity();
  }

  valueChange(formGroup: FormGroup) {}

  onClickAdd() {
    this.showOtpField = false;
    this.showUserIdField = true;
    this.formGroup.controls['email']?.reset();
  }

  addUserId() {
    if (this.formGroup.get('email').valid) {
      const val = this.formGroup.get('email').value;
      // const id = {
      //     name: val,
      //     value: val,
      //     verified: false
      // }
      // this.radioValues.push(id);
      // this.formatRadioValues(val, false);
      // this.showUserIdField = false;
      // this.radioControl.setValue(val);
      this.selectedUserId = val;
      this.disableContinueButton = false;
      // this.onContinue('addUserId');
      if (this.isUserIdVerified(this.selectedUserId)) {
        this.userProfileTokenApi(this.selectedUserId, true);
      } else {
        this.userProfileTokenApi(this.selectedUserId, false);
      }
    } else {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].markAsTouched();
        this.formGroup.controls[controlName].markAsDirty();
      });
    }
  }

  onContinue() {
    //after selecting user id
    // this.showUserIdField = false;
    if (this.showUserIdField) {
      this.addUserId();
    } else {
      if (this.isUserIdVerified(this.selectedUserId)) {
        this.userProfileTokenApi(this.selectedUserId, true);
      } else {
        this.userProfileTokenApi(this.selectedUserId, false);
      }
    }
  }

  submitOtp() {
    if (this.formGroup.controls['otp'].valid) {
      this.callVerificiationCheck(this.formGroup.controls['otp'].value);
    } else {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].markAsTouched();
        this.formGroup.controls[controlName].markAsDirty();
      });
    }
  }

  getSelectedRadioValue(value) {
    this.showUserIdError = false;
    this.showUserIdField = false;
    this.showOtpField = false;
    this.selectedUserId = value;
    if (value === 'newUserId') {
      this.onClickAdd();
    } else {
      this.showUserIdField = false;
    }
  }

  isUserIdVerified(id) {
    const userId = this.radioValues.find((element) => element['value'] === id);
    if (userId?.['verified']) {
      return true;
    } else {
      return false;
    }
  }
  callVerificationRequest() {
    const payload = {
      emailAddress: this.selectedUserId,
      channel: 'email',
    };
    if (this._router.url.includes('en-US')) {
      payload['language'] = 'en';
    } else if (this._router.url.includes('es-US')) {
      payload['language'] = 'es';
    } else if (this._router.url.includes('en-CA')) {
      payload['language'] = 'en-GB';
    } else {
      payload['language'] = 'fr';
    }
    if (this.resendCount < environment.otpValidationAttempts) {
      this._service
        .ssVerificationRequest(payload, this.authTokenValue)
        .pipe(timeout(120000))
        .subscribe(
          (response) => {
            if (response.status !== 200) {
              this.showUserIdError = false;
              this._loadingService.closeLoadingIndicator();
            } else {
              this.resendCount++;
              this.showUserIdError = false;
              this.showResendText = false;
              this.showOtpField = true;
              this.formGroup.controls['otp']?.reset();
              this.sendButtonText = this.config['sendOtpLabels'][1];
              this._loadingService.closeLoadingIndicator();
              let obj: any = {};
              let formValObj: any = {};
              formValObj.otpMethod = 'otpMethod';
              obj.formValue = { otpMethod: 'otpMethod' };
              obj.value = this.selectedUserId;
              obj.type = 'email';
              const popUpData = {
                data: {
                  selectedOption: obj,
                  page: 'setUserId',
                  registrationDetailsObject: this.registrationDetailsObject,
                },
              };
              this._dialog.open(OtpPopupComponent, popUpData);
            }
          },
          (error) => {
            if (
              error?.['error']?.['message'] ===
              'Unknown error. Provider returned : TooManyRequests'
            ) {
              const popUpData = this.config['maxAttemptPopUp'];
              const popUp = this._dialog.open(
                GeneralizedPopUpComponent,
                popUpData
              );
              popUp.afterClosed().subscribe((event) => {
                this._loadingService.closeLoadingIndicator();
                this._router.navigate([this.config['policyRoute']]);
              });
            } else {
              this._loadingService.closeLoadingIndicator();
              const popUpData = cloneDeep(this.serverError);
              const errorResponse = formatApiError(error);
              popUpData['data']['errorResponse'] = errorResponse;
              this._dialog.open(GeneralizedPopUpComponent, popUpData);
            }
          }
        );
    } else {
      const popUpData = this.config['maxAttemptPopUp'];
      const popUp = this._dialog.open(GeneralizedPopUpComponent, popUpData);
      popUp.afterClosed().subscribe((event) => {
        this._loadingService.closeLoadingIndicator();
        this._router.navigate([this.config['policyRoute']]);
      });
    }
  }

  // twilio
  twilioTokenApi() {
    this._loadingService.openLoadingIndicator({});
    this._service
      .twilioTokenApi()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.authTokenValue =
              response.token_type + ' ' + response.access_token;
            this.sessionToken = response.access_token;
            this._loadingService.closeLoadingIndicator();
            return this.authTokenValue;
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const popUpData = cloneDeep(this.serverError);
          const errorResponse = formatApiError(error);
          popUpData['data']['errorResponse'] = errorResponse;
          this._dialog.open(GeneralizedPopUpComponent, popUpData);
        }
      );
  }

  // registration
  registrationTokenAPI() {
    this._service
      .ssRegistrationTokenAPI()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            let authTokenVal =
              response.token_type + ' ' + response.access_token;
            this.registrationTokenBuilderAPI(authTokenVal);
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const popUpData = cloneDeep(this.serverError);
          const errorResponse = formatApiError(error);
          popUpData['data']['errorResponse'] = errorResponse;
          this._dialog.open(GeneralizedPopUpComponent, popUpData);
        }
      );
  }
  registrationTokenBuilderAPI(token) {
    const userDetails = this.registrationDetailsObject['userDetails'];
    const findCustomerResponse = this.registrationDetailsObject[
      'findCustomerResponse'
    ];
    let isTrueGroup = '';

    if (
      findCustomerResponse !== undefined &&
      findCustomerResponse['IsTrueGroupMember'] !== undefined
    ) {
      if (findCustomerResponse['IsTrueGroupMember'] === true) {
        isTrueGroup = 'trueGroup';
      } else {
        isTrueGroup = '';
      }
    }
    let regTokeBuilderPayload = {
      ClientId: environment.registrationTokenBuilderConfig.clientId,
      B2CTenant: environment.registrationTokenBuilderConfig.tentantId,
      B2CPolicy: environment.registrationTokenBuilderConfig.policy,
      ReplyUrl: environment.registrationTokenBuilderConfig.replyUrl,
      Claim1Name: 'email',
      Claim1Value: this.selectedUserId,
      Claim2Name: 'firstName',
      Claim2Value: userDetails['firstName'],
      Claim3Name: 'lastName',
      Claim3Value: userDetails['lastName'],
      Claim4Name: 'clientID',
      Claim4Value: environment.registrationTokenBuilderConfig.clientId,
      Claim5Name: 'extension_userType',
      Claim5Value: isTrueGroup
    };

    console.log('regTokeBuilderPayload', regTokeBuilderPayload);
    sessionStorage.setItem('callSaveRegister', 'true');
    sessionStorage.setItem('RegisterId', findCustomerResponse['RegisterId']);
    if (findCustomerResponse['IsRegistered'] === true) {
      sessionStorage.setItem('IsReregister', 'true');
      sessionStorage.setItem(
        'RegisteredLoginId',
        this.selectedUserId
      );
    } else {
      sessionStorage.setItem('IsReregister', 'false');
    }
    this._service
      .ssRegistrationTokenBuilderAPI(token, regTokeBuilderPayload)
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.TokenData !== undefined &&
            response.TokenData !== ''
          ) {
            this._loadingService.closeLoadingIndicator();
            this.authProvider = this._authProviderService.getRegistrationProvider();
            const path = this._router.url.split('/')[1];
            const extraQueryParameters = {
              id_token_hint: response.TokenData,
              ui_locales: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
              language: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
              prompt: 'login',
            };
            localStorage.setItem('language', path);
            this.authProvider.login(extraQueryParameters);
          } else {
            this._loadingService.closeLoadingIndicator();
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const popUpData = cloneDeep(this.serverError);
          const errorResponse = formatApiError(error);
          popUpData['data']['errorResponse'] = errorResponse;
          this._dialog.open(GeneralizedPopUpComponent, popUpData);
        }
      );
  }

  callVerificiationCheck(otp) {
    if (otp) {
      let payload = {};
      payload['code'] = otp;
      payload['channel'] = 'email';
      if (this.selectedUserId) {
        payload['emailAddress'] = this.selectedUserId;
        this._loadingService.openLoadingIndicator({});
        this._service
          .ssVerificationCheck(payload, this.authTokenValue)
          .pipe(timeout(120000))
          .subscribe(
            (response) => {
              this._loadingService.closeLoadingIndicator();
              this.registrationTokenAPI();
            },
            (error) => {
              if (
                error?.['error']?.['message'].toLowerCase() === 'incorrect code'
              ) {
                this.submitCount++;
                this.formGroup.controls['otp'].setErrors({
                  invalid: true,
                });
                if (this.submitCount === environment.otpValidationAttempts) {
                  const popUpData = this.config['maxAttemptPopUp'];
                  const popUp = this._dialog.open(
                    GeneralizedPopUpComponent,
                    popUpData
                  );
                  popUp.afterClosed().subscribe((event) => {
                    this._loadingService.closeLoadingIndicator();
                    this._router.navigate([this.config['policyRoute']]);
                  });
                } else {
                  this._loadingService.closeLoadingIndicator();
                }
              } else if (
                error?.['error']?.['message'].toLowerCase() ===
                'no pending verification requests'
              ) {
                this.showResendText = true;
                this._loadingService.closeLoadingIndicator();
              } else if (
                error?.['error']?.['message'] ===
                'Unknown error. Provider returned : TooManyRequests'
              ) {
                const popUpData = this.config['maxAttemptPopUp'];
                const popUp = this._dialog.open(
                  GeneralizedPopUpComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  this._loadingService.closeLoadingIndicator();
                  this._router.navigate([this.config['policyRoute']]);
                });
              } else {
                this._loadingService.closeLoadingIndicator();
              }
            }
          );
      }
    }
  }

  backToLoginPage() {
    let url = this.config['loginPageLink'];
    const accessType = this.navigationState
      ? this.navigationState.accessType
      : '';
    if (accessType === 'policy') {
      url = this.config['registrationByPolicyPageLink'];
    } else if (this.backToRegisterationPage === 'registrationMethod') {
      url = this.config['backToRegisterationPage1'];
    } else if (this.backToRegisterationPage === 'registrationMethodTwo') {
      url = this.config['backToRegisterationPage2'];
    }
    this._router.navigate([url]);
  }

  userProfileTokenApi(userId, verified) {
    this._loadingService.openLoadingIndicator({});
    if (
      this.userProfileResponse === undefined ||
      (this.userProfileResponse !== undefined &&
        this.userProfileResponse['value']?.['email'] !== userId)
    ) {
      this._service
        .userProfileTokenAPI()
        .pipe(take(1))
        .pipe(timeout(120000))
        .subscribe(
          (response: any) => {
            if (
              response !== undefined &&
              response.access_token !== undefined &&
              response.access_token !== ''
            ) {
              const token = response.token_type + ' ' + response.access_token;
              this.callUserProfuleApi(userId, verified, token);
            }
          },
          (error) => {
            this._loadingService.closeLoadingIndicator();
            const popUpData = cloneDeep(this.serverError);
            const errorResponse = formatApiError(error);
            popUpData['data']['errorResponse'] = errorResponse;
            this._dialog.open(GeneralizedPopUpComponent, popUpData);
          }
        );
    } else if (
      this.userProfileResponse !== undefined &&
      this.userProfileResponse['value']?.['email'] === userId
    ) {
      if (this.userProfileResponse['status']['Code'] === 0) {
        // show user id error
        this._loadingService.closeLoadingIndicator();
        this.showUserIdError = true;
      } else {
        //continue registration
        if (
          this.registrationDetailsObject['findCustomerResponse']['IsRegistered']
        ) {
          if (this.overrideUserIdFlag !== userId) {
            const popUpData = this.config['reRegisterPopUp'];
            const popUp = this._dialog.open(
              GeneralizedPopUpComponent,
              popUpData
            );
            popUp.afterClosed().subscribe((event) => {
              if (event === 'button') {
                this.overrideUserIdFlag = userId;
                if (verified) {
                  this.registrationTokenAPI();
                } else {
                  this.callVerificationRequest();
                }
              } else {
                // Do nothing
                this._loadingService.closeLoadingIndicator();
              }
            });
          } else if (this.overrideUserIdFlag) {
            if (verified) {
              this.registrationTokenAPI();
            } else {
              this.callVerificationRequest();
            }
          }
        } else {
          if (verified) {
            this.registrationTokenAPI();
          } else {
            this.callVerificationRequest();
          }
        }
      }
    }
  }

  callUserProfuleApi(userId, verified, token) {
    const payload = {
      email: userId,
    };
    this._service.ssUserProfileCheck(payload, token).subscribe(
      (response) => {
        if (response) {
          this.userProfileResponse = response;
          if (response['status']['Code'] === 0) {
            // show user id error
            this.showUserIdError = true;
            this._loadingService.closeLoadingIndicator();
          } else {
            //continue registration
            if (
              this.registrationDetailsObject['findCustomerResponse'][
                'IsRegistered'
              ]
            ) {
              if (this.overrideUserIdFlag !== userId) {
                const popUpData = this.config['reRegisterPopUp'];
                if (
                  this.registrationDetailsObject['findCustomerResponse'][
                    'RegisteredLoginId'
                  ] !== ''
                ) {
                  // popUpData.data.errorMessage = popUpData.data.errorMessage +
                  // this.registrationDetailsObject["findCustomerResponse"]["RegisteredLoginId"] +
                  // popUpData.betweenText +
                  // userId;
                } else {
                  popUpData.data.errorMessage =
                    popUpData.data.errorMessage +
                    popUpData.betweenText +
                    userId;
                }
                const popUp = this._dialog.open(
                  GeneralizedPopUpComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  if (event === 'button') {
                    this.overrideUserIdFlag = userId;
                    if (verified) {
                      this.registrationTokenAPI();
                    } else {
                      this.callVerificationRequest();
                    }
                  } else {
                    // Do nothing
                    this._loadingService.closeLoadingIndicator();
                  }
                });
              } else if (this.overrideUserIdFlag) {
                if (verified) {
                  this.registrationTokenAPI();
                } else {
                  this.callVerificationRequest();
                }
              }
            } else {
              if (verified) {
                this.registrationTokenAPI();
              } else {
                this.callVerificationRequest();
              }
            }
          }
        }
      },
      (error) => {
        this._loadingService.closeLoadingIndicator();
        const popUpData = cloneDeep(this.serverError);
        const errorResponse = formatApiError(error);
        popUpData['data']['errorResponse'] = errorResponse;
        this._dialog.open(GeneralizedPopUpComponent, popUpData);
      }
    );
  }

  ngOnDestroy() {
    this.routerEventSub.unsubscribe();
    this.storeSub.unsubscribe();
  }
}
