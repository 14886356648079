import moment from 'moment-timezone';
import {
  IFrameFetchSuccessAction,
  LanguageSelectionSuccessAction,
  ResetStore,
  saveDashboardDeatils,
  savePolicyDetails,
} from '../../../app/state/actions';

export function getRouteName(router) {
  const routeName = [];
  const currentURL = router.url;
  const pathName = currentURL.split('?');
  if (pathName !== undefined && pathName !== '') {
    const subPath = pathName[0].split('/');
    if (subPath !== undefined && subPath !== '') {
      for (let i = 0; i <= subPath.length; i++) {
        if (subPath[i] !== undefined && subPath[i] !== '') {
          routeName.push({ path: subPath[i] });
        }
      }
    }
  }
  return routeName;
}

export function getYearRange(start?, end?) {
  const startYear = start ? start : 1899;
  const currentYear = new Date().getFullYear();
  const endYear = end ? end : currentYear;
  const years = [];
  for (let i = endYear - 1; i > startYear; i--) {
    years.push({ key: i + 1 });
  }
  return years;
}

export function monthRange() {
  const monthObj = [
    {
      key: 'January',
      value: '01',
    },
    {
      key: 'February',
      value: '02',
    },
    {
      key: 'March',
      value: '03',
    },
    {
      key: 'April',
      value: '04',
    },
    {
      key: 'May',
      value: '05',
    },
    {
      key: 'June',
      value: '06',
    },
    {
      key: 'July',
      value: '07',
    },
    {
      key: 'August',
      value: '08',
    },
    {
      key: 'September',
      value: '09',
    },
    {
      key: 'October',
      value: '10',
    },
    {
      key: 'November',
      value: '11',
    },
    {
      key: 'December',
      value: '12',
    },
  ];
  return monthObj;
}

export function getDayItems() {
  const days = [];
  for (let i = 1; i <= 28; i++) {
    if(i%10 == 1 && i%100 != 11){

      days.push({ key: i + "st" });
    }
    else if(i%10 == 2 && i%100 != 12){

      days.push({ key: i + "nd" });
    }
    else if(i%10 == 3 && i%100 != 13){

      days.push({ key: i + "rd" });
    }
    else {

      days.push({ key: i + "th" });
    }

  }
  return days;
}

export function getFullMonthName(monthNum) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthNum];
}

export function getFullMonthNameSpanish(monthNum) {
  const monthNames = [
    'en',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'agto',
    'set',
    'oct',
    'nov',
    'dic',
  ];
  return monthNames[monthNum];
}

export function getAgeByDob(date) {
  const starts = moment(date);
  const ends = moment();
  const years = ends.diff(starts, 'year');
  return years;
}

export function getAgeByDobCeil(date) {
  const starts = moment(date);
  const ends = moment();
  let years = ends.diff(starts, 'year');
  const days = ends.diff(starts, 'days');
  if (years === 26 && days && days > 0) {
    years = years + 1;
  }
  return years;
}

export function stringify(obj_from_json) {
  if (typeof obj_from_json !== 'object' || Array.isArray(obj_from_json)) {
    return JSON.stringify(obj_from_json);
  }

  const props = Object.keys(obj_from_json)

    .map((key) => `${key}:${stringify(obj_from_json[key])}`)

    .join(',');

  return `{${props}}`;
}

export function base64ToArrayBuffer(base64) {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function getRandomId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function scrollToPage(element) {
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }, 100);
  }
}

export function getServerError(error, apiName) {
  if (error) {
    return apiName + ' - ' + JSON.stringify(error);
  } else {
    return apiName;
  }
}

export function getApiError(error) {
  if (error) {
    return {
      subHeader: "ERROR: " + error['status'] + " " + error['statusText'],
      message: error['url']
    };
  }
}

export function formatApiError(error) {
  if (error) {
    const message = error['url'] + " " + "Error: " + error['status'] + " " + error['statusText'];
    return message;
  }
}

export function formatApiResponse(response, url?: string) {
  if (response && url) {
    const message = url + " " + "Code: " + response['Code'] + " " + response['Message'];
    return message;
  } else {
    const message = "Code: " + response['Code'] + " " + response['Message'];
    return message;
  }
}

export function resetStore(
  _store,
  dataService,
  iFrameData,
  languageData,
  policyData?: any,
  dashboardStore?: any
) {
  const language_Selected = languageData;
  _store.dispatch(new ResetStore());
  dataService.updateClaimantName('');
  dataService.updateClaimantNameError(true);
  dataService.updateBannerTextBasedOnStep(1);
  dataService.updateSignature('');
  dataService.updateSignatureError(false);
  dataService.updateFraudReviewCheckbox(false);
  dataService.updateFraudReviewCheckboxError(true);
  dataService.updateFraudCheckbox(false);
  dataService.updateFraudCheckboxError(true);
  dataService.updateChubbEmployeeData('');
  _store.dispatch(new IFrameFetchSuccessAction({ iFrameData: iFrameData }));
  _store.dispatch(new LanguageSelectionSuccessAction(language_Selected));
  _store.dispatch(new savePolicyDetails({
    isPolicyDataFormated: policyData?.['isPolicyDataFormated'],
    policyCardsList: policyData?.['policyCardsList'],
    ssFindPolicy: policyData?.['ssFindPolicy']
  }));
  _store.dispatch(
    saveDashboardDeatils({ payload: dashboardStore })
  );
}

/**
 * Method to format date value in English from MM/DD/YYYY to Month Date, Year
 * Example: 01/22/2020 to Jan 22, 2020
 */
export function formatDate(value) {
  const dateArray = value.split('/');
  let monthName = getFullMonthName(parseInt(dateArray[0], 0) - 1);
  monthName = monthName.slice(0, 3);
  const formattedDate = monthName + ' ' + dateArray[1] + ', ' + dateArray[2];
  return formattedDate;
}

/**
 * Method to format date value in Spanishfrom MM/DD/YYYY to Month Date, Year
 * Example: 01/22/2020 to Jan 22, 2020
 */
export function formatDateSpanish(value) {
  const dateArray = value.split('/');
  let monthName = getFullMonthNameSpanish(parseInt(dateArray[0], 0) - 1);
  const formattedDate = monthName + ' ' + dateArray[1] + ', ' + dateArray[2];
  return formattedDate;
}

// Method which return minimum value of an array
export function findMinValue(array) {
  let least;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== undefined && array[i] !== null) {
      if (least === undefined) {
        least = array[i];
      }
      if (array[i] <= least) {
        least = array[i];
      }
    }
  }
  return least;
}

export function formatPhoneNumber(value) {
  let inputStr = ('' + value).replace(/\D/g, '');
  let match = inputStr.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  } else return null;
}

// Method which return maximum value of an array
export function findMaxValue(array) {
  let largest;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== undefined && array[i] !== null) {
      if (largest === undefined) {
        largest = array[i];
      }
      if (array[i] > largest) {
        largest = array[i];
      }
    }
  }
  return largest;
}

//convert Spanish and French special characters to english

export function convertToEnglish(specialText) {
  var Latinise = { 'latin_map': {} };
  Latinise.latin_map = {
    "Á": "A",
    "Ă": "A",
    "Ắ": "A",
    "Ặ": "A",
    "Ằ": "A",
    "Ẳ": "A",
    "Ẵ": "A",
    "Ǎ": "A",
    "Â": "A",
    "Ấ": "A",
    "Ậ": "A",
    "Ầ": "A",
    "Ẩ": "A",
    "Ẫ": "A",
    "Ä": "A",
    "Ǟ": "A",
    "Ȧ": "A",
    "Ǡ": "A",
    "Ạ": "A",
    "Ȁ": "A",
    "À": "A",
    "Ả": "A",
    "Ȃ": "A",
    "Ā": "A",
    "Ą": "A",
    "Å": "A",
    "Ǻ": "A",
    "Ḁ": "A",
    "Ⱥ": "A",
    "Ã": "A",
    "Ꜳ": "AA",
    "Æ": "AE",
    "Ǽ": "AE",
    "Ǣ": "AE",
    "Ꜵ": "AO",
    "Ꜷ": "AU",
    "Ꜹ": "AV",
    "Ꜻ": "AV",
    "Ꜽ": "AY",
    "Ḃ": "B",
    "Ḅ": "B",
    "Ɓ": "B",
    "Ḇ": "B",
    "Ƀ": "B",
    "Ƃ": "B",
    "Ć": "C",
    "Č": "C",
    "Ç": "C",
    "Ḉ": "C",
    "Ĉ": "C",
    "Ċ": "C",
    "Ƈ": "C",
    "Ȼ": "C",
    "Ď": "D",
    "Ḑ": "D",
    "Ḓ": "D",
    "Ḋ": "D",
    "Ḍ": "D",
    "Ɗ": "D",
    "Ḏ": "D",
    "ǲ": "D",
    "ǅ": "D",
    "Đ": "D",
    "Ƌ": "D",
    "Ǳ": "DZ",
    "Ǆ": "DZ",
    "É": "E",
    "Ĕ": "E",
    "Ě": "E",
    "Ȩ": "E",
    "Ḝ": "E",
    "Ê": "E",
    "Ế": "E",
    "Ệ": "E",
    "Ề": "E",
    "Ể": "E",
    "Ễ": "E",
    "Ḙ": "E",
    "Ë": "E",
    "Ė": "E",
    "Ẹ": "E",
    "Ȅ": "E",
    "È": "E",
    "Ẻ": "E",
    "Ȇ": "E",
    "Ē": "E",
    "Ḗ": "E",
    "Ḕ": "E",
    "Ę": "E",
    "Ɇ": "E",
    "Ẽ": "E",
    "Ḛ": "E",
    "Ꝫ": "ET",
    "Ḟ": "F",
    "Ƒ": "F",
    "Ǵ": "G",
    "Ğ": "G",
    "Ǧ": "G",
    "Ģ": "G",
    "Ĝ": "G",
    "Ġ": "G",
    "Ɠ": "G",
    "Ḡ": "G",
    "Ǥ": "G",
    "Ḫ": "H",
    "Ȟ": "H",
    "Ḩ": "H",
    "Ĥ": "H",
    "Ⱨ": "H",
    "Ḧ": "H",
    "Ḣ": "H",
    "Ḥ": "H",
    "Ħ": "H",
    "Í": "I",
    "Ĭ": "I",
    "Ǐ": "I",
    "Î": "I",
    "Ï": "I",
    "Ḯ": "I",
    "İ": "I",
    "Ị": "I",
    "Ȉ": "I",
    "Ì": "I",
    "Ỉ": "I",
    "Ȋ": "I",
    "Ī": "I",
    "Į": "I",
    "Ɨ": "I",
    "Ĩ": "I",
    "Ḭ": "I",
    "Ꝺ": "D",
    "Ꝼ": "F",
    "Ᵹ": "G",
    "Ꞃ": "R",
    "Ꞅ": "S",
    "Ꞇ": "T",
    "Ꝭ": "IS",
    "Ĵ": "J",
    "Ɉ": "J",
    "Ḱ": "K",
    "Ǩ": "K",
    "Ķ": "K",
    "Ⱪ": "K",
    "Ꝃ": "K",
    "Ḳ": "K",
    "Ƙ": "K",
    "Ḵ": "K",
    "Ꝁ": "K",
    "Ꝅ": "K",
    "Ĺ": "L",
    "Ƚ": "L",
    "Ľ": "L",
    "Ļ": "L",
    "Ḽ": "L",
    "Ḷ": "L",
    "Ḹ": "L",
    "Ⱡ": "L",
    "Ꝉ": "L",
    "Ḻ": "L",
    "Ŀ": "L",
    "Ɫ": "L",
    "ǈ": "L",
    "Ł": "L",
    "Ǉ": "LJ",
    "Ḿ": "M",
    "Ṁ": "M",
    "Ṃ": "M",
    "Ɱ": "M",
    "Ń": "N",
    "Ň": "N",
    "Ņ": "N",
    "Ṋ": "N",
    "Ṅ": "N",
    "Ṇ": "N",
    "Ǹ": "N",
    "Ɲ": "N",
    "Ṉ": "N",
    "Ƞ": "N",
    "ǋ": "N",
    "Ñ": "N",
    "Ǌ": "NJ",
    "Ó": "O",
    "Ŏ": "O",
    "Ǒ": "O",
    "Ô": "O",
    "Ố": "O",
    "Ộ": "O",
    "Ồ": "O",
    "Ổ": "O",
    "Ỗ": "O",
    "Ö": "O",
    "Ȫ": "O",
    "Ȯ": "O",
    "Ȱ": "O",
    "Ọ": "O",
    "Ő": "O",
    "Ȍ": "O",
    "Ò": "O",
    "Ỏ": "O",
    "Ơ": "O",
    "Ớ": "O",
    "Ợ": "O",
    "Ờ": "O",
    "Ở": "O",
    "Ỡ": "O",
    "Ȏ": "O",
    "Ꝋ": "O",
    "Ꝍ": "O",
    "Ō": "O",
    "Ṓ": "O",
    "Ṑ": "O",
    "Ɵ": "O",
    "Ǫ": "O",
    "Ǭ": "O",
    "Ø": "O",
    "Ǿ": "O",
    "Õ": "O",
    "Ṍ": "O",
    "Ṏ": "O",
    "Ȭ": "O",
    "Ƣ": "OI",
    "Ꝏ": "OO",
    "Ɛ": "E",
    "Ɔ": "O",
    "Ȣ": "OU",
    "Ṕ": "P",
    "Ṗ": "P",
    "Ꝓ": "P",
    "Ƥ": "P",
    "Ꝕ": "P",
    "Ᵽ": "P",
    "Ꝑ": "P",
    "Ꝙ": "Q",
    "Ꝗ": "Q",
    "Ŕ": "R",
    "Ř": "R",
    "Ŗ": "R",
    "Ṙ": "R",
    "Ṛ": "R",
    "Ṝ": "R",
    "Ȑ": "R",
    "Ȓ": "R",
    "Ṟ": "R",
    "Ɍ": "R",
    "Ɽ": "R",
    "Ꜿ": "C",
    "Ǝ": "E",
    "Ś": "S",
    "Ṥ": "S",
    "Š": "S",
    "Ṧ": "S",
    "Ş": "S",
    "Ŝ": "S",
    "Ș": "S",
    "Ṡ": "S",
    "Ṣ": "S",
    "Ṩ": "S",
    "Ť": "T",
    "Ţ": "T",
    "Ṱ": "T",
    "Ț": "T",
    "Ⱦ": "T",
    "Ṫ": "T",
    "Ṭ": "T",
    "Ƭ": "T",
    "Ṯ": "T",
    "Ʈ": "T",
    "Ŧ": "T",
    "Ɐ": "A",
    "Ꞁ": "L",
    "Ɯ": "M",
    "Ʌ": "V",
    "Ꜩ": "TZ",
    "Ú": "U",
    "Ŭ": "U",
    "Ǔ": "U",
    "Û": "U",
    "Ṷ": "U",
    "Ü": "U",
    "Ǘ": "U",
    "Ǚ": "U",
    "Ǜ": "U",
    "Ǖ": "U",
    "Ṳ": "U",
    "Ụ": "U",
    "Ű": "U",
    "Ȕ": "U",
    "Ù": "U",
    "Ủ": "U",
    "Ư": "U",
    "Ứ": "U",
    "Ự": "U",
    "Ừ": "U",
    "Ử": "U",
    "Ữ": "U",
    "Ȗ": "U",
    "Ū": "U",
    "Ṻ": "U",
    "Ų": "U",
    "Ů": "U",
    "Ũ": "U",
    "Ṹ": "U",
    "Ṵ": "U",
    "Ꝟ": "V",
    "Ṿ": "V",
    "Ʋ": "V",
    "Ṽ": "V",
    "Ꝡ": "VY",
    "Ẃ": "W",
    "Ŵ": "W",
    "Ẅ": "W",
    "Ẇ": "W",
    "Ẉ": "W",
    "Ẁ": "W",
    "Ⱳ": "W",
    "Ẍ": "X",
    "Ẋ": "X",
    "Ý": "Y",
    "Ŷ": "Y",
    "Ÿ": "Y",
    "Ẏ": "Y",
    "Ỵ": "Y",
    "Ỳ": "Y",
    "Ƴ": "Y",
    "Ỷ": "Y",
    "Ỿ": "Y",
    "Ȳ": "Y",
    "Ɏ": "Y",
    "Ỹ": "Y",
    "Ź": "Z",
    "Ž": "Z",
    "Ẑ": "Z",
    "Ⱬ": "Z",
    "Ż": "Z",
    "Ẓ": "Z",
    "Ȥ": "Z",
    "Ẕ": "Z",
    "Ƶ": "Z",
    "Ĳ": "IJ",
    "Œ": "OE",
    "œ": "ce",
    "ᴀ": "A",
    "ᴁ": "AE",
    "ʙ": "B",
    "ᴃ": "B",
    "ᴄ": "C",
    "ᴅ": "D",
    "ᴇ": "E",
    "ꜰ": "F",
    "ɢ": "G",
    "ʛ": "G",
    "ʜ": "H",
    "ɪ": "I",
    "ʁ": "R",
    "ᴊ": "J",
    "ᴋ": "K",
    "ʟ": "L",
    "ᴌ": "L",
    "ᴍ": "M",
    "ɴ": "N",
    "ᴏ": "O",
    "ɶ": "OE",
    "ᴐ": "O",
    "ᴕ": "OU",
    "ᴘ": "P",
    "ʀ": "R",
    "ᴎ": "N",
    "ᴙ": "R",
    "ꜱ": "S",
    "ᴛ": "T",
    "ⱻ": "E",
    "ᴚ": "R",
    "ᴜ": "U",
    "ᴠ": "V",
    "ᴡ": "W",
    "ʏ": "Y",
    "ᴢ": "Z",
    "á": "a",
    "ă": "a",
    "ắ": "a",
    "ặ": "a",
    "ằ": "a",
    "ẳ": "a",
    "ẵ": "a",
    "ǎ": "a",
    "â": "a",
    "ấ": "a",
    "ậ": "a",
    "ầ": "a",
    "ẩ": "a",
    "ẫ": "a",
    "ä": "a",
    "ǟ": "a",
    "ȧ": "a",
    "ǡ": "a",
    "ạ": "a",
    "ȁ": "a",
    "à": "a",
    "ả": "a",
    "ȃ": "a",
    "ā": "a",
    "ą": "a",
    "ᶏ": "a",
    "ẚ": "a",
    "å": "a",
    "ǻ": "a",
    "ḁ": "a",
    "ⱥ": "a",
    "ã": "a",
    "ꜳ": "aa",
    "æ": "ae",
    "ǽ": "ae",
    "ǣ": "ae",
    "ꜵ": "ao",
    "ꜷ": "au",
    "ꜹ": "av",
    "ꜻ": "av",
    "ꜽ": "ay",
    "ḃ": "b",
    "ḅ": "b",
    "ɓ": "b",
    "ḇ": "b",
    "ᵬ": "b",
    "ᶀ": "b",
    "ƀ": "b",
    "ƃ": "b",
    "ɵ": "o",
    "ć": "c",
    "č": "c",
    "ç": "c",
    "ḉ": "c",
    "ĉ": "c",
    "ɕ": "c",
    "ċ": "c",
    "ƈ": "c",
    "ȼ": "c",
    "ď": "d",
    "ḑ": "d",
    "ḓ": "d",
    "ȡ": "d",
    "ḋ": "d",
    "ḍ": "d",
    "ɗ": "d",
    "ᶑ": "d",
    "ḏ": "d",
    "ᵭ": "d",
    "ᶁ": "d",
    "đ": "d",
    "ɖ": "d",
    "ƌ": "d",
    "ı": "i",
    "ȷ": "j",
    "ɟ": "j",
    "ʄ": "j",
    "ǳ": "dz",
    "ǆ": "dz",
    "é": "e",
    "ĕ": "e",
    "ě": "e",
    "ȩ": "e",
    "ḝ": "e",
    "ê": "e",
    "ế": "e",
    "ệ": "e",
    "ề": "e",
    "ể": "e",
    "ễ": "e",
    "ḙ": "e",
    "ë": "e",
    "ė": "e",
    "ẹ": "e",
    "ȅ": "e",
    "è": "e",
    "ẻ": "e",
    "ȇ": "e",
    "ē": "e",
    "ḗ": "e",
    "ḕ": "e",
    "ⱸ": "e",
    "ę": "e",
    "ᶒ": "e",
    "ɇ": "e",
    "ẽ": "e",
    "ḛ": "e",
    "ꝫ": "et",
    "ḟ": "f",
    "ƒ": "f",
    "ᵮ": "f",
    "ᶂ": "f",
    "ǵ": "g",
    "ğ": "g",
    "ǧ": "g",
    "ģ": "g",
    "ĝ": "g",
    "ġ": "g",
    "ɠ": "g",
    "ḡ": "g",
    "ᶃ": "g",
    "ǥ": "g",
    "ḫ": "h",
    "ȟ": "h",
    "ḩ": "h",
    "ĥ": "h",
    "ⱨ": "h",
    "ḧ": "h",
    "ḣ": "h",
    "ḥ": "h",
    "ɦ": "h",
    "ẖ": "h",
    "ħ": "h",
    "ƕ": "hv",
    "í": "i",
    "ĭ": "i",
    "ǐ": "i",
    "î": "i",
    "ï": "i",
    "ḯ": "i",
    "ị": "i",
    "ȉ": "i",
    "ì": "i",
    "ỉ": "i",
    "ȋ": "i",
    "ī": "i",
    "į": "i",
    "ᶖ": "i",
    "ɨ": "i",
    "ĩ": "i",
    "ḭ": "i",
    "ꝺ": "d",
    "ꝼ": "f",
    "ᵹ": "g",
    "ꞃ": "r",
    "ꞅ": "s",
    "ꞇ": "t",
    "ꝭ": "is",
    "ǰ": "j",
    "ĵ": "j",
    "ʝ": "j",
    "ɉ": "j",
    "ḱ": "k",
    "ǩ": "k",
    "ķ": "k",
    "ⱪ": "k",
    "ꝃ": "k",
    "ḳ": "k",
    "ƙ": "k",
    "ḵ": "k",
    "ᶄ": "k",
    "ꝁ": "k",
    "ꝅ": "k",
    "ĺ": "l",
    "ƚ": "l",
    "ɬ": "l",
    "ľ": "l",
    "ļ": "l",
    "ḽ": "l",
    "ȴ": "l",
    "ḷ": "l",
    "ḹ": "l",
    "ⱡ": "l",
    "ꝉ": "l",
    "ḻ": "l",
    "ŀ": "l",
    "ɫ": "l",
    "ᶅ": "l",
    "ɭ": "l",
    "ł": "l",
    "ǉ": "lj",
    "ſ": "s",
    "ẜ": "s",
    "ẛ": "s",
    "ẝ": "s",
    "ḿ": "m",
    "ṁ": "m",
    "ṃ": "m",
    "ɱ": "m",
    "ᵯ": "m",
    "ᶆ": "m",
    "ń": "n",
    "ň": "n",
    "ņ": "n",
    "ṋ": "n",
    "ȵ": "n",
    "ṅ": "n",
    "ṇ": "n",
    "ǹ": "n",
    "ɲ": "n",
    "ṉ": "n",
    "ƞ": "n",
    "ᵰ": "n",
    "ᶇ": "n",
    "ɳ": "n",
    "ñ": "n",
    "ǌ": "nj",
    "ó": "o",
    "ŏ": "o",
    "ǒ": "o",
    "ô": "o",
    "ố": "o",
    "ộ": "o",
    "ồ": "o",
    "ổ": "o",
    "ỗ": "o",
    "ö": "o",
    "ȫ": "o",
    "ȯ": "o",
    "ȱ": "o",
    "ọ": "o",
    "ő": "o",
    "ȍ": "o",
    "ò": "o",
    "ỏ": "o",
    "ơ": "o",
    "ớ": "o",
    "ợ": "o",
    "ờ": "o",
    "ở": "o",
    "ỡ": "o",
    "ȏ": "o",
    "ꝋ": "o",
    "ꝍ": "o",
    "ⱺ": "o",
    "ō": "o",
    "ṓ": "o",
    "ṑ": "o",
    "ǫ": "o",
    "ǭ": "o",
    "ø": "o",
    "ǿ": "o",
    "õ": "o",
    "ṍ": "o",
    "ṏ": "o",
    "ȭ": "o",
    "ƣ": "oi",
    "ꝏ": "oo",
    "ɛ": "e",
    "ᶓ": "e",
    "ɔ": "o",
    "ᶗ": "o",
    "ȣ": "ou",
    "ṕ": "p",
    "ṗ": "p",
    "ꝓ": "p",
    "ƥ": "p",
    "ᵱ": "p",
    "ᶈ": "p",
    "ꝕ": "p",
    "ᵽ": "p",
    "ꝑ": "p",
    "ꝙ": "q",
    "ʠ": "q",
    "ɋ": "q",
    "ꝗ": "q",
    "ŕ": "r",
    "ř": "r",
    "ŗ": "r",
    "ṙ": "r",
    "ṛ": "r",
    "ṝ": "r",
    "ȑ": "r",
    "ɾ": "r",
    "ᵳ": "r",
    "ȓ": "r",
    "ṟ": "r",
    "ɼ": "r",
    "ᵲ": "r",
    "ᶉ": "r",
    "ɍ": "r",
    "ɽ": "r",
    "ↄ": "c",
    "ꜿ": "c",
    "ɘ": "e",
    "ɿ": "r",
    "ś": "s",
    "ṥ": "s",
    "š": "s",
    "ṧ": "s",
    "ş": "s",
    "ŝ": "s",
    "ș": "s",
    "ṡ": "s",
    "ṣ": "s",
    "ṩ": "s",
    "ʂ": "s",
    "ᵴ": "s",
    "ᶊ": "s",
    "ȿ": "s",
    "ɡ": "g",
    "ᴑ": "o",
    "ᴓ": "o",
    "ᴝ": "u",
    "ť": "t",
    "ţ": "t",
    "ṱ": "t",
    "ț": "t",
    "ȶ": "t",
    "ẗ": "t",
    "ⱦ": "t",
    "ṫ": "t",
    "ṭ": "t",
    "ƭ": "t",
    "ṯ": "t",
    "ᵵ": "t",
    "ƫ": "t",
    "ʈ": "t",
    "ŧ": "t",
    "ᵺ": "th",
    "ɐ": "a",
    "ᴂ": "ae",
    "ǝ": "e",
    "ᵷ": "g",
    "ɥ": "h",
    "ʮ": "h",
    "ʯ": "h",
    "ᴉ": "i",
    "ʞ": "k",
    "ꞁ": "l",
    "ɯ": "m",
    "ɰ": "m",
    "ᴔ": "oe",
    "ɹ": "r",
    "ɻ": "r",
    "ɺ": "r",
    "ⱹ": "r",
    "ʇ": "t",
    "ʌ": "v",
    "ʍ": "w",
    "ʎ": "y",
    "ꜩ": "tz",
    "ú": "u",
    "ŭ": "u",
    "ǔ": "u",
    "û": "u",
    "ṷ": "u",
    "ü": "u",
    "ǘ": "u",
    "ǚ": "u",
    "ǜ": "u",
    "ǖ": "u",
    "ṳ": "u",
    "ụ": "u",
    "ű": "u",
    "ȕ": "u",
    "ù": "u",
    "ủ": "u",
    "ư": "u",
    "ứ": "u",
    "ự": "u",
    "ừ": "u",
    "ử": "u",
    "ữ": "u",
    "ȗ": "u",
    "ū": "u",
    "ṻ": "u",
    "ų": "u",
    "ᶙ": "u",
    "ů": "u",
    "ũ": "u",
    "ṹ": "u",
    "ṵ": "u",
    "ᵫ": "ue",
    "ꝸ": "um",
    "ⱴ": "v",
    "ꝟ": "v",
    "ṿ": "v",
    "ʋ": "v",
    "ᶌ": "v",
    "ⱱ": "v",
    "ṽ": "v",
    "ꝡ": "vy",
    "ẃ": "w",
    "ŵ": "w",
    "ẅ": "w",
    "ẇ": "w",
    "ẉ": "w",
    "ẁ": "w",
    "ⱳ": "w",
    "ẘ": "w",
    "ẍ": "x",
    "ẋ": "x",
    "ᶍ": "x",
    "ý": "y",
    "ŷ": "y",
    "ÿ": "y",
    "ẏ": "y",
    "ỵ": "y",
    "ỳ": "y",
    "ƴ": "y",
    "ỷ": "y",
    "ỿ": "y",
    "ȳ": "y",
    "ẙ": "y",
    "ɏ": "y",
    "ỹ": "y",
    "ź": "z",
    "ž": "z",
    "ẑ": "z",
    "ʑ": "z",
    "ⱬ": "z",
    "ż": "z",
    "ẓ": "z",
    "ȥ": "z",
    "ẕ": "z",
    "ᵶ": "z",
    "ᶎ": "z",
    "ʐ": "z",
    "ƶ": "z",
    "ɀ": "z",
    "ﬀ": "ff",
    "ﬃ": "ffi",
    "ﬄ": "ffl",
    "ﬁ": "fi",
    "ﬂ": "fl",
    "ĳ": "ij",
    "ﬆ": "st",
    "ₐ": "a",
    "ₑ": "e",
    "ᵢ": "i",
    "ⱼ": "j",
    "ₒ": "o",
    "ᵣ": "r",
    "ᵤ": "u",
    "ᵥ": "v",
    "ₓ": "x",
    "Ð": "Eth",
    "ð": "eth",
    "ß": "s"
  };
  return specialText.replace(/[^A-Za-z0-9\[\] ]/g, function (a) { return Latinise.latin_map[a] || a })
}
/**
 * Function to get claimant details for a new claim
 * @param {Object} claimntList - List of claimants
 * @returns {Object} - Claimant details
 */
export function getClaimantDetailsForNewClaim(claimntList) {
  return {
    DOB: moment(claimntList.birthDate).format('MM-DD-YYYY'), // Date of Birth
    FirstName: claimntList.firstName, // First Name
    LastName: claimntList.lastName, // Last Name
    PayeeCity: claimntList.address.city, // City
    PayeeName: claimntList.fullName, // Full Name
    PayeeState: claimntList.address.state, // State
    PayeeStreet1: claimntList.address.addresslineOne, // Street Address 1
    PayeeStreet2: claimntList.address.addresslineTwo, // Street Address 2
    /*PayeeStreet: `${claimntList.address.addresslineOne || ''} ${
      claimntList.address.addresslineTwo || ''
    }`.trim(), // Street Address*/
    PayeeZip4: claimntList.address.zip, // Zip Code
    Sex: claimntList.sexValue || '1', // Sex
    PolicyNo: claimntList.PolicyNumber, // Policy Number
  };
}

/**
 * Function to get claimant details based on employee flag
 * @param {Object} claimntList - List of claimants
 * @param {Object} memberClaimDetails - Details of the member claim
 * @param {boolean} isChubbEmployee - Flag to check if the claimant is a Chubb employee
 * @returns {Object} - Claimant details
 */
export function generatePayload(
  claimntList,
  memberClaimDetails,
  isChubbEmployee
) {
  const claimantDetails = getClaimantDetailsForNewClaim(claimntList);

  if (isChubbEmployee) {
    return { claimantDetails };
  } else {
    const dependentDetails = {
      DOB: memberClaimDetails.claimantInfoForm.birthDate, // Date of Birth
      FirstName: memberClaimDetails.claimantInfoForm.firstName, // First Name
      LastName: memberClaimDetails.claimantInfoForm.lastName, // Last Name
      PayeeCity: memberClaimDetails.claimantMailingAddressForm.city, // City
      PayeeName: `${memberClaimDetails.claimantInfoForm.firstName} ${memberClaimDetails.claimantInfoForm.lastName}`, // Full Name
      PayeeState: memberClaimDetails.claimantMailingAddressForm.state, // State
      PayeeStreet1: memberClaimDetails.claimantMailingAddressForm.addressLine1, // Street Address 1
      PayeeStreet2: memberClaimDetails.claimantMailingAddressForm.addressLine2, // Street Address 2
      /*PayeeStreet: `${
        memberClaimDetails.claimantMailingAddressForm.addressLine1 || ''
      } ${
        memberClaimDetails.claimantMailingAddressForm.addressLine2 || ''
      }`.trim(), // Street Address*/
      PayeeZip4: memberClaimDetails.claimantMailingAddressForm.zipCode, // Zip Code
      Sex:
        memberClaimDetails.claimantInfoForm.genderType === 'Male' ? '1' : '2', // Sex
      PolicyNo: memberClaimDetails.policyNumberForm.policyNumber, // Policy Number
    };

    return { claimantDetails, dependentDetails };
  }
}

/**
 * Helper function to create a payload item
 * @param {string} key - The key for the payload item
 * @param {string} value - The value for the payload item
 * @param {string} subHeader - The sub-header for the payload item
 * @returns {Object} - A payload item
 */
function createPayloadItem(key, value, subHeader) {
  return {
    Key: key,
    LineId: '',
    SubHeader: subHeader,
    Value: value,
  };
}

/**
 * Function to generate payload based on claimant details
 * @param {Object} claimntList - List of claimants
 * @param {Object} memberClaimDetails - Details of the member claim
 * @param {boolean} isChubbEmployee - Flag to check if the claimant is a Chubb employee
 * @returns {Array} - Payload array
 */
export function getClaimantDetailsBasedOnEmployeeFlag(
  claimntList,
  memberClaimDetails,
  isChubbEmployee
) {
  const details = generatePayload(
    claimntList,
    memberClaimDetails,
    isChubbEmployee
  );

  const payload = [];

  const addPayloadItems = (detailObject, subHeader) => {
    payload.push(
      createPayloadItem('First Name', detailObject.FirstName, subHeader),
      createPayloadItem('Last Name', detailObject.LastName, subHeader),
      createPayloadItem(
        'Address Line 1',
        detailObject.PayeeStreet1, //.split('  ')[0],
        subHeader
      ),
      createPayloadItem(
        'Address Line 2',
        detailObject.PayeeStreet2, //.split('  ').slice(1).join(' '),
        subHeader
      ),
      createPayloadItem('City', detailObject.PayeeCity, subHeader),
      createPayloadItem('State', detailObject.PayeeState, subHeader),
      createPayloadItem('Zip', detailObject.PayeeZip4, subHeader)
    );
  };

  addPayloadItems(details.claimantDetails, 'Policy Holder Information');
  payload.push(
    createPayloadItem(
      'Is this claim for you (Employee)?',
      isChubbEmployee ? 'Yes' : 'No',
      'Policy Holder Information'
    )
  );

  if (!isChubbEmployee) {
    addPayloadItems(details.dependentDetails, 'Claimant Information');
  }

  return payload;
}
/**
 * Returns the employee address object based on the claimant list.
 * @param {Object} claimntList - The claimant list object containing address details.
 * @returns {Object} - The address object containing fullName, addressLine1, addressLine2, city, state, and zipCode.
 */
export function getEmployeeAddress(claimntList) {
  // Return the address details from the claimant list.
  return {
    fullName: claimntList.fullName,
    addressLine1: claimntList.address.addresslineOne || '',
    addressLine2: claimntList.address.addresslineTwo || '',
    city: claimntList.address.city,
    state: claimntList.address.state,
    zipCode: claimntList.address.zip,
  };
}

const EMPLOYEE_MAILING_ADDRESS_SUBHEADER = "Employee's Mailing Address";

/**
 * Generates an array of Tiff data objects containing employee mailing address details.
 *
 * @param {Object} empMailingAddress - The employee mailing address object containing address details.
 * @returns {Array} - An array of Tiff data objects with keys and values for the mailing address.
 */
export function getMailingAddressTiffData(empMailingAddress) {
  if (!empMailingAddress) {
    return [];
  }

  const fields = [
    { key: 'Full Name', value: empMailingAddress.fullName },
    { key: 'Address Line 1', value: empMailingAddress.addressLine1 },
    { key: 'Address Line 2', value: empMailingAddress.addressLine2 },
    { key: 'City', value: empMailingAddress.city },
    { key: 'State', value: empMailingAddress.state },
    { key: 'Zip', value: empMailingAddress.zipCode },
  ];

  return fields.map((field) => ({
    Key: field.key,
    LineId: '',
    SubHeader: EMPLOYEE_MAILING_ADDRESS_SUBHEADER,
    Value: field.value,
  }));
}

/**
 * Retrieves the first name and last name based on whether the claimant is a Chubb employee.
 * @param {Object} claimntList - The claimant list object containing claimant information.
 * @param {Object} memberClaimDetails - The member claim details object containing member information.
 * @param {boolean} isChubbEmployee - Flag indicating if the claimant is a Chubb employee.
 * @returns {Object} An object containing the first name and last name.
 */
export function getFirstNameAndLastName(
  claimntList,
  memberClaimDetails,
  isChubbEmployee
) {
  let firstName = '';
  let lastName = '';

  if (isChubbEmployee) {
    // Get first name and last name from claimntList if the claimant is a Chubb employee
    firstName = claimntList.firstName || '';
    lastName = claimntList.lastName || '';
  } else {
    // Get first name and last name from memberClaimDetails if the claimant is not a Chubb employee
    firstName = memberClaimDetails.claimantInfoForm.firstName || '';
    lastName = memberClaimDetails.claimantInfoForm.lastName || '';
  }

  return { firstName, lastName };
}
