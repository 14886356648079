<div class="migration-container">
    <div class="container">
        <div class="mainContentContainer">
            <div class="nav-header">
                <div class="logoContainer" [ngClass]="language === 'fr-CA' ? 'logoContainerCA' : ''">
                    <img class="brandLogo" src="{{config.brandlogoImgUrl}}" alt="{{config.brandLogoAltText}}">
                    <!-- <div class="toggle">
                        <app-studio-custom-multiple-toggle [config]="config.mobileToggleConfig" [toggleButtons]="config.toggleButtons" (toggleEmission)="handleToggleSelection($event)"></app-studio-custom-multiple-toggle>
                    </div>  -->
                </div>
                <div class="header-bg"></div>
            </div>
            <div class="messageLoginContainer">
                <div class="messageContainer">
                    <app-stepper-new [stepperConfig]="stepperConfig" [productConfig]="productConfig" [stepsActivity]="stepsActivity"></app-stepper-new>
                    <!-- <span class="message">{{config.leftHeader}}</span> -->
                </div>
                <div class="loginContainer">
                    <div class="keyLogo"><img class="keyLogoImg" src="{{config.keyLogoImgUrl}}" alt="Key Logo">
                        <div class="LoginMessageContainer">
                            <div><span class="small-header-title">{{config.smallHeader}}</span><span class="loginMessage">{{config.headerRight}}</span></div>
                            <div class="subtext-container">
                                <span class="subtext" *ngIf="radioValues.length > 0" >{{config.subtext1}}</span> <span  class="subtext" *ngIf="radioValues.length > 1">{{config.subtextnext}}</span>
                                <span class="subtext"  *ngIf="radioValues.length == 0" >{{config.emptyemailandmobiletext1}} <span class="subtext-Link" (click)="ContactUs()">{{config.contactUsText}}</span> {{config.emptyemailandmobiletext2}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="loginFormContainer">
                        <div class="radio-button-container">
                            <app-nested-custom-radio-group [customFormControl]="radioControl" [radioValues]="radioValues"  (valueChanges)="handleValueChange($event)"
                            [formBuilderConfig]="formBuilderConfig1">
                            </app-nested-custom-radio-group>
                        </div>
                        <div class="contactus"  *ngIf="radioValues.length > 0">
                            <span class="subtext">{{config.subTextNoAccess}}</span>
                            <span class="subtext-Link" (click)="ContactUs()">{{config.contactUsText}}</span>
                        </div>
                        <div class="otp-selection" *ngIf="selectedOption?.showForm">
                            <div class="otp-type">
                                <span class="subtext">{{config.receiveOtpText}}</span>
                            </div>
                            <div class="sub-radio-button-container">
                                <!-- <app-nested-custom-radio-group [customFormControl]="radioControl" [radioValues]="radioValues"  (valueChanges)="handleValueChange($event)"
                                    [formBuilderConfig]="formBuilderConfig1">
                                    </app-nested-custom-radio-group> -->
                                    <crux-form-builder [config]="formBuilderConfig1" [formGroup]="formGroup" (valueChange)="handleChange($event)"></crux-form-builder>
                            </div>
                        </div>
                        <div class="inviteForm" *ngIf="inviteForm">
                            <crux-form-builder [config]="formBuilderConfig2" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder>
                            <crux-button [disabled]="disableVerifyButton" (click)="verifyCode()" mode="primary">
                                {{config.verifyText}}
                            </crux-button>
                            <div class="labelGuidance">
                                <span class="labelGuidance">{{config.formConfig.InviteCodeLableGudiance}}</span>
                            </div>
                            <!-- <div class="otp-type">
                                <span class="subtext">How would you like to receive your OTP?</span>
                            </div> -->
                            <div class="sub-radio-button-container">
                                <!-- <app-nested-custom-radio-group [customFormControl]="radioControl" [radioValues]="radioValues"  (valueChanges)="handleValueChange($event)"
                                    [formBuilderConfig]="formBuilderConfig1">
                                    </app-nested-custom-radio-group> -->
                                    
                                    <!-- <crux-form-builder [config]="formBuilderConfig1" [formGroup]="formGroup" (valueChange)="handleChange($event)"></crux-form-builder> -->
                            </div>
                        </div>
                       
                        <!-- <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder> -->
                        <div class="button-container">
                            <!-- <div class="send-button">
                                <crux-button [disabled]="disableSendButton" (click)="sendOTP()" mode="primary">
                                    {{ sendButtonText }}
                                </crux-button>
                                <div class="skip-button" *ngIf="showSkipButton === true">
                                    <crux-button (click)="OnSkip()" mode="primary">
                                        Skip
                                    </crux-button>
                                </div>
                            </div> -->
                            <!-- <div class="otp-container" *ngIf="showOtpField">
                                <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder>
                                <div class="submit-button">
                                    <crux-button (click)="OnSubmit()" mode="primary">
                                        {{config.buttonLabel}}
                                    </crux-button>
                                </div>
                            </div>
                            <div class="resend-text" *ngIf="showResendText === true">
                                {{config.resendText}}
                            </div> -->
                        </div>
                        <!-- <div class="back-to-landing-page-link">
                            <span (click)="backToLoginPage()">
                                {{config.backtoLandingPage}}
                            </span>
                        </div> -->
                    </div>
                    <div class="button-section">
                        <span class="back-link" (click)="backToLoginPage()">{{config.backtoLandingPage}}</span>
                        <crux-button  *ngIf="radioValues.length > 0" [disabled]="disableSendButton" (click)="sendOTP()" mode="primary">
                            {{ sendButtonText }}
                        </crux-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <app-footer [footerConfig]="footerConfig" [languageConfig]="languageConfig"></app-footer>
        </div>
    </div>
</div>