import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { filter, take, timeout } from 'rxjs/operators';
import { AppContextService } from '../../app.context.service';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import { FormService } from '../../services/form.services';
import { StudioFormBuilderConfig, id__otpForm, id__sendCodeForm, id__inputInviteForm } from './user-info-success.constant';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment'  
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralizedPopUpComponent } from '../../widgets/shared/generalized-popup/generalized-popup.component';
import { OtpPopupComponent } from '../../widgets/shared/otp-popup/otp-popup.component';
import { ContactUsPopupComponent } from 'src/app/widgets/shared/contact-us-popup/contact-us-popup.component';
import { I } from '@angular/cdk/keycodes';
import { NumberOfAttemptsPopupComponent } from 'src/app/widgets/shared/no-of-attempts-popup/no-of-attempts-popup.component';
import { InvalidInviteCodePopupComponent } from 'src/app/widgets/shared/invalid-invite-code-popup/invalid-invite-code-popup.component';
@Component({
  selector: 'app-user=info-success',
  templateUrl: './user-info-success.component.html',
  styleUrls: ['./user-info-success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInfoSuccessComponent implements OnInit, OnDestroy{
  config: any;
  formBuilderConfig: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formBuilderConfig1: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formBuilderConfig2: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formGroup = new FormGroup({});
  radioControl = new FormControl();
  radioValues = [];
  selectedOption: any;
  disableSendButton: boolean;
  showOtpField: boolean;
  authTokenValue: any;
  sessionToken: any;
  userDetails: any;
  resendCount: number;
  submitCount: number;
  sendButtonText: string;
  registrationDetailsObject = {};
  showSkipButton: boolean;
  routerEventSub: Subscription;
  storeSub: Subscription;
  showResendText: boolean;
  appConfig: any;
  footerConfig: any;
  dialogRef: any;
  somethingWentWrongError: any;
  serverError: any;
  language: any;
  stepperConfig: any;
  productConfig: any;
  stepsActivity: any;
  navigationState: any;
  backToRegisterationPage : string;
  inviteCode: any;
  disableVerifyButton: boolean = true;
  apitoken: any;
  numberOfAttempts: number = 0;
  inviteForm: boolean;
  languageConfig: any;
  constructor(
    private _router: Router,
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _service: FormService,
    private _store: Store<any>,
    private _dialog: MatDialog,
  ) {
    this.navigationState = _router.getCurrentNavigation().extras.state;
    this.config = this._appContext.get("pages.userInfoSuccess");
    this.stepperConfig = this._appContext.get(
      'pages.registration.stepper.payment-amount'
    );
    // steps, editLinkLabel
    this.productConfig = this._appContext.get(
      'pages.registration.stepper.productInfo'
    );
    // showImg, currentStep
    this.stepsActivity = this._appContext.get(
      'pages.registration.stepper.payment-amount.stepperConfigRegistration'
    );
    this.footerConfig = this._appContext.get("footer");
    this.languageConfig = this._appContext.get("footer");
    this.somethingWentWrongError = this._appContext.get("somethingWentWrong");
    this.serverError = this._appContext.get("serverError");
    this.language = this._appContext.get("language.route");
    this.disableSendButton = true;
    this.showOtpField = false;
    this.resendCount = 0;
    this.submitCount = 0;
    this.sendButtonText = this.config["otpButtonLabels"][0];
    this.storeSub = this._store.subscribe(state =>{
      if(state){
        this.registrationDetailsObject = cloneDeep(state["registrationDetails"]);
        this.backToRegisterationPage = this.registrationDetailsObject ? this.registrationDetailsObject["backToRegisterationPage"] : this.config["loginPageLink"];
      }
    });
    this.showSkipButton = environment.skipOtpValidation;
    this.routerEventSub = this._router.events.pipe(
      filter( (event: NavigationEvent) => {
        return (event instanceof NavigationStart)
      })
    ).subscribe((event: NavigationStart) =>{
      if(event.navigationTrigger === "popstate"){
        this._router.navigate([this.config["loginPageLink"]]);
      }
    });
  }

  ngOnInit() {
    this.formatRadioValues(this.registrationDetailsObject["findCustomerResponse"]);
    console.log(this.registrationDetailsObject["findCustomerResponse"]);
    this.retrieveFormBuilderConfig();
    this.twilioTokenApi();
    this.callTokenAPi();
    // this._loadingService.closeLoadingIndicator();
  }
  callTokenAPi(){
    this._service
    .getTokenAPIInvitationCode()
    .pipe(take(1))
    .pipe(timeout(120000)).subscribe((response : any )=> {
      console.log(response);
      this.apitoken = response.token_type + ' ' + response.access_token;
    })
  }
  verifyCode(){
    console.log(this.inviteCode, this.apitoken);
    this._loadingService.openLoadingIndicator({});;
    let payload: any = {
      "tokengroup": "CB01",
      "data": this.inviteCode,
      "tokentemplate": "CBASCII01"
    }
    this._service.tokenizeApi(payload, this.apitoken).pipe(take(1)).pipe(timeout(120000)).subscribe((response :  any) => {
      console.log(response);
      this._loadingService.closeLoadingIndicator();
      let inviteKeys : any = [] = this.registrationDetailsObject["findCustomerResponse"].InviteKey;
      let filterInviteKeys = inviteKeys.filter(x=> x === response.token);
      if(filterInviteKeys.length > 0){
        //navigate
        this._router.navigate([this.config["nextRoute"]]);
      }else{
        this.numberOfAttempts = this.numberOfAttempts + 1;
        console.log(this.numberOfAttempts);
        if(this.numberOfAttempts >= 3){
          const noofattemptsPopup = this._dialog.open(
            NumberOfAttemptsPopupComponent,
            this.config.noOfAttemptsPopup
          );
        }else{
          const invalidcodePopup = this._dialog.open(
            InvalidInviteCodePopupComponent,
            this.config.invalidInviteCodePopup
          );
        }
      }
    })

  }
  retrieveFormBuilderConfig(){
    if(this.config !== undefined) {
      this.formBuilderConfig = cloneDeep(
        id__otpForm(this.config["formConfig"])
      );
      this.formBuilderConfig1 = cloneDeep(id__sendCodeForm(this.config["formConfig"]));
      this.formBuilderConfig2 = cloneDeep(id__inputInviteForm(this.config["formConfig"]));
    }
  }

  formatRadioValues(contactDetails){
    if(contactDetails){
      const phoneList = contactDetails["PhoneList"];
      const emailList = contactDetails["Email"];
      if(phoneList !== undefined && phoneList !== null && phoneList !== ""){
        phoneList.forEach(element => {
          const value: string = element.Value;
          const pattern = /[^0-9]/g;
          const result = value.match(pattern);
          if(value !== "" && !result){
            const ele = {
              "name": this.getMaskedValue(element),
              "formValue": "",
              "value": element.Value,
              "showForm": true,
              "showInviteForm": false,
              "type": "phone"
            }
            this.radioValues.push(ele);
          } else if(result && result[0] === "+" && result.length === 1){
            const ele = {
              "name": this.getMaskedValue(element),
              "formValue": "",
              "value": element.Value,
              "showForm": true,
              "showInviteForm": false,
              "type": "phone"
            }
            this.radioValues.push(ele);
          } 
        });
      }
      if(emailList !== undefined && emailList !== null && emailList !==""){
        const email = {
          "name": this.getMaskedValue(emailList),
          "formValue": {otpMethod: "Text"},
          "value": emailList,
          "showForm": false,
          "showInviteForm": false,
          "type": "email"
        }
        this.radioValues.push(email);
      }
      if((contactDetails["Email"] == null || contactDetails["Email"] == '') && this.backToRegisterationPage === 'registrationMethodSSN') {
        const ele = {
          "name": this.config.inviteCodeText,
          "formValue": "",
          "value": "Code",
          "showForm": false,
          "showInviteForm": true,
          "type": "phone"
        }
        this.radioValues.push(ele);
      }
    }
    console.log(this.radioValues);
  }

  valueChange(formGroup: FormGroup){
    console.log(formGroup);
    this.inviteCode = formGroup.value.invitecode
    console.log(this.inviteCode);
    if(this.inviteCode == '' || !this.inviteCode || this.inviteCode.length < 8){
     this.disableVerifyButton = true 
    }else{
      this.disableVerifyButton = false
    }
  }
  handleChange(formGroup: FormGroup){
    if(formGroup.value !== undefined){
      this.selectedOption.formValue = formGroup.value
      this.handleValueChange(this.selectedOption);
    }
  }

    getMaskedValue(ele){
    if(typeof ele === "string"){
      const maskedValue = "******" + ele.substring(ele.lastIndexOf("@")-3);
      return maskedValue;
      return ele;
    } else {
      const maskedValue = "*** ***" + ele.Value.slice(-4);
      return maskedValue;
    }
  }

  OnSubmit(){
    if(this.formGroup.get("otp").valid){
      this.callVerificiationCheck(this.formGroup.get("otp").value);
    } else {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].markAsTouched();
        this.formGroup.controls[controlName].markAsDirty();
      });
    }
  }

  sendOTP(){
    if(this.selectedOption.formValue.otpMethod !== null && 
      this.selectedOption.formValue.otpMethod !== undefined) {
        if(this.authTokenValue !== undefined){
          this.callVerificationRequest(this.authTokenValue);
        } else {
          this.callVerificationRequest(this.twilioTokenApi());
        }
      }
  }

  navigate(){
    this._router.navigate([this.config["clickHereLink"]]);
  }

  handleValueChange(item){
    if(item.showInviteForm){
      this.selectedOption = null;
      this.disableSendButton = true;
      this.inviteForm = true;
    }else{
      this.inviteForm = false;
      this.selectedOption = item;
      if(item.formValue.otpMethod === null ||
        item.formValue.otpMethod === undefined){
          this.disableSendButton = true;
        } else {
          this.disableSendButton = false;
        }
    }
  }

  twilioTokenApi() {
    this._loadingService.closeLoadingIndicator();
    this._loadingService.openLoadingIndicator({});;
    this._service
      .twilioTokenApi()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.authTokenValue =
              response.token_type + ' ' + response.access_token;
            this.sessionToken = response.access_token;
            this._loadingService.closeLoadingIndicator();
            return this.authTokenValue;
          }
        },
        (error) => {
          this._dialog.open(GeneralizedPopUpComponent, this.serverError);
        }
      );
  }

  callVerificationRequest(token){
    const otpMethod = this.selectedOption.formValue.otpMethod;
    const ofType = this.selectedOption.type;
    const value = this.selectedOption.value;

    let payload = {};
    if(otpMethod !== null && otpMethod !== undefined){
      if(ofType && ofType === "email"){  
        payload["emailAddress"] = value;
        payload["channel"] = ofType;
      } else {
        payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
        payload["phoneNumber"] = value;
      }
    }
    if(this._router.url.includes("en-US")){
      payload["language"] = "en"
    } else if(this._router.url.includes("es-US")) {
      payload["language"] = "es"
    } else if (this._router.url.includes("en-CA")) {
      payload["language"] = "en-GB"
    } else {
      payload["language"] = "fr"
    }
    if(this.resendCount < environment.otpValidationAttempts){
      this._loadingService.openLoadingIndicator({});
      this._service.ssVerificationRequest(payload, token)
      .pipe(timeout(120000)).subscribe(response =>{
        if(response.status !== 200){
          this.showResendText = false;
          this._loadingService.closeLoadingIndicator();
        } else {
          this.resendCount = this.resendCount +1;
          this.sendButtonText = this.config["otpButtonLabels"][1];
          this._loadingService.closeLoadingIndicator();
          const popUpData = {
            "data": {
              "selectedOption" : this.selectedOption,
                "page" : 'successInfo',
                "registrationDetailsObject" : null
            }
          }
          this._dialog.open(OtpPopupComponent, popUpData);
          this.showOtpField = true;
        }
      }, error =>{
        if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
          const popUpData = this.config['maxAttemptPopUp'];
          const popUp = this._dialog.open(
            GeneralizedPopUpComponent,
            popUpData
          );
          popUp.afterClosed().subscribe((event) => {
            this._loadingService.closeLoadingIndicator();
            this._router.navigate([this.config["clickHereLink"]]);
          });
        }
      });
    } else {
      const popUpData = this.config['maxAttemptPopUp'];
      const popUp = this._dialog.open(
        GeneralizedPopUpComponent,
        popUpData
      );
      popUp.afterClosed().subscribe((event) => {
        this._loadingService.closeLoadingIndicator();
        this._router.navigate([this.config["clickHereLink"]]);
      });
    }
  }

  callVerificiationCheck(otp){
    if(otp && this.selectedOption){
      const otpMethod = this.selectedOption.formValue.otpMethod;
      const ofType = this.selectedOption.type;
      const value = this.selectedOption.value;
      let payload = {};
      payload["code"] = otp;
      if(otpMethod !== null && otpMethod !== undefined){
        if(ofType && ofType === "email"){  
          payload["emailAddress"] = value;
          payload["channel"] = ofType;
        } else {
          payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
          payload["phoneNumber"] = value;
        }
      }
      this._loadingService.openLoadingIndicator({});
      this._service.ssVerificationCheck(payload, this.authTokenValue).pipe(timeout(120000)).subscribe( response =>{
        this._loadingService.closeLoadingIndicator();
        this._router.navigate([this.config.nextRoute]);
        }, error =>{
          // this._router.navigate([this.config["clickHereLink"]]);
          if(error?.['error']?.['message'].toLowerCase() === "incorrect code") {
          this.submitCount++;
          this.formGroup.controls["otp"].setErrors({
            invalid: true
          });
          if(this.submitCount === environment.otpValidationAttempts){
            const popUpData = this.config['maxAttemptPopUp'];
            const popUp = this._dialog.open(
              GeneralizedPopUpComponent,
              popUpData
            );
            popUp.afterClosed().subscribe((event) => {
              this._loadingService.closeLoadingIndicator();
              this._router.navigate([this.config["clickHereLink"]]);
            });
          } else {
            this._loadingService.closeLoadingIndicator();
          }
        } else if(error?.['error']?.['message'].toLowerCase() === 'no pending verification requests'){
          this.showResendText = true;
          this._loadingService.closeLoadingIndicator();
        } else if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
          const popUpData = this.config['maxAttemptPopUp'];
          const popUp = this._dialog.open(
            GeneralizedPopUpComponent,
            popUpData
          );
          popUp.afterClosed().subscribe((event) => {
            this._loadingService.closeLoadingIndicator();
            this._router.navigate([this.config["clickHereLink"]]);
          });
        }
      });
    }
  }

  backToLoginPage(){
    let url = this.config["loginPageLink"];
    const accessType = this.navigationState ? this.navigationState.accessType : '';
    if (accessType === 'phone') {
      url = this.config["registrationByPhonePageLink"];
    } else if (this.backToRegisterationPage === 'registrationMethod') {
      url = this.config["backToRegisterationPage1"];
    } else if (this.backToRegisterationPage === 'registrationMethodTwo') {
      url = this.config["backToRegisterationPage2"];
    } else if (this.backToRegisterationPage === 'registrationMethodSSN') {
      url = this.config["backToRegisterationPage3"];
    }
    this._router.navigate([url]);
  }

  OnSkip(){
    this._router.navigate([this.config.nextRoute]);
  }

  ContactUs(){
    const contactSupportPopup = this._dialog.open(
      ContactUsPopupComponent, {
      data : this.config['contactSupportPopup']
      }
    );
  }
  ngOnDestroy(){
    this.routerEventSub.unsubscribe();
    this.storeSub.unsubscribe();
  }
}
