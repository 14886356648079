import { ElementRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { LoadingComponent } from './loading.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { DynamicOverlay } from '../../loading-widget/dynamic-overlay/dynamic-overlay.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _overlayRefs: {
    [key: string]: OverlayRef;
  } = {};

  constructor(
    private _overlay: Overlay,
    private _dynamicOverlay: DynamicOverlay
  ) {}

  open(title: string, message?: string, key = 'global'): void {
    const overlayRef = this._overlay.create({
      hasBackdrop: false,
      scrollStrategy: this._overlay.scrollStrategies.noop(),
      positionStrategy: this._overlay.position().global(),
    });

    this._open(overlayRef, title, message, 'fullscreen', key);
  }

  openForContainer(
    key: string,
    elementRef: ElementRef,
    title: string,
    message?: string
  ): void {
    this._dynamicOverlay.setContainerElement(elementRef.nativeElement);

    const overlayRef = this._dynamicOverlay.create({
      positionStrategy: this._dynamicOverlay.position().global(),
      hasBackdrop: false,
    });

    this._open(overlayRef, title, message, 'container', key);
  }

  close(key: string): void {
    const overlayRef = <OverlayRef>this._overlayRefs[key];

    if (overlayRef) {
      overlayRef.detach();
      delete this._overlayRefs[key];
    }
  }

  private _open(
    overlayRef: OverlayRef,
    title: string,
    message: string,
    mode: 'fullscreen' | 'container' = 'fullscreen',
    key: string
  ): void {
    if (overlayRef.hasAttached()) {
      overlayRef.detach();
    }

    this._overlayRefs[key] = overlayRef;

    const portal = new ComponentPortal(LoadingComponent);
    const componentRef = overlayRef.attach(portal);
    componentRef.instance.message = message;
    componentRef.instance.title = title;
    componentRef.instance.mode = mode;
  }
}
