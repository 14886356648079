import { PolicyDataActions, PolicyDataActionsTypes } from '../actions';
import { policyDetailsInitialState } from '../initial-state';

export function policyDetailsReducer(
  state = policyDetailsInitialState,
  action: PolicyDataActionsTypes
) {

  switch (action.type) {
    case PolicyDataActions.POLICY_SAVE_DATA:
      return { ...state, ...(<Partial<any>>action.payload) };
    default:
      return state;
  }
}
