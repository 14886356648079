import {
    FormBuilderInputControl,
    FormBuilderConfig,
    FormBuilderRadioControl,
  } from "@crux/components";
  
  export interface StudioFormBuilderConfig extends FormBuilderConfig {
    title?: string;
    expanded?: boolean;
    hasChevron?: boolean;
    id?: string;
    footer?: string;
  }

  function patternValidator(formState, patternStr, fieldName) {
    if (patternStr) {
      const pattern = new RegExp(patternStr);
      if (
        formState[fieldName].value &&
        !pattern.test(formState[fieldName].value)
      ) {
        formState[fieldName].setErrors({ invalid: true });
      }
    }
  }
  
  export function id__selectUserIdForm(
    formConfig
  ): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
          key: "userId",
          name: "userId",
          type: "radio",
          placeholder: '',
          label: "",
          required: true,
          cssClass: 'custom-radio-button-control',
          options: <FormBuilderRadioControl>{
            values: formConfig,
            mode: 'primary',
            layout: 'column',
          },
          expressions: {
            blur: (formState: any) => {
                formState["userId"].markAsTouched();
              },
            change: (formState: any) => {
                formState["userId"].markAsUntouched();
              }
          }
        },
      ]
    };
  }

  export function id__otpForm(
    formConfig
  ): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
          key: "otp",
          name: "otp",
          type: "input",
          label: formConfig["otpLabel"],
          placeholder: formConfig["otpPlaceHolder"],
          required: true,
          layout: "column",
          cssClass: "custom-resident-zip-code-input-control",
          validationMessages: {
            required: formConfig["otpRequiredMessage"],
            invalid: formConfig["otpInvalidMessage"],
            minLength: formConfig["otpMinLengthMessage"],
          },
          options: <FormBuilderInputControl>{
            type: "text",
            appearance: "legacy",
            hideRequiredMarker: true,
            autoComplete: "off",
            minlength: formConfig["otpMinLength"],
            maxlength: formConfig["otpMaxLength"],
            onlyAllow: formConfig["otpOnlyAllow"]
          },
          expressions: {
            blur: (formState: any) => {
              formState["otp"].markAsTouched();
            },
            change: (formState: any) => {
              formState["otp"].markAsUntouched();
            }
          }
        },
      ]
    };
  }

  export function id__adduserIdForm(formConfig): FormBuilderConfig {
    return {
      showSubmit: false,
      controls: [
        {
            key: "email",
            name: "email",
            type: "input",
            layout: "column",
            label: formConfig["emailLabel"],
            placeholder: formConfig["emailPlaceHolder"],
            required: true,
            cssClass: "studio-resident-input-control",
            validationMessages: {
              required: formConfig["emailRequiredMessage"],
              email: formConfig["emailInvalidMessage"],
              invalid: formConfig["emailInvalidMessage"],
            },
            options: <FormBuilderInputControl>{
              type: "email",
              appearance: "legacy",
              hideRequiredMarker: true,
              autoComplete: "off",
              maxlength: formConfig["emailMaxLength"],
            },
            expressions: {
              blur: (formState: any) => {
                formState["email"].markAsTouched();
                patternValidator(
                  formState,
                  formConfig["emailPattern"],
                  "email"
                );
              },
              change: (formState: any) => {
                formState["email"].markAsUntouched();
              },
            },
          },
      ]
    };
  }