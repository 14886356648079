import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-studio-custom-toggle',
  templateUrl: './custom-toggle.component.html',
  styleUrls: ['./custom-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomToggleComponent implements OnInit {
  @Input() btnList: any[];
  @Input() customCss?: String;
  @Input() label: String;
  @Output() toggleEmission = new EventEmitter();
  @Input() isDisabled: Boolean;
  @Input() toggleSelected: any;
  @Input() claimType: String;
  @ViewChild('yesselector') yesSelector: any;
  @ViewChild('noselector') noSelector: any;
  yesClicked: any;
  noClicked: any;
  constructor() {
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.yesSelector.el.nativeElement.querySelector("input").onchange = (event) => {
      if (event.currentTarget.value === "on") {
        this.onChangeFunction('yes');
      }
    };
    this.noSelector.el.nativeElement.querySelector("input").onchange = (event) => {
      if (event.currentTarget.value === "on") {
        this.onChangeFunction('no');
      }
    };
  }
  onChangeFunction(event) {
    if (event === 'yes') {
      this.yesClicked = true;
      this.toggleSelected.yes = true;
      this.toggleSelected.no = false;
    } else if (event === 'no') {
      this.noClicked = true;
      this.toggleSelected.no = true;
      this.toggleSelected.yes = false;
    }
    this.toggleEmission.emit(this.toggleSelected);
  }
}
