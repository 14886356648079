import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  labels: any;
  tempLabel: any;
}

@Component({
  selector: 'app-studio-loading-widget',
  templateUrl: './loading-widget.component.html',
  styleUrls: ['./loading-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingWidgetComponent implements OnInit {
  labels: any;
  constructor(
    public dialogRef: MatDialogRef<LoadingWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.labels = this.data.labels;
  }
}
