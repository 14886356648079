import { createReducer, on } from '@ngrx/store';
import { saveSelectedPolicyDetails } from '../actions';
import { SelectedPolicyDeatilsInitialState } from '../initial-state';

const _selectedPolicyReducer = createReducer(
    SelectedPolicyDeatilsInitialState,
  on(saveSelectedPolicyDetails, (state, { payload }) => ({ ...state, ...payload })),
//   on(saveSelectedPolicyDetails, (state) => ({}))
);

export function selectedPolicyDetailsReducer(
  state = SelectedPolicyDeatilsInitialState,
  action
) {
  return _selectedPolicyReducer(state, action);
}