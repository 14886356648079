import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../widgets/shared/message-popup/message-popup.component';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MessagePopupService {
  dialogRef: any;
  private refreshToken = new Subject<Boolean>();
  public refreshToken$ = this.refreshToken.asObservable();

  constructor(public dialog: MatDialog) { }

  openPopup(data) {
    this.dialogRef = this.dialog.open(MessagePopupComponent, {
      ...data,
      disableClose: true,
    });
  }

  closePopup() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  callRefreshToken(newAzureAcessTOken) {
    this.refreshToken.next(newAzureAcessTOken);
  }
}
