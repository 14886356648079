import { Action } from '@ngrx/store';

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

export interface LanguageSelectionAction extends Action {
  payload: {
    en: boolean;
    es: boolean;
    fr: boolean;
  };
}

export function getLanguageName(en, es, fr): LanguageSelectionAction {
  return {
    type: LANGUAGE_CHANGED,
    payload: {
      en,
      es,
      fr
    },
  };
}

export class LanguageSelectionSuccessAction implements Action {
  readonly type: string = LANGUAGE_CHANGED;
  constructor(public payload: Object) {}
}
