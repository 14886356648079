<div class="reset-password-container">
  <div class="container">
    <div class="mainContentContainer">
      <div class="logoContainer"
        [ngClass]="languageRoute.route === 'en-CA' || languageRoute.route === 'fr-CA' ? 'logoContainerCA' : ''">
        <img class="brandLogo" src="{{config.brandlogoImgUrl}}" alt="{{config.brandLogoAltText}}" height="44px" width="150px">
        <div class="toggle">
          <app-studio-custom-multiple-toggle [config]="config.mobileToggleConfig"
            [toggleButtons]="config.toggleButtons"></app-studio-custom-multiple-toggle>
        </div>
        <div class="mobileMenu">
          <ng-sidebar-container class="mobileSideMenu"
            [ngStyle]="_opened ?  { 'height': '100vh','overflow':'auto'} : null">
            <ng-sidebar [(opened)]="_opened" position="left" closeOnClickOutside="true" showBackdrop="true">
              <div class="sideBarContent">
                <div class="closeIcon" (click)="_toggleSidebarClose()">
                  <img class="mobileCompanyLogoImg" src="{{navbarConfig.logoSideBar}}"
                    alt="{{navbarConfig.logoAltText}}" />
                  <img class="closeIconImg" src="{{navbarConfig.closeIcon}}" alt="{{navbarConfig.closeIconAltText}}" />
                </div>
                <div class="menuList" *ngIf="menuListMobile && menuListMobile.length >0">
                  <div class="menuItem" *ngFor="let list of menuListMobile" (click)="onRedirect(list)">
                    <div class="menuItemLabel">{{list.name}}</div>
                    <div class="arrowDiv">
                      <div class="arrow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-sidebar>
            <div ng-sidebar-content class="mobileMenuButtonDiv">
              <button class="sideBarButton" (click)="_toggleSidebar()">
                <img src="{{navbarConfig.mobileMenu}}" alt="{{navbarConfig.mobileMenuAltText}}" />
              </button>
            </div>
          </ng-sidebar-container>
        </div>
      </div>
      <div class="messageLoginContainer">
        <div class="messageContainer"><span class="message">{{config.leftHeader}}</span>
        </div>
        <div class="loginContainer">
          <div class="keyLogo"><img src="{{config.keyLogoImgUrl}}" alt="Key Logo" width="40px" height="40px">
            <div class="LoginMessageContainer">
              <div class="loginMessage">{{config.loginMessage}}</div>
              <div class="subtext-container">
                <div class="subtext-section">
                  <span class="subtext">{{config.subtext}}</span>
                  <span class="subtext subtext-bold">{{config.subtext2}}</span>
                </div>
                <div class="subtext-section">
                  <span class="subtext">{{config.subtext3}}</span>
                  <span class="subtext subtext-bold">{{config.subtext4}}</span>
                </div>
                <div class="subtext-section">
                  <span class="subtext">{{config.subtext5}}</span>
                  <span class="subtext subtext-link" (click)="navigate()">{{config.subtext6}}<span
                      [ngClass]="'border-'+languageRoute.route"></span>
                  </span>
                  <span class="subtext">{{config.subtext7}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="loginFormContainer">
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <app-footer [footerConfig]="footerConfig" [languageConfig]="languageConfig"></app-footer>
    </div>
  </div>
</div>