import { createAction, props } from '@ngrx/store';
export const saveRegistrationDeatils = createAction(
  '[Registration Details] Save Data',
  props<{ payload: any }>()
);
export const resetRegistrationDetails = createAction('[Registration Details] Reset');

export const showOtpInRegisterationPage = createAction(
  '[Registration Details] show OTP',
  props<{ payload: any }>()
);

export const searchRegistrationDeatils = createAction(
  '[Registration Details] Search Data',
  props<{ payload: any }>()
);