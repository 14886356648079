import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinningIndicatorComponent } from './spinning-indicator.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SpinningIndicatorComponent],
  exports: [SpinningIndicatorComponent],
  entryComponents: [SpinningIndicatorComponent],
})
export class SpinningIndicatorModule {
  static rootComponent = SpinningIndicatorComponent;
}
