import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralizedPopupModel } from "./generalized-popup.model";
@Component({
  selector: "app-generalized-message-popup",
  templateUrl: "./generalized-popup.component.html",
  styleUrls: ["./generalized-popup.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GeneralizedPopUpComponent implements OnInit {
  imageMap: any[];
  imageSize: any[];
  constructor(
    public dialogRef: MatDialogRef<GeneralizedPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeneralizedPopupModel,
    public sanitizer: DomSanitizer
  ) {}

  onNoClick(): void {
    this.dialogRef.close("cancel");
  }

  onClose(): void {
    this.dialogRef.close("button");
  }

  onButtonTwoClick(): void {
    this.dialogRef.close("button2");
  }

  ngOnInit(): void {
    this.imageMap = [
      `${this.data.popupImageLinkOne} ${this.data.imageSizeOne}`,
      `${this.data.popupImageLinkTwo} ${this.data.imageSizeTwo}`
    ];

    this.imageSize = [
      `(max-width: ${this.data.widthOne}) ${this.data.imageSizeTwoInPx}`,
      `${this.data.imageSizeOneInPx}`
    ];
  }

  onClickOk() {
    this.dialogRef.close();
  }
}
