// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { b2cUatConfig } from './b2c-config.uat';

export const environment = {
  production: true,
  development: false,
  isProd: false,
  servicingFooterUrl: 'https://www.chubb.com',
  siteKey: '6LdIKrgUAAAAAGRVcIm5Rndr73c9OnnNm5y6e2hq',
  backend: '/api',
  backendClaims: 'https://studio-beta-uat.chubb.com/api/apac',
  configBlobUrl: '/assets/config',
  url: {
    pageNotFound: 'page-not-found',
  },
  benefitRegion: 'TMK',
  ApimSubscriptionKey: 'b899a0158cb3472ba05fa68fed912e15',
  chubbApiVersion: '1',
  msalConfig: b2cUatConfig,
  // eCSRmsalConfig: {
  //   clientId: "7ef2519c-6232-4430-b538-b12bb420ef35",
  //   tenantId: "fffcdc91-d561-4287-aebc-78d2466eec29",
  //   redirectUri: "https://my-uat.combinedinsurance.com/en-US/registration/registrationMethodTwo/ecsr",
  //   postLogoutRedirectUri: "https://my-uat.combinedinsurance.com/en-US/registration/registrationMethodTwo/ecsr"
  // },
  apis: {
    getIdTokenHint: '/adb2c/verification',
  },
  uri: {
    validatePolicy: '/validatepolicy',
    downloadCOI: '/downloadcoi',
    endorsement: '/endorsement',
    documentlistCoi: '/documentlistcoi',
    verification: '/validateaccount',
    policySummary: '/policysummary',
    documentlistPolicy: '/documentlistpolicy',
    userprofile: '/userprofile',
    updateuseprofile: '/updateuseprofile',
    sciDownloadCOI: '/scidownloadcoi',
    registerUser: '/registeruser',
    retrieveUsername: '/retrieveusername',
    documentpolicy: '/documentpolicy',
    documentcoi: '/documentcoi',
    userPolicies: '/userpolicies?username=',
    addressLookUp:
      '/lookupAddress?Data.AddressLine1=$searchTerm$&Data.Country=$country$&Data.PostalCode=$postalCode$&MaxCandidates=$numRows$',
    validateZip: '/validateZip',
    policySearch: '/policySearch',
    createFNOL: '/claims/createFNOL',
    uploadDocument: '/claims/documents/',
    submitFNOL: '/claims/submitFNOL/',
    getClaims: '/claims',
    getAlerts: '/getUserAlerts',
    updateAlerts: '/updateUserAlerts',
    csrPolicySearch: '/csrPolicySearch',
    csrPolicyInsuredGuid: '/policies/',
    billingaccounts: '/billing/accounts',
    billingaccountactivity: '/billing/account-activity',
    billingaddress: '/billing/address',
    billingdocument: '/billing/document',
    billingdocumentlist: '/billing/documents',
    billingpaymentschedule: '/billing/payment-schedule',
    pageNotFound: '404',
    preferences: '/get-preferences',
    reportPolicy: '/accessrequest',
    apacPolicies: '/policies',
    apacCreateFNOL: '/createFNOL',
    apacGetFNOL: '/getFNOL',
    apacValidateFNOL: '/validateFNOL',
    apacSubmitFNOL: '/submitFNOL',
    apacAddDocument: '/addDocument/',
    apacFlightStats: '/updateFNOLWithFlightStatus',
    apacGetDocument: '/getDocument',
    apacUpdateFNOL: '/updateFNOL',
    apacSubmitEft: '/eftSubmission',
    apacDeleteDocument: '/deleteDocument',
  },
  reportLostPolicy: {
    EventDefinitionKey: 'APIEvent-0d974010-b808-8b4d-49b5-a887a206f230',
    ToEmailAddress: 'SFMCTestOverride@chubb.com',
  },
  // ADB2C Configurations
  // UAT client ID and redirect
  // clientID: 'f1ca872b-463f-49ab-9160-38bef2c7d9e6',
  // redirectUrl:
  //   'https://studio-beta-uat.chubb.com/us/chubb/default/default/en-US/Dashboard',

  /*New Authority to support javascript in blob */
  authority:
    'https://cbaadb2c08uat.b2clogin.com/cbaadb2c08uat.onmicrosoft.com/B2C_1A_SPSignIn',

  /* Old Authority*/
  // 'https://login.microsoftonline.com/tfp/cbaadb2c08uat.onmicrosoft.com/B2C_1A_SPSignIn',

  extraQueryParameter: 'p=B2C_1A_SPSignIn&scope=openid&nux=1',
  b2cRead: 'https://uatb2c.chubbdigital.com/ServicingportalAPI/read',
  b2cWrite: ' https://uatb2c.chubbdigital.com/ServicingportalAPI/write',
  /**
   * Analytics
   */
  gtmTrackingId: 'GTM-5S5SSCV',
  //gaTrackingId: 'UA-75217127-1',
  // gaTrackingId: 'UA-75217127-3', //PROD
  appInsightsInstrumentationKey: 'b39499f6-27c4-40f9-9a44-8b5487988345',
  /** Survey Link */
  surveyLink: 'https://village.chubb.com/welcome',
  webclaimURL: {
    tokenizeAPI: 'https://nauat.chubbdigital.com/api/security/tokenize',
    tokenAPI: 'https://nauat.chubbdigital.com/api/tokens/?Identity=AAD',
    docMetaDataSaveAPI:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/DocMetaDataSave',
    getWebDropdown:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/sqlw/webdropdowns',
    createClaimAPI:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/hllw/New',
    addressUniqueId:
      'https://amer-staging.spectrum.precisely.com/rest/CreateAddressingInstanceKey/results.json',
    addressSearch:
      'https://nauat.chubbdigital.com/enterprise.operations.searchaddress/commercial/addressSearch',
    addressSearchNew:
      'https://amer-staging.spectrum.precisely.com/rest/GlobalTypeAheadUSCAN/results.json',
    PassKey: '',
    RegionName: 'CICST4',
    ServiceCenter: '01',
    UserId: '', // always blank
    submitClaim:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/hllw/triage',
    createInteraction:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/sqlw/interactions',
    getMutitPolicyInsured:
      'https://devsvca.combinedinsurance.com/CTGServiceA/Service1.svc/REST/GetMultiPolicyInsureds',

    // App_ID: 'a533150f-7deb-44a5-bee4-707b44fd98d6',
    // App_Key: '7278j,R[d9c/Jy]l/Qq[12gs',
    // tokenURL: 'https://nasit.chubbdigital.com/api/v1/tokens',
    // uploadAttachmentURL: 'https://nasit.chubbdigital.com/claims.servicing.attachments/',
    tokenAPIResource: '8e6fda62-b3cb-43d9-93e8-2d3349f98902',

    tokenURL: 'https://nauat.chubbdigital.com/api/v1/tokens',
    App_ID: '15f90575-e9c9-4dee-a5dc-f9e37b624840',
    App_Key: '90mC_=Kg&=x+\\2m1b0nM_1AY',
    uploadAttachmentURL:
      'https://nauat.chubbdigital.com/claims.servicing.attachments/',

    attachmentTypeCd: 'VehicleFirstNoticeOfLoss',
    emailiD: 'test@chubb.com',
    Request0357ServiceCenter: '01', // should be based on country code shared by
    Request0357ServiceCenter02: '02',
    Request0443ServiceCenter01: '01',
    Request0443ServiceCenter02: '02',
    tempCreateClaimAPI:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/prtw/newPdf',
    printClaimTiffAPI:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/prtw/newPdf',
    tempClaimNumber: '99999999',
    claimType: 'Wellness',
    accidentClaimType: 'Accident',
    disabilityClaimType: 'Disability',
    hospitalClaimType: 'Hospital',
    criticalIllnessClaimType: 'Critical Illness',
    CancerClaimType: 'Cancer',
    newClaimBen:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/ctgw/clbenefits',
    defaultCustomerCare: '1-800-225-4500',
    getWCSessionData:
      'https://devsvca.combinedinsurance.com/SQLService/Service1.svc/REST/GetWCSessionData',
    sessionLogoutURL: '/pkmslogout',
    customerSupportLink:
      'http://www.combinedinsurance.com/about-combined/contact-options.html',
    otherClaimTypeReRouteURL:
      '/self-service-web/self-service-web/customer/claims/newClaim.html',
    consentsLinkEn:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=USEnglish.pdf',
    consentsLinkEs:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=USSpanish.pdf',
    consentsLinkCAEn:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=CANADAEnglish.pdf',
    consentsLinkCAFr:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=CANADAFrench.pdf',
    consentsLinkEnNY:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=NYEnglish.pdf',
    consentsLinkEsNY:
      'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=NYSpanish.pdf',
    sicknessClaimType: '00S',
    apiKey: 'd1e6c9f6-17a8-4ee3-b739-8d5b5dfd5840',
    categoryCode: 'WC8',
    chubbCategoryCode: 'WC2',
    lifeWOPremiumNatureCode: '987',
    lifeNODeathNatureCode: '987',
    lifeWOPremiumCategoryCode: 'W',
    lifeNODeathCategoryCode: 'WCL',
    lifeWOPremiumClaimType: 'NCO',
    lifeNODeathClaimType: 'NOD',
    illinoisLifeNODeathClaimType: 'NDI',
    getClaimHistory:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/ctgw/history',
    getClaimsStatus:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/sqlw/statusOt',
    getPolicyBenefitClaim:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/ctgw/benefits',
    cancerClaimType: '00C',
    cancerCategoryCode: 'WCR',
    cancerDiagnosisCategoryCode: 'WC8',
    denialLetter:
      'https://devsvca.combinedinsurance.com/ondemandservice/service1.svc/rest/GetDenialLetter',
    getActivityByClaim:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/claims/ctgw/activity',
    allowOrigin: 'https://*.combinedinsurance.com',
  },
  SendEmailUrl: {
    tokenAPI:
      'https://sit.studiogateway.chubb.com/enterprise.operations.authorization/?Identity=AAD',
    App_ID: 'fe86abe0-78c9-469b-932c-a2ade48ef130',
    // App_Key: 'Bpi7Q~miShLPvWZgsJotFwuvJlCny6o1~~Cu5',
    App_Key: 'IQv8Q~69-E9_sIi44V2oPe8vJOUCMNDRqan-aawc',
    apiVersion: '1',
    Resource: 'fcc232a6-1ff3-4eb3-b645-f57305338744',
  },
  ssUrl: {
    ssUpdateRegister:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/registration/change',
    ssSaveRegister:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/registration/save',
    ssDashboard:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/dashboard',
    ssFindCustomer:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/registration/customer',
    ssFindPolicies:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/policies',
    ssLoginInfo:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/login/save',
    ssPaymentHist:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/payments/history',
    ssFindBeneficiaries:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/beneficiaries/history',
    ssFindDependents:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/insureds/history',
    ssFindDocumentsHist:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/documents/history',
    ssFetchDocument:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/documents/fetch',
    ssSaveDetails:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/profile/change',
    ssRegistrationToken: 'https://nauat.chubbdigital.com/api/tokens',
    ssRegistrationTokenBuilder:
      'https://nauat.chubbdigital.com/servicing.b2cidtokenbuilder/token',
    ssVerificationRequest:
      'https://nauat.chubbdigital.com/servicing.b2cidtokenbuilder/Verification/Request',
    ssVerificationCheck:
      'https://nauat.chubbdigital.com/servicing.b2cidtokenbuilder/Verification/Check',
    ssUserProfile:
      'https://nauat.chubbdigital.com/api/digital/adb2cplatform/api/userprofile',
    ssSendResetPasswordEmail:
      'https://nauat.chubbdigital.com/api/digital/adb2cplatform/api/passwordsetuplink',
    ssCustomEvent:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/events/save',
    ssSendResetWalletFeature:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/Wallet',
    ssDeleteWallet:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/Wallet/Remove',
    ssSendFourDigitOtpEmailCheck:
      'https://nasit.chubbdigital.com/servicing.b2cidtokenbuilder/Verification/VerifyPin',
    ssSendEmailOTPQUickRegistration:
      'https://sit.studiogateway.chubb.com/enterprise.system.emailservice/SendEmail',
    ssMemberDashboard:
      'https://devsvc.combinedinsurance.com/SelfServiceT/Service1.svc/REST/memberdashboard',
    getPolicyBenefits:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/policy/benefits',
    getClaimDocumentList:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/ClaimSupportingDocuments',
  },
  loadingTimer: 7000,
  payment: {
    tokenexIframeUrl:
      'https://nauat.chubbdigital.com/servicing.cicadigital/TXW/tokenex/iframeurl',
    tokenexMonerisPurchase:
      'https://nauat.chubbdigital.com/servicing.cicadigital/TXW/tokenex/moneris/purchase',
    tokenexMonerisPreAuth:
      'https://nauat.chubbdigital.com/servicing.cicadigital/TXW/tokenex/moneris/preauth',
    savePaymentUrl:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/payments/save',
    lyonsVerifyAccountAPI:
      'https://nauat.chubbdigital.com/servicing.cicadigital/VerifyAccount',
    serviceCenter: '01',
    setupPayor:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/Wallet/Setup',
  },
  ecsr: {
    verifyOtp:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/registration/VerifyPin?id={ID}&pin={PIN}&channel=WebSelfService',
  },
  contactNumberList: {
    picsNY: '1-800-951-6206',
    wrksNY: '1-888-441-7936',
    picsNonNY: '1-800-225-4500',
    wrksNonNY: '1-800-544-9382',
    pfpNonNY: '1-800-488-0603',
    pfpNY: '1-800-341-3718',
    CWB: '(833) 542-2013',
    CWBNY: '(833) 542-2013',
    canadaPhoneNumber: '1-888-234-4466',
  },
  adjCompanyValues: {
    adjCompanyValue10: '10',
    adjCompanyValue20: '20',
    adjCompanyValue70: '70',
    adjCompanyValue80: '80',
    adjCompanyValue90: '90',
  },
  notification: {
    notifHistory:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/alerts/history',
    markRead:
      'https://nauat.chubbdigital.com/cica.operations.cicaselfservice/alerts/Change',
  },
  WEBCLAIM: 'WEBCLAIM',
  addressUserName: 'CICA_SelfServiceWeb',
  addresspassword: '5hvDJ`|1QB?Z1f+X84m#x5?z',
  addressLine1: '?Data.AddressLine1=',
  instanceKey: '&Data.InstanceKey=',
  country: '&Data.Country=',
  castingKey: '&Option.OutputCasing=',
  tokenApiApp_ID: '22841036-2507-45f1-8f11-ecfbfb27c98e',
  tokenApiApp_Key: 'mAI34g~5QK.2JKBkVyzoqFb0lJGP~l_F_O',
  tokenAPIResource: '4d78f778-2ca3-4f32-9d0e-8d5e2fa6259e',
  tokenApiApp_ID_invitationCode: '353b4264-52bf-46d0-8646-1219016b5691',
  tokenApiApp_Key_invitationCode: '2qN9-oPDS9l4n.cI13wlQ3h.N-r_J62gvn',
  tokenAPIResource_invitationCode: 'b277e581-a91f-4841-b472-188e59ba615b',
  tokenScheme: 'fourANTOKENfour',
  paymentMethodTokenType: 'Tokenex',
  sourceDescription: 'COMBINED INS SELFSERVICE PAYMENT',
  creditCardPaymentType: 'recurring',
  preAuthPaymentType: 'PartialPay',
  preAuthTransactionAmount: '0.10',
  ssrChannel: 'WebSelfservice',
  findMoreLinkToDD: 'http://buy-uat.combinedinsurance.com/accident-policy',
  findMoreCALinkToDD: 'http://buy-uat.combinedinsurance.com/en-CA',
  findMoreFRLinkToDD: 'http://buy-uat.combinedinsurance.com/fr-CA',
  historyAPILabels: {
    userId: '?UserId=',
    customerId: '&CustomerId=',
    policy: '&Policy=',
    fName: '&Fname=',
    lName: '&Lname=',
    form: '&Form=',
    issueState: '&IssueState=',
    issueDate: '&IssueDt=',
    country: '&Country=',
    Channel: '&Channel=',
    serviceCenter: '&ServiceCenter=',
  },
  verificationRequestSubscriptionkey: '0790ae03cb02400f9c95386b1d9ba7d9',
  verificationRequestApiVersion: '1',
  applicationName: 'Combined Policyholder Portal',
  otpValidationAttempts: 5,
  skipOtpValidation: false,
  testMobileNumbersFlag: false,
  userProfileTokenConfig: b2cUatConfig.userProfileTokenConfig,
  twilioTokenConfig: b2cUatConfig.twilioTokenConfig,
  registrationTokenConfig: b2cUatConfig.registrationTokenConfig,
  registrationTokenBuilderConfig: b2cUatConfig.registrationTokenbuilderConfig,
  testDataForOtpValidation: false,
  testPhoneNumbersForOtpValidation: [],
  testEmailForOtpValidation: '',
  systemDownJson:
    'https://chs-uat.combinedinsurance.com/ContentHostingApp/SelfServiceWeb?filename=systemdowntime.json',
  emailAPIURL:
    'https://sit.studiogateway.chubb.com/enterprise.system.emailservice/SendEmail',
  emailTemplateAPIKey: 'px34udyy66',
  emailTemplateIdentifier: 'px34udyy66',
  emaiTemplateFromId: 'WelcometoCombinedTest@combined.com',
  emailTemplateName: 'quickQuoteTemplate.html',
  emailOTPQUickRegistration: {
    cCList: 'mamtha.sony@combined.com,Madhumitha.Prabakaran@Chubb.com',
    aPIKey: 'px34udyy66',
    identifier: 'px34udyy66',
    fromId: 'WelcometoCombinedTest@combined.com',
    attachments: null,
    bCCList: [],
    channel: 'DigitalSalesPortal',
  },
  usSupportEmail: ['cicphs@combined.com'],
  caSupportEmail: ['caphscustomerservice@ca.combined.com'],
};
