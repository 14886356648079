import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { LoadModuleContext as CruxLazyLoadedModuleContext } from '@crux/components';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppContextService } from '../../../../app/app.context.service';
@Component({
  selector: 'app-stepper-new',
  templateUrl: './stepper-new.component.html',
  styleUrls: ['./stepper-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StepperNewComponent implements OnInit {
  @Input() stepperConfig: any;
  @Input() productConfig: any;
  @Input() stepsActivity: any;
  @Input() stepId: any;
  @Output() editClick = new EventEmitter();
  @Output() headerClick = new EventEmitter();

  private subscriptions: {
    [key: string]: Subscription;
  } = {};

  defaultPercentageStyling = {
    percentage: '0',
    radius: '50',
    outerStrokeWidth: '10',
    innerStrokeWidth: '10',
    space: '-10',
    outerStrokeColor: '#003a63',
    innerStrokeColor: '#D5D5D5',
    titleFontSize: '30',
    unitsFontSize: '30',
    titleColor: '#003A63',
    titleFontWeight: '700',
    unitsFontWeight: '700',
    showSubtitle: false,
    animation: true,
    animationDuration: '300',
    startFromZero: false,
    responsive: true,
    backgroundColor: '#FFFFFF',
  };
  name: string;

  context: CruxLazyLoadedModuleContext;

  percentageStyle: any;
  isMobileView: boolean;
  sessionId: any;
  isEcsr = false;
  fullStoreObj: any;
  claimType: any;
  claimNum: any;
  claimResult: any;
  claimNumLabels: any;
  isRecurringPayment = false;
  isRecurringOption = false;
  multipleClaimResult = [];
  /**
   * @todo to be used when using load-module directive
   * from CRUX Components
   */
  moduleRegistry: {
    [key: string]: string;
  };
  progressBar = document.querySelector('.progress-bar');
  intervalId;

  constructor(
    private _appContext: AppContextService,
    private router: Router,
    private _store: Store<any>
  ) {
    this.moduleRegistry = this._appContext.get(`moduleRegistry`);
    this._store.subscribe((state) => {
      this.fullStoreObj = state;

      if (this.fullStoreObj?.['paymentDetails'] && this.fullStoreObj?.['paymentDetails']?.['isRecurringPayment']) {
        this.isRecurringPayment = true;
      }

      if (this.fullStoreObj?.['paymentDetails'] && this.fullStoreObj?.['paymentDetails']?.['isRecurringOption']) {
        this.isRecurringOption = true;
      }

      if (
        this.fullStoreObj['stepWidgetReady'] &&
        this.fullStoreObj['stepWidgetReady']['claimResults']
      ) {
        if (
          this.fullStoreObj['stepWidgetReady']['claimResults']['type'] !==
          undefined
        ) {
          this.claimType = this.fullStoreObj['stepWidgetReady']['claimResults'][
            'type'
          ];
        }
        if (
          this.fullStoreObj['stepWidgetReady']['claimResults'].claimId !==
          undefined
        ) {
          this.claimNum = this.fullStoreObj['stepWidgetReady']['claimResults'][
            'claimId'
          ];
        }
        if (
          this.fullStoreObj['stepWidgetReady']['claimResults'] !== undefined
        ) {
          this.claimResult = state['stepWidgetReady']['claimResults'];
        }
        if (this.fullStoreObj['stepWidgetReady']['multipleClaimResults'] !== undefined) {
          this.multipleClaimResult = state['stepWidgetReady']['multipleClaimResults'];
        }
      }
    });
  }

  ngOnInit() {
    if (screen.width < 931 || window.innerWidth < 931) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    window.onresize = () => {
      if (screen.width < 931 || window.innerWidth < 931) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    };
    this.percentageStyle = this.productConfig.percentageStyling;
    this.claimNumLabels = this.productConfig.claimNumLabels;
  }

  navigate(index) {
    /* istanbul ignore else*/
    if (
      this.stepsActivity !== undefined &&
      this.stepsActivity[index].editLink !== undefined &&
      this.stepsActivity[index].editLink !== ''
    ) {
      this.router.navigate([this.stepsActivity[index].editLink]);
    }
  }

  headerAction(
    action: number,
    index: number,
    subheaderIndex: number,
    stepsActivity: any
  ) {
    const headerAction = {
      selection: action,
      headerIndex: index,
      subheaderIndex: subheaderIndex,
    };
    this.headerClick.emit(headerAction);
  }

  subHeaderAction(
    action: number,
    index: number,
    subheaderIndex: number,
    subHeader: any
  ) {
    const headerAction = {
      selection: action,
      headerIndex: index,
      subheaderIndex: subheaderIndex,
    };
    this.headerClick.emit(headerAction);
  }

  handleEdit(url) {
    url = document.location.pathname.substr(1, 5) + '/' + url;
    this.editClick.emit(url);
    this.router.navigate([url]);
  }

  getTooltip(index) {
    if (
      this.stepsActivity !== undefined &&
      this.stepsActivity[index] !== undefined &&
      this.stepsActivity[index + 1] !== undefined &&
      this.stepsActivity[index].showEdit !== undefined &&
      this.stepsActivity[index].showEdit !== true &&
      this.stepsActivity[index].isActive &&
      this.stepsActivity[index + 1].showEdit !== undefined &&
      this.stepsActivity[index + 1].showEdit !== true
    ) {
      return this.productConfig.toolTipMsg;
    }
  }

}
