import { Directive, OnInit, ElementRef, Renderer2, NgModule, HostListener } from '@angular/core';

@Directive({
  selector: '[appSharedNew]'
})
export class SharedNewDirective{

constructor(private elementRef: ElementRef ,private renderer : Renderer2){
  }
   @HostListener('keydown', ['$event']) onKeyDown(event) {
        if(this.elementRef.nativeElement.getAttribute('role') === 'button' && this.elementRef.nativeElement.getAttribute('tabindex') === '0' && (this.elementRef.nativeElement.tagName === 'DIV' || this.elementRef.nativeElement.tagName === 'A' || this.elementRef.nativeElement.tagName === 'SPAN' ||this.elementRef.nativeElement.tagName === 'NG-SIDEBAR-CONTAINER')  && (event.key === '' || event.key  ===  'Enter')){
              this.elementRef.nativeElement.click();
        }else if(this.elementRef.nativeElement.getAttribute('tabindex') === '0' && (event.key === '' || event.key  ===  'Enter')){
              this.elementRef.nativeElement.click();
        }
      }
      @HostListener('mousedown', ['$event']) onMouseDown(event) {
        if (this.elementRef.nativeElement.classList[0] === 'desktopMenuItemLabel') {
          this.renderer.setStyle(this.elementRef.nativeElement, 'border', 'none');
        }
      }
}

@NgModule({
  declarations: [ SharedNewDirective ],
  exports: [ SharedNewDirective ]
})

export class SharedNewDirectiveModule {}