import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutopayPopupComponent } from './shared/autopay-popup/autopay-popup.component';
import { GeneralMessageDialogComponent } from './general-message-dialog/general-message-dialog.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AutopayPopupComponent, GeneralMessageDialogComponent],
  exports: []
})
export class WidgetsModule {}
