import {createReducer, on} from '@ngrx/store';
import {resetWellnessDocumentDetails, saveWellnessDocumentDeatils} from '../actions';
import { wellnessDocumentDeatilsInitialState } from '../initial-state';

const _wellnessDetailsReducer = createReducer(
    wellnessDocumentDeatilsInitialState,
    on(saveWellnessDocumentDeatils, (state, { payload }) => ({ ...state, ...payload })),
    on(resetWellnessDocumentDetails, (state) => ({}))
);

export function wellnesDocumentDetailsReducer(state = wellnessDocumentDeatilsInitialState, action) {
    return _wellnessDetailsReducer(state, action);
}
