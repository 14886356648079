import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinningIndicatorComponent } from '../widgets/spinning-indicator/spinning-indicator.component';

@Injectable()
export class LoadingIndicatorService {
  dialogRef: any;
  constructor(public dialog: MatDialog) {}
  openLoadingIndicator(data) {
    this.dialogRef = this.dialog.open(SpinningIndicatorComponent, {
      height: 'auto',
      data: {},
      disableClose: true,
    });
  }
  closeLoadingIndicator() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  isLoadingIndicatorOpen(){
    if (this.dialog.openDialogs && this.dialog.openDialogs.length === 0) {
      return false;
    } else {
      return true;
    }
  }
}
