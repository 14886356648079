import { Action } from '@ngrx/store';
import {
  ClaimType,
  getClaimType,
} from '../initial-state/claim-type.state';
import {
  ClaimTypeSelectionAction,
  CLAIM_TYPE,
} from '../actions/claim-type.action';

export function claimTypeReducer(
  state: ClaimType = getClaimType(),
  action: Action
) {
  switch (action.type) {
    case CLAIM_TYPE: {
      const typedAction = <ClaimTypeSelectionAction>action;
      return {
        ...state,
        claimSelected: typedAction.payload.claimSelected
      };
    }
    default: {
      return state;
    }
  }
}
