import { Injectable, Injector } from '@angular/core';
import {
  Adb2cConfig,
  Adb2cState,
  AuthProvider,
  AuthProviderFactory,
  ResponseMode,
  StorageLocation,
  StorageUtilsService,
  TokenRequest
} from '@chubb-auth/types';
import { AuthProviderDemoConfig } from './auth-provider-config';
import { isNil } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
// import { PrsAuthConfig, PrsAuthProvider } from '@chubb-auth/prs';

export interface RouteBuilderOptions {
  policyName: string;
  scope?: string;
  responseType?: string;
  redirectUri?: string;
  promptLogin?: boolean;
}
@Injectable(
  {
    providedIn: "root",
  }
)
export class AuthProviderService {
  readonly CONFIG_KEY = 'AUTH_DEMO_CONFIG';
  readonly STATE_KEY = 'CICA_SS_AUTH';

  currentConfig: Adb2cConfig;
  registrationConfig: Adb2cConfig;
  extendSessionConfig: Adb2cConfig;
  changePasswordConfig: Adb2cConfig;
  logginSessionData: Adb2cState;
  firstLogin: boolean;
  authProvider: any;
  language: any;
  private loggedInFlg = new BehaviorSubject<Boolean>(false);
  public loggedInFlg$ = this.loggedInFlg.asObservable();

  constructor(
    private readonly _providerFactory: AuthProviderFactory
  ) {
    this.registrationConfig = {
      stateKey: this.STATE_KEY,
      authProvider: environment.msalConfig.registrationConfig.registrationAuthProvider,
      tenant: environment.msalConfig.registrationConfig.registrationTenantId,
      clientId: environment.msalConfig.registrationConfig.registrationClientId,
      policy: environment.msalConfig.registrationConfig.registrationPolicy,
      redirectUri: environment.msalConfig.registrationConfig.registrationRedirectUrl,
      scopes: environment.msalConfig.registrationConfig.registrationScopes,
      stateLocation: StorageLocation.Local,
    } as Adb2cConfig;
    this.currentConfig = {
      stateKey: this.STATE_KEY,
      tenant: environment.msalConfig.loginConfig.loginTenantId,
      clientId: environment.msalConfig.loginConfig.loginClientId,
      policy: environment.msalConfig.loginConfig.loginPolicy,
      redirectUri: environment.msalConfig.loginConfig.loginRedirectUrl,
      scopes: environment.msalConfig.loginConfig.loginScopes,
      stateLocation: StorageLocation.Local,
    } as Adb2cConfig;
    this.changePasswordConfig = {
      stateKey: this.STATE_KEY,
      tenant: environment.msalConfig.changePasswordConfig.changePasswordTenantId,
      clientId: environment.msalConfig.changePasswordConfig.changePasswordClientId,
      policy: environment.msalConfig.changePasswordConfig.changePasswordPolicy,
      redirectUri: environment.msalConfig.changePasswordConfig.changePasswordRedirectUrl,
      scopes: environment.msalConfig.changePasswordConfig.changePasswordScope,
      stateLocation: StorageLocation.Local,
    } as Adb2cConfig;

    const providerType = 'MSAL2';
    this.authProvider = this._providerFactory.getProvider(providerType);
    this.authProvider.initialize(this.currentConfig);
  }

  getAuthProvider(): AuthProvider {
    if (this.authProvider) {
      return this.authProvider;
    }
    const providerType = 'MSAL2';
    this.authProvider = this._providerFactory.getProvider(providerType);
    return this.authProvider;
  }

  getLoginProvider(): AuthProvider {
    const authProvider = this._providerFactory.getProvider(
      "MSAL2"
    );
    authProvider.initialize(this.currentConfig);
    return authProvider;
  }

  saveLoggedInSessionInfo(data) {
    this.logginSessionData = data;
  }

  getLoggedInSessionInfo() {
    return this.logginSessionData;
  }

  setLoggedInFlg(flg) {
    this.loggedInFlg.next(flg);
  }

  setLanguage(route){
    this.language = route;
  }

  getRegistrationProvider(newConfig?: AuthProviderDemoConfig): AuthProvider {
    const authProvider = this._providerFactory.getProvider(
      "MSAL2"
    );
    authProvider.initialize(this.registrationConfig);
    return authProvider;
  }

  getChangePasswordProvider(newConfig?: AuthProviderDemoConfig): AuthProvider {
    const authProvider = this._providerFactory.getProvider(
      "MSAL2"
    );
    authProvider.initialize(this.changePasswordConfig);
    return authProvider;
  }
}
