import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { FormService } from "../services";

@Injectable()
export class SystemDownGuard implements CanActivate {


    constructor(private _service: FormService,
        private _router: Router){
    }

    canActivate(next: ActivatedRouteSnapshot,
        routerState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            this._service.getSystemDownTimeJson().subscribe((response: any) => {
                if (response && response.module && response.module.main && response.module.main.msg) {
                    const path = routerState.url.split("/")[1]; 
                    this._router.navigate([path + "/login"]);
                    resolve(false);
                } else {
                    resolve(true);
                }
              },err=>{
                  resolve(true);
              });
        });
    }
}
