import { createReducer, on } from '@ngrx/store';
import { resetPaymentDetails, savePaymentDeatils } from '../actions';
import { paymentDetailsInitialState } from '../initial-state';

const _paymentDetailsReducer = createReducer(
  paymentDetailsInitialState,
  on(savePaymentDeatils, (state, { payload }) => ({ ...state, ...payload })),
  on(resetPaymentDetails, (state) => ({}))
);

export function paymentDetailsReducer(
  state = paymentDetailsInitialState,
  action
) {
  return _paymentDetailsReducer(state, action);
}
