import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-general-message-dialog',
  templateUrl: './general-message-dialog.component.html',
  styleUrls: ['./general-message-dialog.component.scss']
})
export class GeneralMessageDialogComponent implements OnInit {

  successMessage: string;
  noOfDocumentsToUpload: number;
  totalDocs: number;

  displayCount: boolean;

  constructor(
    public dialogRef: MatDialogRef<GeneralMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
  }

  ngOnInit() {
    console.log(this.data);
  }

  onClickOfClose(): void {
    this.dialogRef.close();
  }

}
