import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
// Required for Touch functionality of CRUX Components
import 'hammerjs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FormBuilderModule } from '@crux/components';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { LayoutModule } from './layout';
import { WidgetsModule } from '../app/widgets';
import {
  AnalyticsModule,
  GoogleTagManagerService,
  ApplicationInsightsService,
  GoogleAnalyticsService,
  IdlingModule,
} from '@crux/services';
import { WINDOW_PROVIDERS } from './services/window.service';
import { AuthProviderService } from './auth-provider-service';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomToggleModule } from './widgets/shared/custom-toggle/custom-toggle.module';
import {
  AppContextGuard,
  WebAppContextGuard,
  LoginContextGuard,
} from './guards';
import { Adb2cUtilsModule } from '@chubb-auth/types';
// import { MsalModule, MsalService, MsalGuard, BroadcastService } from '@azure/msal-angular';
import { MsalAuthGuard } from './guards/msal-auth.guard';
import {
  ClaimantlistReducer,
  claimTypeReducer,
  clearStore,
  iFrameReducer,
  LanguageSelectionReducer,
  loadingIndicatorReducer,
  sectionsReadyReducer,
  stepWidgetReadyReducer,
  stepWizardReducer,
  userIdleReducer,
  WebDropDownReducer,
  wellnessClaimDetailsReducer,
  wellnesDocumentDetailsReducer,
  wellnesReviewDetailsReducer,
  documentDetailsReducer,
  policyDetailsReducer,
  paymentDetailsReducer,
  selectedPolicyDetailsReducer,
  memberClaimDetailsReducer,
} from './state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { LoadingIndicatorEffects } from './state/effects';
import { DynamicOverlayContainer } from './widgets/loading-widget/dynamic-overlay/dynamic-overlay-container';
import { DynamicOverlay } from './widgets/loading-widget/dynamic-overlay/dynamic-overlay.service';
import { ChubbAuthMsal2PkceModule } from '@chubb-auth/msal2-pkce';
import { SpinningIndicatorModule } from './widgets/spinning-indicator/spinning-indicator.module';
import { HttpHeaderInterceptor } from './interceptors';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { SpinningIndicatorComponent } from './widgets/spinning-indicator/spinning-indicator.component';
import { FormValidationService } from './services/form-validation.service';
import { registrationDetailsReducer } from './state/reducers/registration-details.reducer';
import { LoginRedirectGuard } from './guards/login-redirect-guard';
// import { MsalService, MsalModule } from '@azure/msal-angular';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { SystemDownGuard } from './guards/system-down-guard';

export const httpProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true,
  },
];
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LayoutModule,
    WidgetsModule,
    BrowserModule,
    BrowserAnimationsModule,
    // App Modules
    HttpClientModule,
    AppRoutingModule.forRoot(),
    StoreModule.forRoot(
      {
        sectionsReady: sectionsReadyReducer,
        userIdle: userIdleReducer,
        loadingIndicators: loadingIndicatorReducer,
        languageSelection: LanguageSelectionReducer,
        claimantlistObject: ClaimantlistReducer,
        webDropDownList: WebDropDownReducer,
        stepWidgetReady: stepWidgetReadyReducer,
        iFrameData: iFrameReducer,
        claimType: claimTypeReducer,
        stepWizard: stepWizardReducer,
        wellnessClaimDetails: wellnessClaimDetailsReducer,
        wellnessDocumentDetails: wellnesDocumentDetailsReducer,
        wellnessReviewDetails: wellnesReviewDetailsReducer,
        dashboardDetails: documentDetailsReducer,
        policyDetails: policyDetailsReducer,
        paymentDetails: paymentDetailsReducer,
        registrationDetails: registrationDetailsReducer,
        selectedPolicyDetails: selectedPolicyDetailsReducer,
        memberClaimDetails: memberClaimDetailsReducer,
      },
      { metaReducers: [clearStore] }
    ),
    // Analytics Module for instrumenting events
    AnalyticsModule.forRoot(
      [
        GoogleTagManagerService,
        ApplicationInsightsService,
        GoogleAnalyticsService,
      ],
      {
        applicationInsights: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
        },
      }
    ),
    IdlingModule,
    MatDialogModule,
    // MsalModule.forRoot(
    //   {
    //     auth: {
    //       clientId: environment.eCSRmsalConfig.clientId,
    //       authority:
    //         "https://login.microsoftonline.com/" + environment.eCSRmsalConfig.tenantId,
    //       redirectUri: environment.eCSRmsalConfig.redirectUri,
    //       postLogoutRedirectUri: environment.eCSRmsalConfig.postLogoutRedirectUri
    //     },
    //     cache: {
    //       cacheLocation: "localStorage"
    //     }
    //   },
    //   {
    //     consentScopes: ["user.read", "openid", "profile"]
    //   }
    // ),
    CustomToggleModule,
    Adb2cUtilsModule,
    ChubbAuthMsal2PkceModule,
    EffectsModule.forRoot([LoadingIndicatorEffects]),
    SpinningIndicatorModule,
    FormBuilderModule,
  ],
  providers: [
    AppContextGuard,
    WebAppContextGuard,
    LoginContextGuard,
    LoginRedirectGuard,
    SystemDownGuard,
    WINDOW_PROVIDERS,
    AuthProviderService,
    LoadingIndicatorService,
    FormValidationService,
    // For lazy loading modules
    //{ provide: CRUX_APP_BUILD, useValue: { platform: 'DESKTOP' } },
    // MsalService,
    // MsalGuard,
    // BroadcastService,
    MsalAuthGuard,
    DynamicOverlay,
    DynamicOverlayContainer,
    httpProviders,
    // for jwt service
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [SpinningIndicatorComponent],
})
export class AppModule {}
