import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingWidgetComponent } from './loading-widget.component';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { AppContextService } from '../../app.context.service';
import { WebClaimsAppContextService } from '../../webclaims-app.content.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingWidgetService {
  private _overlayRefs: {
    [key: string]: OverlayRef;
  } = {};
  private dialogRef;
  constructor(
    public loadingWidgetDialog: MatDialog,
    private _overlay: Overlay,
    private _appContext: WebClaimsAppContextService
  ) {}

  openDialog() {
    this.dialogRef = this.loadingWidgetDialog.open(LoadingWidgetComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '750px',
      height: '60.88vh',
      data: { labels: this._appContext.get('pages.loadingLabels') },
      disableClose: true,
      panelClass: 'loading-modalbox',
    });
  }

  closeDialog(delayTime, discard = false) {
    if (discard) {
      this.dialogRef?.close();
    } else {
      setTimeout(() => {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }, delayTime);
    }
  }
}
