import { Injectable, Injector } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { ToastService } from '../widgets/toast/toast.service';
import { StudioHttpResponseError } from './studio-http-response.error';
import { StudioError } from './studio.error';

@Injectable({
  providedIn: 'root',
})
export class StudioErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: StudioError): any {
    const toast = this.injector.get(ToastService);

    switch (error.name) {
      case 'StudioError':
        toast.showError(error.message);
        break;
      case 'StudioHttpResponseError':
        const studioHttpResponseError = <StudioHttpResponseError>error;
        toast.showError(
          `${studioHttpResponseError.message} ${
            studioHttpResponseError.httpErrorResponse
              ? '(' + studioHttpResponseError.httpErrorResponse.status + ')'
              : ''
          }`
        );
        break;
    }

    console.error(error);
  }
}
