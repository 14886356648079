import { Action } from '@ngrx/store';
import {
  Claimantlist,
  getClaimantlist,
} from '../initial-state/claimantlist.state';
import {
  ClaimantlistAction,
  CLAIMANT_UPDATED,
} from '../actions/claimantlist.action';

export function ClaimantlistReducer(
  state: Claimantlist = getClaimantlist(),
  action: Action
) {
  switch (action.type) {
    case CLAIMANT_UPDATED: {
      const typedAction = <ClaimantlistAction>action;
      return { ...state, claimantlist: typedAction.payload.claimantlist };
    }
    default: {
      return state;
    }
  }
}
