import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { Adb2cState } from '@chubb-auth/types';
import { cloneDeep } from 'lodash-es';
import { filter, map, take, timeout } from 'rxjs/operators';
import { AppContextService } from '../../app.context.service';
import { AuthProviderService } from 'src/app/auth-provider-service';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import { FormService } from '../../services/form.services';
import { StudioFormBuilderConfig, id__otpForm, id__sendCodeForm } from './user-info-ecsr-success.constant';
import { Store } from '@ngrx/store';
import { saveRegistrationDeatils } from '../../state/actions/registration-details.action';
import { environment } from '../../../environments/environment'  
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralizedPopUpComponent } from '../../widgets/shared/generalized-popup/generalized-popup.component';
import { OtpPopupComponent } from '../../widgets/shared/otp-popup/otp-popup.component';
import { FormValidationService } from '../../services/form-validation.service';
import { LanguageSelectionSuccessAction } from '../../state/actions';
import { LanguageChangePopupComponent } from '../../widgets/language-change-popup/language-change-popup.component';
import { getApiError } from '../../widgets/shared/utilities';
import { CustomEventService } from 'src/app/services/custom-event.service';
import { showOtpInRegisterationPage } from '../../state/actions/registration-details.action';

@Component({
  selector: 'app-user=info-ecsr-success',
  templateUrl: './user-info-ecsr-success.component.html',
  styleUrls: ['./user-info-ecsr-success.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInfoEcsrSuccessComponent implements OnInit, OnDestroy{
  config: any;
  formBuilderConfig: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formBuilderConfig1: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formGroup = new FormGroup({});
  radioControl = new FormControl();
  radioValues = [];
  selectedOption: any;
  disableSendButton: boolean;
  showOtpField: boolean;
  authTokenValue: any;
  sessionToken: any;
  userDetails: any;
  resendCount: number;
  submitCount: number;
  sendButtonText: string;
  registrationDetailsObject = {};
  showSkipButton: boolean;
  routerEventSub: Subscription;
  storeSub: Subscription;
  showResendText: boolean;
  appConfig: any;
  footerConfig: any;
  dialogRef: any;
  somethingWentWrongError: any;
  serverError: any;
  language: any;
  stepperConfig: any;
  productConfig: any;
  stepsActivity: any;
  showFourDigitOtpField: boolean;
  registrationDetailsAlternateObject : any;
  userEmailAddress: string;
  userRegistrationAttemptId: string;
  userRegistrationPin: string;
  ecsrConfig:any;
  apiToken: string;
  languageConfig: any;

  constructor(
    private _router: Router,
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _service: FormService,
    private _store: Store<any>,
    private _dialog: MatDialog,
    private formValidationService: FormValidationService,
    private _customEventService: CustomEventService
  ) {
    this.config = this._appContext.get("pages.userInfoEcsrSuccess");
    this.stepperConfig = this._appContext.get(
      'pages.registration.stepper.payment-amount'
    );
    // steps, editLinkLabel
    this.productConfig = this._appContext.get(
      'pages.registration.stepper.productInfo'
    );
    // showImg, currentStep
    this.stepsActivity = this._appContext.get(
      'pages.registration.stepper.payment-amount.stepperConfigRegistration'
    );
    this.footerConfig = this._appContext.get("footer");
    this.languageConfig = this._appContext.get("footer");
    this.somethingWentWrongError = this._appContext.get("somethingWentWrong");
    this.serverError = this._appContext.get("serverError");
    this.language = this._appContext.get("language.route");
    this.disableSendButton = true;
    this.showOtpField = false;
    this.resendCount = 0;
    this.submitCount = 0;
    this.showFourDigitOtpField = false;
    this.sendButtonText = this.config["otpButtonLabels"][0];
    this.ecsrConfig = this._appContext.get("pages.userInfoEcsrSuccess");
    this.storeSub = this._store.subscribe(state =>{
      if(state){
        if(state.registrationDetails.showOTP != undefined){
          this.showFourDigitOtpField = state.registrationDetails.showOTP;    
        }
        this.registrationDetailsObject = cloneDeep(state["registrationDetails"]);
        this.registrationDetailsAlternateObject = cloneDeep(state["registrationDetails"]);
        this.userEmailAddress = (this.registrationDetailsAlternateObject) ? this.registrationDetailsAlternateObject.findCustomerResponse.Email : '';
        this.userRegistrationAttemptId = (this.registrationDetailsAlternateObject) ? this.registrationDetailsAlternateObject.findCustomerResponse.RegistrationAttemptId : '';
        this.userRegistrationPin = (this.registrationDetailsAlternateObject) ? this.registrationDetailsAlternateObject.findCustomerResponse.RegistrationPin : '';
        if (state.iFrameData.apiToken !== undefined) {
          this.apiToken = state.iFrameData.apiToken;
        }
      }
    });
    this.showSkipButton = environment.skipOtpValidation;
    this.routerEventSub = this._router.events.pipe(
      filter( (event: NavigationEvent) => {
        return (event instanceof NavigationStart)
      })
    ).subscribe((event: NavigationStart) =>{
      if(event.navigationTrigger === "popstate"){
        this._router.navigate([this.config["loginPageLink"]]);
      }
    });
  }

  ngOnInit() {
    this.formatRadioValues(this.registrationDetailsObject["findCustomerResponse"]);
    this.retrieveFormBuilderConfig();
    this.twilioTokenApi();
    // this._loadingService.closeLoadingIndicator();
    
  }

  retrieveFormBuilderConfig(){
    if(this.config !== undefined) {
      this.formBuilderConfig = cloneDeep(
        id__otpForm(this.config["formConfig"])
      );
      this.formBuilderConfig1 = cloneDeep(id__sendCodeForm(this.config["formConfig"]));
    }
  }

  formatRadioValues(contactDetails){
    if(contactDetails){
      const phoneList = contactDetails["PhoneList"];
      const emailList = contactDetails["Email"];
      if(phoneList !== undefined && phoneList !== null && phoneList !== ""){
        phoneList.forEach(element => {
          const value: string = element.Value;
          const pattern = /[^0-9]/g;
          const result = value.match(pattern);
          if(value !== "" && !result){
            const ele = {
              "name": this.getMaskedValue(element),
              "formValue": "",
              "value": element.Value,
              "showForm": true,
              "type": "phone"
            }
            this.radioValues.push(ele);
          } else if(result && result[0] === "+" && result.length === 1){
            const ele = {
              "name": this.getMaskedValue(element),
              "formValue": "",
              "value": element.Value,
              "showForm": true,
              "type": "phone"
            }
            this.radioValues.push(ele);
          } 
        });
      }
      if(emailList !== undefined && emailList !== null && emailList !==""){
        const email = {
          "name": this.getMaskedValue(emailList),
          "formValue": {otpMethod: "Text"},
          "value": emailList,
          "showForm": false,
          "type": "email"
        }
        this.radioValues.push(email);
      }
    }
  }

  valueChange(formGroup: FormGroup){}
  handleChange(formGroup: FormGroup){
    if(formGroup.valid && formGroup.value !== undefined){
      this.selectedOption.formValue = formGroup.value
      this.handleValueChange(this.selectedOption);
    }
  }

    getMaskedValue(ele){
    if(typeof ele === "string"){
      const maskedValue = "******" + ele.substring(ele.lastIndexOf("@")-3);
      return maskedValue;
      return ele;
    } else {
      const maskedValue = "*** ***" + ele.Value.slice(-4);
      return maskedValue;
    }
  }

  OnSubmit(){
    if(this.formGroup.get("otp").valid){
      this.callVerificiationCheck(this.formGroup.get("otp").value);
    } else {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].markAsTouched();
        this.formGroup.controls[controlName].markAsDirty();
      });
    }
  }

  sendOTP(){
    if(this.selectedOption.formValue.otpMethod !== null && 
      this.selectedOption.formValue.otpMethod !== undefined) {
        if(this.authTokenValue !== undefined){
          this.callVerificationRequest(this.authTokenValue);
        } else {
          this.callVerificationRequest(this.twilioTokenApi());
        }
      }
  }

  navigate(){
    this._router.navigate([this.config["clickHereLink"]]);
  }

  handleValueChange(item){
    this.selectedOption = item;
    if(item.formValue.otpMethod === null ||
      item.formValue.otpMethod === undefined){
        this.disableSendButton = true;
      } else {
        this.disableSendButton = false;
      }
  }

  twilioTokenApi() {
    this._loadingService.closeLoadingIndicator();
    this._loadingService.openLoadingIndicator({});;
    this._service
      .twilioTokenApi()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.authTokenValue =
              response.token_type + ' ' + response.access_token;
            this.sessionToken = response.access_token;
            this._loadingService.closeLoadingIndicator();
            return this.authTokenValue;
          }
        },
        (error) => {
          this._dialog.open(GeneralizedPopUpComponent, this.serverError);
        }
      );
  }

  callVerificationRequest(token){
    const otpMethod = this.selectedOption.formValue.otpMethod;
    const ofType = this.selectedOption.type;
    const value = this.selectedOption.value;

    let payload = {};
    if(otpMethod !== null && otpMethod !== undefined){
      if(ofType && ofType === "email"){  
        payload["emailAddress"] = value;
        payload["channel"] = ofType;
      } else {
        payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
        payload["phoneNumber"] = value;
      }
    }
    if(this._router.url.includes("en-US")){
      payload["language"] = "en"
    } else if(this._router.url.includes("es-US")) {
      payload["language"] = "es"
    } else if (this._router.url.includes("en-CA")) {
      payload["language"] = "en-GB"
    } else {
      payload["language"] = "fr"
    }
    if(this.resendCount < environment.otpValidationAttempts){
      this._loadingService.openLoadingIndicator({});
      this._service.ssVerificationRequest(payload, token)
      .pipe(timeout(120000)).subscribe(response =>{
        if(response.status !== 200){
          this.showResendText = false;
          this._loadingService.closeLoadingIndicator();
        } else {
          this.resendCount = this.resendCount +1;
          this.sendButtonText = this.config["otpButtonLabels"][1];
          this._loadingService.closeLoadingIndicator();
          const popUpData = {
            "data": {
              "selectedOption" : this.selectedOption,
                "page" : 'successInfo',
                "registrationDetailsObject" : null
            }
          }
          this._dialog.open(OtpPopupComponent, popUpData);
          this.showOtpField = true;
        }
      }, error =>{
        if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
          const popUpData = this.config['maxAttemptPopUp'];
          const popUp = this._dialog.open(
            GeneralizedPopUpComponent,
            popUpData
          );
          popUp.afterClosed().subscribe((event) => {
            this._loadingService.closeLoadingIndicator();
            this._router.navigate([this.config["clickHereLink"]]);
          });
        }
      });
    } else {
      const popUpData = this.config['maxAttemptPopUp'];
      const popUp = this._dialog.open(
        GeneralizedPopUpComponent,
        popUpData
      );
      popUp.afterClosed().subscribe((event) => {
        this._loadingService.closeLoadingIndicator();
        this._router.navigate([this.config["clickHereLink"]]);
      });
    }
  }

  callVerificiationCheck(otp){
    if(otp && this.selectedOption){
      const otpMethod = this.selectedOption.formValue.otpMethod;
      const ofType = this.selectedOption.type;
      const value = this.selectedOption.value;
      let payload = {};
      payload["code"] = otp;
      if(otpMethod !== null && otpMethod !== undefined){
        if(ofType && ofType === "email"){  
          payload["emailAddress"] = value;
          payload["channel"] = ofType;
        } else {
          payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
          payload["phoneNumber"] = value;
        }
      }
      this._loadingService.openLoadingIndicator({});
      this._service.ssVerificationCheck(payload, this.authTokenValue).pipe(timeout(120000)).subscribe( response =>{
        this._loadingService.closeLoadingIndicator();
        this._router.navigate([this.config.nextRoute]);
        }, error =>{
          // this._router.navigate([this.config["clickHereLink"]]);
          if(error?.['error']?.['message'].toLowerCase() === "incorrect code") {
          this.submitCount++;
          this.formGroup.controls["otp"].setErrors({
            invalid: true
          });
          if(this.submitCount === environment.otpValidationAttempts){
            const popUpData = this.config['maxAttemptPopUp'];
            const popUp = this._dialog.open(
              GeneralizedPopUpComponent,
              popUpData
            );
            popUp.afterClosed().subscribe((event) => {
              this._loadingService.closeLoadingIndicator();
              this._router.navigate([this.config["clickHereLink"]]);
            });
          } else {
            this._loadingService.closeLoadingIndicator();
          }
        } else if(error?.['error']?.['message'].toLowerCase() === 'no pending verification requests'){
          this.showResendText = true;
          this._loadingService.closeLoadingIndicator();
        } else if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
          const popUpData = this.config['maxAttemptPopUp'];
          const popUp = this._dialog.open(
            GeneralizedPopUpComponent,
            popUpData
          );
          popUp.afterClosed().subscribe((event) => {
            this._loadingService.closeLoadingIndicator();
            this._router.navigate([this.config["clickHereLink"]]);
          });
        }
      });
    }
  }

  // reSendFourDigitOTP(){   
  //   this.sendEmailForQuickOTPRegistration();
  // }

  // sendEmailForQuickOTPRegistration() {

  //   this._loadingService.openLoadingIndicator({});
  //   let payload = {
  //     Subject: environment.emailOTPQUickRegistration.subject,
  //     ToList: [this.userEmailAddress],
  //     TemplateName: environment.emailOTPQUickRegistration.templateName,
  //     SubSimpleBodyTextject: environment.emailOTPQUickRegistration.simpleBodyText,
  //     templateData: environment.emailOTPQUickRegistration.templateData,
  //     CCList: [environment.emailOTPQUickRegistration.cCList],
  //     APIKey: environment.emailOTPQUickRegistration.aPIKey,
  //     Identifier: environment.emailOTPQUickRegistration.identifier,
  //     FromId: environment.emailOTPQUickRegistration.fromId,
  //     Attachments: environment.emailOTPQUickRegistration.attachments,
  //     BCCList: [environment.emailOTPQUickRegistration.bCCList],
  //     Channel: environment.emailOTPQUickRegistration.channel,
  //   };
  //   let simpleTextConetent = environment.emailOTPQUickRegistration.simpleBodyText + this.userRegistrationPin;
  //   payload["SimpleBodyText"] = simpleTextConetent;

  //   // to call an API to send email to user with 4 digit OTP and link to download mobile app
  //   this._service.ssSendEmailOTPQUickRegistrationCheck(payload, this.apiToken).pipe(timeout(120000)).subscribe(response => {
  //     //To show a popup wich tells user that 4 digit OTP and link to download mobile app is sent
  //     this.dispatchOtpToStoreValue(false);
  //     const popUpData = this.ecsrConfig['fourDigitEmailPopUp'];
  //     const popUp = this._dialog.open(
  //       GeneralizedPopUpComponent,
  //       popUpData
  //     );
  //     popUp.afterClosed().subscribe((event) => {
  //       this._loadingService.closeLoadingIndicator();
  //       this.dialogRef.close();
  //       this._router.navigate([this.ecsrConfig["backToEcsrLoginLink"]]);
  //     });
  //   },
  //     (error) => {
  //       const resendPopUpData = this.ecsrConfig['fourDigitEmailResendPopUp'];
  //       const resendPopUp = this._dialog.open(
  //         GeneralizedPopUpComponent,
  //         resendPopUpData
  //       );
  //       resendPopUp.afterClosed().subscribe((event) => {
  //         this.dispatchOtpToStoreValue(true);
  //         this._store.dispatch(
  //           showOtpInRegisterationPage({
  //             payload: this.registrationDetailsAlternateObject,
  //           })
  //         );
  //         this._loadingService.closeLoadingIndicator();
  //         this.dialogRef.close();
  //       });
  //     });
  // }

  dispatchOtpToStoreValue(value) {
    this.registrationDetailsAlternateObject["showOTP"] = value;
    this._store.dispatch(
      showOtpInRegisterationPage({
        payload: this.registrationDetailsAlternateObject,
      })
    );
  }

  backToLoginPage(){
    this._router.navigate([this.config["backToEcsrLoginLink"]]);
  }

  OnSkip(){
    this._router.navigate([this.config.nextRoute]);
  }


  ngOnDestroy(){
    this.routerEventSub.unsubscribe();
    this.storeSub.unsubscribe();
  }
}
