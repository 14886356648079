import { isNull } from '@angular/compiler/src/output/output_ast';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nested-custom-radio-group',
  templateUrl: './custom-nested-radio-group.component.html',
  styleUrls: ['./custom-nested-radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomNestedRadioGroupComponent implements OnInit {
  @Input() radioValues;
  @Input() customClassId = '';
  @Input() customFormControl: FormControl;
  @Input() formBuilderConfig;
  @Output() valueChanges = new EventEmitter();
  formGroup = new FormGroup({});
  previousIndex: any;

  selectedIndex;
  constructor() {}
  /**
   * This lifecycle hook is used to prefill the value
   */
  ngOnInit() {
    const { value } = this.customFormControl;
    if (this.radioValues && this.radioValues.length > 0) {
      this.selectedIndex = this.radioValues.findIndex(item => {
        return item.value === value;
      });
    }
  }
  /**
   * This function will accept Object and return an array of Object
   * Since the curx-radio will accept only arrays
   * @param item - Object which needs to be converted as an Array
   */
  convertObjToArray(item) {
    const arr = [];
    arr.push(item);
    return arr;
  }

  /**
   * This function calls when we select a radio button and shares the selected data with the parent
   * @param value - selected value
   * @param index - Index of the selected Object
   */
  getSelectedRadioValue(value, index, item, isClick) {
    if(this.selectedIndex !== undefined && (this.selectedIndex !== index) && item.showForm){
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].reset();
      });
      this.selectedIndex = index;
      item.formValue = this.formGroup.value;
      this.valueChanges.emit(item);
    } else {
      this.selectedIndex = index;
    }
    if (isClick) {
      this.customFormControl.setValue(item.value);
    }
    if(!item.showForm) {
      this.valueChanges.emit(item);
    }
  }

  valueChange(formGroup: FormGroup, index, item){
      if(formGroup.valid && formGroup.value !== undefined){
        item.formValue = formGroup.value
        this.valueChanges.emit(item);
      }
  }
}
