<div class="toggle-selection">
  <div class="toggle-selection_title">{{label}}</div>
  <crux-button-toggle-group class="custom-toggle" [ngClass]="{'noPointerEvent': isDisabled}">
    <crux-button-toggle [ngClass]=" isDisabled ? 'toggle-disabled': null " [disabled]="isDisabled"
      [ngClass]="claimType ? 'toggle_'+claimType : null" [checked]="toggleSelected.yes"
      [class.selectedClass]="toggleSelected.yes === true" (click)="onChangeFunction('yes')" #yesselector>{{btnList[0]}}
    </crux-button-toggle>
    <crux-button-toggle [ngClass]="isDisabled ? 'toggle-disabled': null " [disabled]="isDisabled"
      [ngClass]="claimType ? 'toggle_'+claimType : null" [checked]="toggleSelected.no"
      [class.selectedClass]="toggleSelected.no === true" (click)="onChangeFunction('no')" #noselector>
      {{btnList[1]}}</crux-button-toggle>
  </crux-button-toggle-group>
</div>