import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StudioError } from '../error-handlers/studio.error';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { WINDOW } from '../services/window.service';
import { DomainsMappingService } from '../services/domains-mapping.service';
import { BaseUrlService } from '../services/base-url.service';
import { select, Store } from '@ngrx/store';
import { StudioErrorHandler } from '../error-handlers/studio.error-handler';
import { WebClaimsAppContextService } from '../webclaims-app.content.service';

@Injectable()
export class WebAppContextGuard implements CanActivate, OnDestroy {
  private _sectionStatusSubscription: Subscription;
  _storeStatusSubscription: Subscription;
  private subscriptions: Subscription;
  selectedLanguage: any;
  isInitial = true;
  customerCountry: any;

  constructor(
    private _appContext: WebClaimsAppContextService,
    private _router: Router,
    private _domainMappingService: DomainsMappingService,
    private _bus: BaseUrlService,
    @Inject(WINDOW) private window: Window,
    private _store: Store<any>,
    private _errorHandler: StudioErrorHandler
  ) {
    this._store.subscribe(state => {
      if (state !== undefined) {
        if (state['iFrameData'] !== undefined) {
          this.customerCountry = state['iFrameData'].customerCountry;
        }
        if (state['languageSelection'] !== undefined) {
          if (state['languageSelection'].en === true) {
            if (this.customerCountry === 'ca') {
              this.selectedLanguage = 'en-CA';
            } else {
              this.selectedLanguage = 'en-US';
            }
          } else if (state['languageSelection'].es === true) {
            this.selectedLanguage = 'es-US';
          } else if (state['languageSelection'].fr === true) {
            this.selectedLanguage = 'fr-CA';
          }
        }
      }
    });

    // this._storeStatusSubscription = this._store
    //   .pipe(select('languageSelection'))
    //   .subscribe((languageSelectionObj) => {
    //     const currentURL = this._router.url;
    //     if (
    //       !this.isInitial &&
    //       languageSelectionObj &&
    //       currentURL.includes('/claims') &&
    //       !currentURL.includes('/contact-us')
    //     ) {
    //       let langStr = document.location.pathname.substr(1, 5);
    //       let langStrCurrentLang = '';
    //       for (const key in languageSelectionObj) {
    //         if (languageSelectionObj[key] === true) {
    //           langStrCurrentLang = key + langStr.slice(2, langStr.length);
    //         }
    //       }
    //       const url = this._bus.baseUri + langStr + '/claims';
    //       const urlCUrrent = this._bus.baseUri + langStrCurrentLang + '/dashboard';
    //       if (document.location.pathname.indexOf('claims') < 0) {
    //         setTimeout(() => {
    //           this._router.navigate([urlCUrrent]);
    //         }, 200);
    //       }
    //       this._router.navigate([url]);
    //     }
    //     this.isInitial = false;
    //   });
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this._appContext.get('')) {
      return of(true);
    }
    let configPath = null;

    try {
      configPath = this._getConfigPath(
        this._domainMappingService.getMapping(),
        this.window.location
      );
    } catch (e) {
      // TODO check if loading icon is required or not
    }
    if (!configPath) {
      return of(false);
    }
    return this._appContext.load(configPath).pipe(
      tap(() => {
        // TODO check if any loading icon is required or not
      }),
      map((appContext) => !!appContext),
      catchError((error) => {
        this._errorHandler.handleError(
          new StudioError(`Can't fetch Application Context.`, error)
        );
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            this._router.navigate([environment.uri.pageNotFound]);
          }
        }

        return of(false);
      })
    );
  }

  _getConfigPath(domainsMapping: any, location: Location): string {
    const domainName = location.host;
    let domainConfig = domainsMapping[domainName];
    if (!domainConfig) {
      domainConfig = domainsMapping['*'];
      if (!domainConfig) {
        throw new StudioError(
          `Can't find configuration for domain ${domainName}`
        );
      }
    }

    this._router.events.subscribe((event) => {
      if (event['urlAfterRedirects'] !== undefined) {​
        let path = event['urlAfterRedirects'].substr(1, 5);
        if(path === 'en-US'){​
           this.selectedLanguage = 'en-US';
        }​ else if (path === 'en-CA'){​
           this.selectedLanguage = 'en-CA';
        }​ else if (path === 'es-US') {​
           this.selectedLanguage = 'es-US';
        }​ else if (path === 'fr-CA'){​
           this.selectedLanguage = 'fr-CA';
        }​
      }​ 
    });

    if(this.selectedLanguage === undefined){
      const url = location.pathname.split("/");
      if(url[1] === "en-US"){
        this.selectedLanguage = 'en-US';
      } else if (url[1] === "en-CA"){
        this.selectedLanguage = 'en-CA';
      } else if (url[1] === "es-US"){
        this.selectedLanguage = 'es-US';
      } else if (url[1] === "fr-CA"){
        this.selectedLanguage = 'fr-CA';
      }
    }

    // if (!this.selectedLanguage) {
    //   const currentURL = this._router.url;
    //   this.selectedLanguage = currentURL.substr(1, 5);
    // }
    let defaultPath;
    if (this.selectedLanguage === 'en-US') {
      defaultPath = domainConfig.find((path) => path.en === true);
    } else if (this.selectedLanguage === 'es-US') {
      defaultPath = domainConfig.find((path) => path.es === true);
    } else if (this.selectedLanguage === 'fr-CA') {
      defaultPath = domainConfig.find((path) => path.fr === true);
    } else if (this.selectedLanguage === 'en-CA') {
      defaultPath = domainConfig.find((path) => path.enCA === true);
    } else {
      defaultPath = domainConfig.find((path) => path.default === true);
    }

    if (!defaultPath) {
      throw new StudioError(`Can't find default path for domain ${domainName}`);
    }

    const locationPathBaseHref = location.pathname
      .split('/')
      .slice(0, 6)
      .join('/');

    if (locationPathBaseHref === defaultPath.baseHref) {
      const routePath = location.pathname
        .split('/')
        .slice(6, location.pathname.split('/').length)
        .join('/');

      this._bus.baseUri = '';

      this._router.navigate([routePath]);
      return null;
    }

    if (location.pathname.split('/').slice(0, 6).length <= 5) {
      this._bus.baseUri = '';
      return defaultPath.baseHref;
    }

    const baseHref = domainConfig.find(
      (path) => path.baseHref === locationPathBaseHref
    );
    if (!baseHref) {
      this._router.navigate([environment.uri.pageNotFound]);
      throw new StudioError(`Can't find path ${locationPathBaseHref}`);
    }
    this._bus.baseUri = locationPathBaseHref;

    return locationPathBaseHref;
  }

  ngOnDestroy() {
    if (this._storeStatusSubscription) {
      this._storeStatusSubscription.unsubscribe();
    }
  }
}
