import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import { AppContextService } from '../app.context.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormService } from '../services/form.services';
import { AuthProviderService } from '../auth-provider-service';
import { Router } from '@angular/router';
import { CustomEventService } from '../services/custom-event.service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  authTokenVar: any;
  sessionToken: any;
  _storeStatusSubscription: Subscription;
  headerToken: any;
  pdfHeaderToken: any;
  waitFlag = false;
  iFrameData = {};

  constructor(
    private _store: Store<any>,
    private _authProviderService: AuthProviderService,
    private _router: Router,
    private _customEventService: CustomEventService
  ) {
    this._storeStatusSubscription = this._store.subscribe((state: any) => {
      this.iFrameData = state['iFrameData'];
      this.authTokenVar = state['iFrameData']
        ? state['iFrameData'].apiToken
        : '';
      this.sessionToken = state['iFrameData']
        ? state['iFrameData'].adToken
        : '';
      if (this.sessionToken !== undefined) {
        this.headerToken = {
          'Content-Type': 'application/json',
          Authorization: this.authTokenVar,
          apiVersion: environment.chubbApiVersion,
          'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
          'adToken': this.sessionToken
        };
        this.pdfHeaderToken = {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
          Authorization: this.authTokenVar,
          apiVersion: environment.chubbApiVersion,
          'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
          'adToken': this.sessionToken
        };
      } else {
        this.headerToken = {
          'Content-Type': 'application/json',
          Authorization: this.authTokenVar,
          apiVersion: environment.chubbApiVersion,
          'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey
        };
        this.pdfHeaderToken = {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
          Authorization: this.authTokenVar,
          apiVersion: environment.chubbApiVersion,
          'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey
        };
      }
    });
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let authReq = req.clone({
      setHeaders: {},
    });
    if (
      !req.headers.get('skip') &&
      !req.headers.get('Access-Control-Allow-Origin') &&
      !req.headers.get('Authorization') &&
      !req.headers.get('APIKey') &&
      !req.headers.get('App_Key')
    ) {
      if (req.headers.get('content')) {
        authReq = req.clone({
          setHeaders: this.pdfHeaderToken,
        });
      } else {
        if(req.url.toLowerCase().includes('systemdowntime.json')){
          // remove apiVersion from header
          delete this.headerToken.apiVersion
        }
        authReq = req.clone({
          setHeaders: this.headerToken,
        });
      }
    }

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (
            event.body &&
            event.body.Code === '0001' &&
            event.body.errorMatchCode === '0001'
          ) {
            this._authProviderService.setLoggedInFlg(false);
            const authProvider = this._authProviderService.getAuthProvider();
            let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
            const path = this._router.url.split("/")[1];
            if(path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA" )){
              logoutUrl = logoutUrl + path + "/login";
            } else {
              logoutUrl = logoutUrl + "en-US/login";
            }
            localStorage.removeItem("loggedIn");
            authProvider.logout(logoutUrl);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if(error && authReq && authReq.url && authReq.url.includes("newPdf")){
          //custom event
          this._customEventService.callCustomEventApi("interceptor", "failure-" + error.status, "interceptor");
        }
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          const authProvider = this._authProviderService.getAuthProvider();
          let logoutUrl = environment.msalConfig.logoutConfig.logoutRedirectUrl;
          const path = this._router.url.split("/")[1];
          if(path && path !== "" && (path === "en-US" || path === "es-US" || path === "en-CA" || path === "fr-CA" )){
            logoutUrl = logoutUrl + path + "/login";
          } else {
            logoutUrl = logoutUrl + "en-US/login";
          }
          localStorage.removeItem("loggedIn");
          authProvider.logout(logoutUrl);
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   * Adding a active token to the header
   * @param req - HTTP Request
   */
  private addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    let authReq = req.clone({
      setHeaders: {},
    });
    if (this.sessionToken !== undefined) {
      this.headerToken = {
        'Content-Type': 'application/json',
        Authorization: this.authTokenVar,
        apiVersion: environment.chubbApiVersion,
        'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey,
        'adToken': this.sessionToken
      };
    } else {
      this.headerToken = {
        'Content-Type': 'application/json',
        Authorization: this.authTokenVar,
        apiVersion: environment.chubbApiVersion,
        'Ocp-Apim-Subscription-Key': environment.ApimSubscriptionKey
      };
    }

    if (
      !req.headers.get('skip') &&
      !req.headers.get('Access-Control-Allow-Origin') &&
      !req.headers.get('APIKey') &&
      !req.headers.get('App_Key')
    ) {
      authReq = req.clone({
        setHeaders: this.headerToken,
      });
    } else {
      authReq = req.clone({
        setHeaders: { Authorization: this.authTokenVar },
      });
    }
    return authReq;
  }
}
