import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public spinnerVisiblity = new Subject<any>();
  constructor() {}

  setSpinnerVisiblity(spinner) {
    this.spinnerVisiblity.next(spinner);
  }

  getSpinnerVisiblity(): Observable<any> {
    return this.spinnerVisiblity.asObservable();
  }
}
