import { Action } from '@ngrx/store';
// import { StepWidgetsReady } from '../../widgets/st';

export enum StepWidgetsReadyActions {
  STEPWIDGET_READY_SUCCESS = '[STEPWIDGET] STEPWIDGET READY SUCCESS',
  STEPWIDGET_READY_FAILURE = '[STEPWIDGET] STEPWIDGET READY FAILURE',
  STEPWIDGET_READY_DESTROY_SUCCESS = '[STEPWIDGET] STEPWIDGET READY DESTROY SUCCESS',
  STEPWIDGET_READY_DESTROY_FAILURE = '[STEPWIDGET] STEPWIDGET READY DESTROY FAILURE',
}

export class StepWidgetsReadySuccessAction implements Action {
  readonly type: string = StepWidgetsReadyActions.STEPWIDGET_READY_SUCCESS;

  constructor(public payload: Partial<any>) {}
}

export class StepWidgetssReadyFailureAction implements Action {
  readonly type: string = StepWidgetsReadyActions.STEPWIDGET_READY_FAILURE;

  constructor(public payload: Partial<any>) {}
}

export class StepWidgetsReadyDestroySuccessAction implements Action {
  readonly type: string =
    StepWidgetsReadyActions.STEPWIDGET_READY_DESTROY_SUCCESS;

  constructor(public payload: string) {}
}

export class StepWidgetsReadyDestroyFailureAction implements Action {
  readonly type: string =
    StepWidgetsReadyActions.STEPWIDGET_READY_DESTROY_FAILURE;

  constructor(public payload: string) {}
}

export type StepWidgetsReadyActionType =
  | StepWidgetsReadySuccessAction
  | StepWidgetssReadyFailureAction
  | StepWidgetsReadyDestroySuccessAction
  | StepWidgetsReadyDestroyFailureAction;
