import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";
import moment from "moment";
import { timeout } from "rxjs/operators";
import { AuthProviderService } from "../auth-provider-service";
import { FormService } from "./form.services";

@Injectable({
    providedIn: 'root',
})
export class CustomEventService {
    sessionId: any;
    decodedToken: any;
    token: any;
    adToken: any;

    constructor(private _store: Store,
        private _authProviderService: AuthProviderService,
        private _jwtHelperService: JwtHelperService,
        private _formService: FormService) {
        this._store.subscribe((state) => {
            if (state !== undefined) {
                if (state['iFrameData'] !== undefined) {

                    if (state['iFrameData'].uniqueId !== undefined) {
                        this.sessionId = state['iFrameData'].uniqueId;
                    }
                    if (state['iFrameData']['apiToken'] !== undefined) {
                        this.token = state['iFrameData']['apiToken'];
                    }
                    if (state['iFrameData']['adToken'] !== undefined) {
                        this.adToken = state['iFrameData']['adToken'];
                    }

                }
            }
        });
    }

    callCustomEventApi(eventLog, eventName, screenName) {
        const accessToken = this._authProviderService.logginSessionData?.accessToken;
        if (accessToken !== undefined) {
            this.decodedToken = this._jwtHelperService.decodeToken(accessToken);
        }
        const payload = {
            EventLog: eventLog,
            EventName: eventName,
            EventTime: moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
            UserId: this.decodedToken?.email !== undefined ? this.decodedToken.email : '',
            SessionId: this.sessionId,
            PageName: screenName,
            BrowserName: this.getBrowser()
        }

        this._formService.ssCustomEvent(payload, this.token, this.adToken).pipe().subscribe(response => {
            //success
        }, error => {
            //error
        })
    }

    getBrowser() { 
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return 'Opera';
        }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
            return 'Chrome';
        }else if(navigator.userAgent.indexOf("Safari") != -1){
            return 'Safari';
        }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
             return 'Firefox';
        }else if((navigator.userAgent.indexOf("MSIE") != -1 )){
          return 'IE'; 
        } else {
           return '';
        }
    }

}