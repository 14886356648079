import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep, isEmpty } from 'lodash-es';
import { map, take, timeout } from 'rxjs/operators';
import { AppContextService } from '../../app.context.service';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import { id__loginForm, StudioFormBuilderConfig } from './login-new.constant';
import { FormService } from '../../services';
import { Store } from '@ngrx/store';
import {
  resetRegistrationDetails,
  saveRegistrationDeatils,
} from '../../state/actions/registration-details.action';
import { environment } from '../../../environments/environment';
import { getApiError, getServerError } from '../../widgets/shared/utilities';
import { GeneralizedPopUpComponent } from '../../widgets/shared/generalized-popup/generalized-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageSelectionSuccessAction } from '../../state/actions/language-selection.action';
import { BaseUrlService } from '../../services/base-url.service';
import { LanguageChangePopupComponent } from '../../widgets/language-change-popup/language-change-popup.component';
import { AuthProviderService } from '../../auth-provider-service';
import { CustomEventService } from '../../services/custom-event.service';
import { Adb2cState } from '@chubb-auth/types';
@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html',
  styleUrls: ['./login-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewLoginComponent {
  config: any;
  formBuilderConfig: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  formGroup = new FormGroup({});
  registrationDetailsObject = {};
  serverError: any;
  somethingWentWrongError: any;
  apiToken: any;
  sessionToken: any;
  appConfig: any;
  dialogRef: any;
  footerConfig: any;
  navbarConfig: any;
  _opened = false;
  menuListMobile = [];
  language: any;
  systemDownData: any;
  systemDownFlag: boolean = false;
  systemDownText: any;
  isDisabled: boolean = false;
  loginImage: any;
  constructor(
    private _router: Router,
    private _bus: BaseUrlService,
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _service: FormService,
    private _store: Store<any>,
    private dialog: MatDialog,
    private _authProviderService: AuthProviderService,
    private _customEventService: CustomEventService
  ) {
    this.navbarConfig = this._appContext.get('navbar');
    this.config = this._appContext.get('pages.login');
    this.serverError = this._appContext.get('serverError');
    this.footerConfig = this._appContext.get('footer');
    this.language = this._appContext.get('language.route');
    this.menuListMobile = this._appContext.get(
      'pages.registrationNavbar.menuListMobile.default'
    );
    this.somethingWentWrongError = this._appContext.get('somethingWentWrong');

    this._store.dispatch(resetRegistrationDetails());
    this.systemDownText = this._appContext.get('systemDownText');
    if (!this._loadingService.isLoadingIndicatorOpen()) {
      this._loadingService.openLoadingIndicator({});
    }
    this._service.getSystemDownTimeJson().subscribe(
      (response) => {
        this.systemDownData = response;
        if (
          this.systemDownData &&
          this.systemDownData.module &&
          this.systemDownData.module.main &&
          this.systemDownData.module.main.msg
        ) {
          this.systemDownFlag = true;
          this._loadingService.closeLoadingIndicator();
        } else {
          this.checkIfLoggedIn();
        }
      },
      (error) => {
        this._loadingService.closeLoadingIndicator();
        this.checkIfLoggedIn();
      }
    );
  }

  checkIfLoggedIn() {
    const authProvider = this._authProviderService.getAuthProvider();
    authProvider.getAccessToken().subscribe((state: Adb2cState) => {
      if (state.accessToken && state.isLoggedIn) {
        if (state && state.isLoggedIn) {
          const path = this._router.url.split('/')[1];
          this._router.navigate([path + '/dashboard']);
        } else {
          this._loadingService.closeLoadingIndicator();
        }
      } else {
        this._loadingService.closeLoadingIndicator();
      }
    });
  }

  ngOnInit() {
    this.retrieveFormBuilderConfig();
    this.isDisabled = true;
    this.registrationDetailsObject['redirectFlag'] = true;
    this._store.dispatch(
      saveRegistrationDeatils({
        payload: this.registrationDetailsObject,
      })
    );
    let url = window.location.href;
    if (url.includes('&isMobileApp=true')) {
      document.body.classList.add('isMobileApp');
    } else {
      document.body.classList.remove('isMobileApp');
    }
    // Code for Displaying Random Images
    let imageArray = [];
    imageArray[0] = '/assets/images/Hero-1_Image.png';
    imageArray[1] = '/assets/images/Hero-2_Image.png';
    imageArray[2] = '/assets/images/Hero-3_Image.png';
    imageArray[3] = '/assets/images/Hero-4_Image.png';

    this.getRandomImage(imageArray, "");
  }

  getRandomImage(imgAr, path) {
    // path = path || 'http://fpoimg.com/'; // default path here
    let num = Math.floor( Math.random() * imgAr.length );
    console.log(num)
    this.loginImage = imgAr[ num ];
    console.log(this.loginImage)
    // var imgStr = '<img src="' + path + img + '" alt = "">';
    // document.write(imgStr); document.close();
  }
  retrieveFormBuilderConfig() {
    if (this.config !== undefined) {
      this.formBuilderConfig = cloneDeep(
        id__loginForm(this.config['formConfig'])
      );
    }
  }

  valueChange(formGroup: FormGroup) {
    if (
      this.formGroup.valid &&
      (this.formGroup.controls['email'].dirty ||
        !this.formGroup.controls['email'].errors === null)
    ) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  OnSubmit() {
    if (this.formGroup.valid) {
      this.isDisabled = false;
      this.formGroup.value['email'] = this.formGroup.value['email'].trim();
      this.userProfileTokenApi(this.formGroup.value);
    } else {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        this.formGroup.controls[controlName].markAsTouched();
        this.formGroup.controls[controlName].markAsDirty();
      });
      this.isDisabled = true;
    }
  }

  callUserProfuleApi(formValue, token) {
    const payload = {
      email: formValue.email,
      template:
        environment.msalConfig.userProfileTokenConfig.resetPasswordTemplate,
      language: 'en',
      subjectLine: this.config['resetPasswordSubjectLine'],
    };
    if (this._router.url.includes('es-US')) {
      payload.template = payload.template.replace('/en/', '/es/');
      payload.language = 'es';
    } else if (this._router.url.includes('fr-CA')) {
      payload.template = payload.template.replace('/en/', '/fr/');
      payload.language = 'fr';
    } else if (this._router.url.includes('en-CA')) {
      payload.template = payload.template.replace('/en/', '/en-CA/');
      payload.language = 'en-CA';
    }
    // this._loadingService.openLoadingIndicator({});
    this._service.ssUserProfileCheck(payload, token).subscribe(
      (response) => {
        const path = this._router.url.split('/')[1];
        this.registrationDetailsObject['userProfile'] = response;
        this.registrationDetailsObject['login_params'] = {
          login_hint: formValue.email,
          ui_locales: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
          language: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
          app_locale: path,
          forgot_password: 'true',
        };
        this._store.dispatch(
          saveRegistrationDeatils({
            payload: this.registrationDetailsObject,
          })
        );
        if (response['value']['requiresMigration']?.toLowerCase() === 'true') {
          this._service.ssSendResetPasswordEmail(payload, token).subscribe(
            (response) => {
              if (response && response['Code'] === 0) {
                this._loadingService.closeLoadingIndicator();
                this._router.navigate([this.config['migrationTrueRoute']]);
              } else {
                this._loadingService.closeLoadingIndicator();
                this.dialog.open(GeneralizedPopUpComponent, this.serverError);
              }
            },
            (error) => {
              this._loadingService.closeLoadingIndicator();
              this.dialog.open(GeneralizedPopUpComponent, this.serverError);
            }
          );
        } else {
          this.registrationTokenAPI();
        }
      },
      (error) => {
        this._loadingService.closeLoadingIndicator();
        this.dialog.open(GeneralizedPopUpComponent, this.serverError);
      }
    );
  }

  registrationTokenAPI() {
    this._service
      .ssRegistrationTokenAPI()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            let authTokenVal =
              response.token_type + ' ' + response.access_token;
            this.registrationTokenBuilderAPI(authTokenVal);
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const authProvider = this._authProviderService.getLoginProvider();
          let extraQueryParameters = this.registrationDetailsObject[
            'login_params'
          ];
          localStorage.setItem('language', this._router.url.split('/')[1]);
          authProvider.login(extraQueryParameters);
        }
      );
  }
  registrationTokenBuilderAPI(token) {
    let regTokeBuilderPayload = {
      ClientId: environment.registrationTokenBuilderConfig.clientId,
      Claim1Name: 'clientID',
      Claim1Value: environment.registrationTokenBuilderConfig.clientId,
      Claim2Name: 'subjectline',
      Claim2Value: this.config['forgotPasswordSubjectLine'],
    };
    this._service
      .ssRegistrationTokenBuilderAPI(token, regTokeBuilderPayload)
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.TokenData !== undefined &&
            response.TokenData !== ''
          ) {
            this._loadingService.closeLoadingIndicator();
            const authProvider = this._authProviderService.getLoginProvider();
            this.registrationDetailsObject['login_params']['id_token_hint'] =
              response.TokenData;
            let extraQueryParameters = this.registrationDetailsObject[
              'login_params'
            ];
            localStorage.setItem('language', this._router.url.split('/')[1]);
            authProvider.login(extraQueryParameters);
          } else {
            this._loadingService.closeLoadingIndicator();
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const authProvider = this._authProviderService.getLoginProvider();
          let extraQueryParameters = this.registrationDetailsObject[
            'login_params'
          ];
          localStorage.setItem('language', this._router.url.split('/')[1]);
          authProvider.login(extraQueryParameters);
        }
      );
  }

  onClickSignUp(form_id) {
    // window.location.href = this.config.signUpRoute + '?id=' + form_id
    this._router.navigate([this.config.signUpRoute], {
      queryParams: { id: form_id },
    });
  }

  userProfileTokenApi(formValue) {
    this._loadingService.openLoadingIndicator({});
    this._service
      .userProfileTokenAPI()
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.apiToken = response.token_type + ' ' + response.access_token;
            this.sessionToken = response.access_token;
            this.callUserProfuleApi(formValue, this.apiToken);
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          this.dialog.open(GeneralizedPopUpComponent, this.serverError);
        }
      );
  }

  onCountrySelection() {
    this.dialogRef = this.dialog.open(LanguageChangePopupComponent, {
      height: 'auto',
      data: {},
      disableClose: true,
    });
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }
  _toggleSidebarClose() {
    this._opened = false;
  }
  onRedirect(item) {
    this._opened = !this._opened;
    let url = this._router.url;
    const countryRoute = url.split('/')[1];
    window.scroll(0, 0);
    this._router.navigateByUrl(url.replace(countryRoute, item.path));
  }
}
