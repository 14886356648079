import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { AppContextService } from '../app.context.service';
import { catchError, map, tap } from 'rxjs/operators';
import { StudioError } from '../error-handlers/studio.error';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { WINDOW } from '../services/window.service';
import { DomainsMappingService } from '../services/domains-mapping.service';
import { BaseUrlService } from '../services/base-url.service';
import { Store } from '@ngrx/store';
import { StudioErrorHandler } from '../error-handlers/studio.error-handler';
import { LanguageSelectionSuccessAction } from '../state/actions';

@Injectable()
export class AppContextGuard implements CanActivate {
  private _sectionStatusSubscription: Subscription;
  private subscriptions: {
    [key: string]: Subscription;
  } = {};
  selectedLanguage: any;
  customerCountry: any;

  constructor(
    private _appContext: AppContextService,
    private _router: Router,
    private _domainMappingService: DomainsMappingService,
    private _bus: BaseUrlService,
    @Inject(WINDOW) private window: Window,
    private _store: Store<any>,
    private _errorHandler: StudioErrorHandler
  ) {
    this._store.subscribe(state => {
      if (state !== undefined) {
        if (state['iFrameData'] !== undefined) {
          this.customerCountry = state['iFrameData'].customerCountry;
        }
        if (state['languageSelection'] !== undefined) {
          if (state['languageSelection'].en === true) {
            if (this.customerCountry === 'ca') {
              this.selectedLanguage = 'en-CA';
            } else if (this.customerCountry === 'USA') {
              this.selectedLanguage = 'en-US';
            }
          } else if (state['languageSelection'].es === true) {
            this.selectedLanguage = 'es-US';
          } else if (state['languageSelection'].fr === true) {
            this.selectedLanguage = 'fr-CA';
          }
        }
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this._appContext.get('')) {
      return of(true);
    }
    let configPath = null;

    try {
      configPath = this._getConfigPath(
        this._domainMappingService.getMapping(),
        this.window.location
      );
    } catch (e) {
      // TODO check if loading icon is required or not
    }
    if (!configPath) {
      return of(false);
    }
    return this._appContext.load(configPath).pipe(
      tap(() => {
        // TODO check if any loading icon is required or not
      }),
      map((appContext) => !!appContext),
      catchError((error) => {
        this._errorHandler.handleError(
          new StudioError(`Can't fetch Application Context.`, error)
        );
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            this._router.navigate([environment.uri.pageNotFound]);
          }
        }

        return of(false);
      })
    );
  }

  _getConfigPath(domainsMapping: any, location: Location): string {
    const domainName = location.host;
    let domainConfig = domainsMapping[domainName];
    if (!domainConfig) {
      domainConfig = domainsMapping['*'];
      if (!domainConfig) {
        throw new StudioError(
          `Can't find configuration for domain ${domainName}`
        );
      }
    }
    this._router.events.subscribe((event) => {
      if (event['urlAfterRedirects'] !== undefined) {​
        let path = event['urlAfterRedirects'].substr(1, 5);
        if(path === 'en-US'){​
           this.selectedLanguage = 'en-US';
        }​ else if (path === 'en-CA'){​
           this.selectedLanguage = 'en-CA';
        }​ else if (path === 'es-US') {​
           this.selectedLanguage = 'es-US';
        }​ else if (path === 'fr-CA'){​
           this.selectedLanguage = 'fr-CA';
        }​
      }​ 
    });

    if(this.selectedLanguage === undefined){
      const url = location.pathname.split("/");
      if(url[1] === "en-US"){
        this.selectedLanguage = 'en-US';
      } else if (url[1] === "en-CA"){
        this.selectedLanguage = 'en-CA';
      } else if (url[1] === "es-US"){
        this.selectedLanguage = 'es-US';
      } else if (url[1] === "fr-CA"){
        this.selectedLanguage = 'fr-CA';
      }
    }
    
    let defaultPath;
    if (this.selectedLanguage === 'en-US') {
      defaultPath = domainConfig.find((path) => path.en === true);
    } else if (this.selectedLanguage === 'es-US') {
      defaultPath = domainConfig.find((path) => path.es === true);
    } else if (this.selectedLanguage === 'fr-CA') {
      defaultPath = domainConfig.find((path) => path.fr === true);
    } else if (this.selectedLanguage === 'en-CA') {
      defaultPath = domainConfig.find((path) => path.enCA === true);
    } else {
      defaultPath = domainConfig.find((path) => path.default === true);
    }

    if (!defaultPath) {
      throw new StudioError(`Can't find default path for domain ${domainName}`);
    }

    const locationPathBaseHref = location.pathname
      .split('/')
      .slice(0, 6)
      .join('/');

    if (locationPathBaseHref === defaultPath.baseHref) {
      const routePath = location.pathname
        .split('/')
        .slice(6, location.pathname.split('/').length)
        .join('/');

      this._bus.baseUri = '';

      this._router.navigate([routePath]);
      return null;
    }

    if (location.pathname.split('/').slice(0, 6).length <= 5) {
      this._bus.baseUri = '';
      return defaultPath.baseHref;
    }

    const baseHref = domainConfig.find(
      (path) => path.baseHref === locationPathBaseHref
    );
    if (!baseHref) {
      this._router.navigate([environment.uri.pageNotFound]);
      throw new StudioError(`Can't find path ${locationPathBaseHref}`);
    }
    this._bus.baseUri = locationPathBaseHref;

    return locationPathBaseHref;
  }
}
