<div class="footerMainContainer_footer">
    <!--  get in touch section -->
    <div class="footer_container__getInTouchSection" *ngIf="getInTouchSection.showGetInTouchSection">
        <div class="getInTouchBackground">
            <div class="footer_container__getInTouchSection__leftSection">
                <div class="headingContainer"><span class="headingText">{{getInTouchSection.headingText}}</span></div>
            </div>
            <div class="footer_container__getInTouchSection__rightSection">
                <div class="rightList" *ngFor="let list of getInTouchSection.rightSectionList;let i = index">
                    <div class="rightListContainer">
                        <div class="leftListImg">
                            <img src="{{list.img}}" alt="{{list.alt}}" class="{{'rightListImg'+i}}" />
                        </div>
                        <div class="rightTextContainer">
                            <span (click)="goToSupportPage()" *ngIf="list.id === '1'" class="text rightText1 link">{{list.text1}}</span>
                            <span *ngIf="list.id !== '1'" class="text rightText1">{{list.text1}}</span>
                            <!-- <span (click)="goToSupportPage()" class="text rightText1 link">{{getInTouchSection.sendMessageText}}</span> -->
                            <span *ngIf="list.id === '1'" class="text rightText2"
                                [ngClass]="{'addCursor': (list.link !== '#' && list.link !== '')}"
                                (click)="goToSupportPage()" appSharedNew tabindex="0" role="button">{{list.text2}}</span> 
                                <span *ngIf="list.id !== '1'" class="text rightText2"
                                [ngClass]="{'addCursor': (list.link !== '#' && list.link !== '')}"
                                (click)="navigateTo(list.link)" appSharedNew tabindex="0" role="button">{{list.text2}}</span>                       
                            </div>
                        <div class="rightTextContainer" *ngIf="list.text3 && list.text4"
                            [ngClass]="{canadaContactDetails: list.text3}">
                            <span class="text rightText1">{{list.text3}}</span>
                            <span class="text rightText2"
                                [ngClass]="{'addCursor': (list.link !== '#' && list.link !== '')}"
                                (click)="navigateTo(list.link)">{{list.text4}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end of get in touch section -->
    <div class="footer_container">
        <div class="footer_container__mainDiv">
            <div class="footer_container__mainDiv__logo">
                <div class="logoDiv">
                    <div  class="logo logo-left">
                        <img src="{{footerConfig.logoURL}}" alt="{{footerConfig.logoAltText}}" />
                    </div>
                    <!-- <div *ngIf = "isCwbUser && !isRegistrationPage" class="logo logo-left">
                        <img src="./assets/images/chubbLogoWhite.png" alt="{{footerConfig.logoAltText}}" />
                    </div> -->
                    <!-- <div *ngIf = "isRegistrationPage" class="logo logo-left">
                        <img src="./assets/images/fullLogoWhite.png" alt="{{footerConfig.logoAltText}}" />
                    </div> -->
                    <div *ngIf="footerConfig.languagePopUp.isShow">
                        <div class="logo logo-right" [ngClass]="{'noPointerEvent': isCountryBtnDisabled}">
                            <span class="country-selector" (click)="onCountrySelection()" appSharedNew tabindex="0" role="button">
                                <span class="spanLabel">{{languageConfig.languagePopUp.label}}</span>
                                <span class="spanImage"><img class="verticalImage" src="{{footerConfig.languagePopUp.selectorIcon}}" alt="{{footerConfig.languagePopUp.altSelectorIcon}}" /></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_container__mainDiv__content">{{contentSection.mainDiv}}
                <div class="footer_container__mainDiv__subcontent" style="margin: 0">{{contentSection.footerSubText}}</div>
            </div>
            <div class="footer_container__mainDiv__link">
                <span *ngFor="let link of footerConfig.footerLinkList">
                    <a (mousedown)="$event.preventDefault()" target="_blank" href="{{link.path}}">{{link.name}}</a>
                </span>
            </div>
        </div>
    </div>
</div>