<div class="overlay">
    <div class="popup">
        <h2>
            <label class="popup-img"><img src="../../../../../assets/images/PNG/OC_Combined_Claims_portal_assets_form_info.png"></label>
            <label  class="text-label">{{autoPayConfig.autopopup.billingDayLabel}}</label>
        </h2>
        <p class="text-label-data">{{autoPayConfig.autopopup.para1}}</p>
          <p class="text-label-data"> {{autoPayConfig.autopopup.para2}} </p>
            <div class="popup-action-btn" >
                <span (click)="onClose()">{{autoPayConfig.autopopup.close}}</span>
            </div>
        </div>
</div>
