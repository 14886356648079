import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { StudioFormBuilderConfig } from 'src/app/pages/user-info-success/user-info-success.constant';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppContextService } from 'src/app/app.context.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { FormService } from 'src/app/services/form.services';
import { environment } from '../../../../environments/environment';
import { take, timeout } from 'rxjs/operators';
import { GeneralizedPopUpComponent } from '../generalized-popup/generalized-popup.component';
import { cloneDeep } from 'lodash';
import { formatApiError } from '../utilities';
import { AuthProviderService } from 'src/app/auth-provider-service';
import { Subscription } from 'rxjs';
import { showOtpInRegisterationPage } from '../../../state/actions/registration-details.action';
import { RegistrationSuccessInfoPopupComponent } from '../registration-success-info-popup/registration-success-info-popup.component';

@Component({
  selector: 'app-otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss'],
})
export class OtpPopupComponent implements OnInit {
  formGroup = new FormGroup({});
  formBuilderConfig: StudioFormBuilderConfig | StudioFormBuilderConfig[];
  otp: any;
  selectedOption: any;
  resendCount: number;
  config: any;
  ecsrConfig: any;
  registrationConfig: any;
  registrationLinkSuccessConfig: any;
  authTokenValue: any;
  sessionToken: any;
  serverError: any;
  submitCount: number;
  page: any;
  registrationDetailsObject: any;
  authProvider: any;
  incorrectCode: boolean;
  validationMessage: boolean;
  shouldCallGenerateFourDigitOtpEmail: boolean;
  storeSub: Subscription;
  registrationDetailsAlternateObject: any;
  userEmailAddress: string;
  userRegistrationAttemptId: string;
  userRegistrationPin: string;
  apiToken: string;

  constructor(
    public dialogRef: MatDialogRef<OtpPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OtpPopupComponent,
    private _router: Router,
    private _appContext: AppContextService,
    private _loadingService: LoadingIndicatorService,
    private _service: FormService,
    private _dialog: MatDialog,
    private _store: Store<any>,
    private _authProviderService: AuthProviderService
  ) {
    this.serverError = this._appContext.get('serverError');
    this.config = this._appContext.get('pages.userInfoSuccess');
    this.ecsrConfig = this._appContext.get('pages.userInfoEcsrSuccess');
    this.registrationConfig = this._appContext.get(
      'registrationInfoSuccessPopUp'
    );
    this.registrationLinkSuccessConfig = this._appContext.get(
      'registrationInfoLinkEmailSuccessPopUp'
    );
    this.resendCount = 0;
    this.submitCount = 0;
    this.shouldCallGenerateFourDigitOtpEmail = false;
    this.storeSub = this._store.subscribe((state) => {
      if (state) {
        this.registrationDetailsAlternateObject = cloneDeep(
          state['registrationDetails']
        );
        this.userEmailAddress = this.registrationDetailsAlternateObject
          .findCustomerResponse.Email
          ? this.registrationDetailsAlternateObject.findCustomerResponse.Email
          : '';
        this.userRegistrationAttemptId = this.registrationDetailsAlternateObject
          .findCustomerResponse.RegistrationAttemptId
          ? this.registrationDetailsAlternateObject.findCustomerResponse
              .RegistrationAttemptId
          : '';
        this.userRegistrationPin = this.registrationDetailsAlternateObject
          .findCustomerResponse.RegistrationPin
          ? this.registrationDetailsAlternateObject.findCustomerResponse
              .RegistrationPin
          : '';
        if (state.iFrameData.apiToken !== undefined) {
          this.apiToken = state.iFrameData.apiToken;
        }
      }
    });
  }

  ngOnInit(): void {
    this.selectedOption = this.data?.selectedOption;
    this.page = this.data?.page;
    this.registrationDetailsObject = this.data?.registrationDetailsObject;
    if (this._router.url.includes('ecsr')) {
      this.shouldCallGenerateFourDigitOtpEmail = true;
    }
  }
  valueChange(formGroup: FormGroup) {}

  onKeyPress(e) {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  sendOTP() {
    if (
      this.selectedOption.formValue.otpMethod !== null &&
      this.selectedOption.formValue.otpMethod !== undefined
    ) {
      if (this.authTokenValue !== undefined) {
        this.callVerificationRequest(this.authTokenValue);
      } else {
        this.callVerificationRequest(this.twilioTokenApi());
      }
    }
  }
  twilioTokenApi() {
    this._loadingService.closeLoadingIndicator();
    this._loadingService.openLoadingIndicator({});
    this._service
      .twilioTokenApi()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            this.authTokenValue =
              response.token_type + ' ' + response.access_token;
            this.sessionToken = response.access_token;
            this._loadingService.closeLoadingIndicator();
            return this.authTokenValue;
          }
        },
        (error) => {
          this._dialog.open(GeneralizedPopUpComponent, this.serverError);
        }
      );
  }

  callVerificationRequest(token) {
    if (this.data.page == 'successInfo') {
      const otpMethod = this.selectedOption.formValue.otpMethod;
      const ofType = this.selectedOption.type;
      const value = this.selectedOption.value;

      let payload = {};
      if (otpMethod !== null && otpMethod !== undefined) {
        if (ofType && ofType === 'email') {
          payload['emailAddress'] = value;
          payload['channel'] = ofType;
        } else {
          payload['channel'] =
            otpMethod === 'Text' ||
            otpMethod === 'Texto' ||
            otpMethod === 'Texte'
              ? 'sms'
              : 'call';
          payload['phoneNumber'] = value;
        }
      }
      if (this._router.url.includes('en-US')) {
        payload['language'] = 'en';
      } else if (this._router.url.includes('es-US')) {
        payload['language'] = 'es';
      } else if (this._router.url.includes('en-CA')) {
        payload['language'] = 'en-GB';
      } else {
        payload['language'] = 'fr';
      }
      if (this.resendCount < environment.otpValidationAttempts) {
        // this._loadingService.openLoadingIndicator({});
        this._service
          .ssVerificationRequest(payload, token)
          .pipe(timeout(120000))
          .subscribe(
            (response) => {
              if (response.status !== 200) {
                this._loadingService.closeLoadingIndicator();
              } else {
                this._loadingService.closeLoadingIndicator();
                this.resendCount = this.resendCount + 1;
              }
            },
            (error) => {
              if (
                error?.['error']?.['message'] ===
                'Unknown error. Provider returned : TooManyRequests'
              ) {
                const popUpData = this.config['maxAttemptPopUp'];
                const popUp = this._dialog.open(
                  GeneralizedPopUpComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  this.closeDialog();
                  this._loadingService.closeLoadingIndicator();
                  this._router.navigate([this.config['clickHereLink']]);
                });
              }
            }
          );
      } else {
        const popUpData = this.config['maxAttemptPopUp'];
        const popUp = this._dialog.open(GeneralizedPopUpComponent, popUpData);
        popUp.afterClosed().subscribe((event) => {
          this._loadingService.closeLoadingIndicator();
          this._router.navigate([this.config['clickHereLink']]);
        });
      }
    } else if (this.data.page == 'setUserId') {
      const payload = {
        emailAddress: this.selectedOption.value,
        channel: 'email',
      };
      if (this._router.url.includes('en-US')) {
        payload['language'] = 'en';
      } else if (this._router.url.includes('es-US')) {
        payload['language'] = 'es';
      } else if (this._router.url.includes('en-CA')) {
        payload['language'] = 'en-GB';
      } else {
        payload['language'] = 'fr';
      }
      if (this.resendCount < environment.otpValidationAttempts) {
        this._service
          .ssVerificationRequest(payload, this.authTokenValue)
          .pipe(timeout(120000))
          .subscribe(
            (response) => {
              if (response.status !== 200) {
                this._loadingService.closeLoadingIndicator();
              } else {
                this.resendCount++;
                // this.formGroup.controls["otp"]?.reset();
                this._loadingService.closeLoadingIndicator();
              }
            },
            (error) => {
              if (
                error?.['error']?.['message'] ===
                'Unknown error. Provider returned : TooManyRequests'
              ) {
                const popUpData = this.config['maxAttemptPopUp'];
                const popUp = this._dialog.open(
                  GeneralizedPopUpComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  this._loadingService.closeLoadingIndicator();
                  this._router.navigate([this.config['policyRoute']]);
                });
              } else {
                this._loadingService.closeLoadingIndicator();
                const popUpData = cloneDeep(this.serverError);
                const errorResponse = formatApiError(error);
                popUpData['data']['errorResponse'] = errorResponse;
                this._dialog.open(GeneralizedPopUpComponent, popUpData);
              }
            }
          );
      } else {
        const popUpData = this.config['maxAttemptPopUp'];
        const popUp = this._dialog.open(GeneralizedPopUpComponent, popUpData);
        popUp.afterClosed().subscribe((event) => {
          this._loadingService.closeLoadingIndicator();
          this._router.navigate([this.config['policyRoute']]);
        });
      }
    }
    // const otpMethod = this.selectedOption.formValue.otpMethod;
    // const ofType = this.selectedOption.type;
    // const value = this.selectedOption.value;

    // let payload = {};
    // if(otpMethod !== null && otpMethod !== undefined){
    //   if(ofType && ofType === "email"){
    //     payload["emailAddress"] = value;
    //     payload["channel"] = ofType;
    //   } else {
    //     payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
    //     payload["phoneNumber"] = value;
    //   }
    // }
    // if(this._router.url.includes("en-US")){
    //   payload["language"] = "en"
    // } else if(this._router.url.includes("es-US")) {
    //   payload["language"] = "es"
    // } else if (this._router.url.includes("en-CA")) {
    //   payload["language"] = "en-GB"
    // } else {
    //   payload["language"] = "fr"
    // }
    // console.log(this.resendCount < environment.otpValidationAttempts);
    // if(this.resendCount < environment.otpValidationAttempts){
    //   this._loadingService.openLoadingIndicator({});
    //   this._service.ssVerificationRequest(payload, token)
    //   .pipe(timeout(120000)).subscribe(response =>{
    //     console.log(response);
    //     if(response.status !== 200){
    //       console.log('in sucess response');
    //       this._loadingService.closeLoadingIndicator();
    //     } else {
    //       this._loadingService.closeLoadingIndicator();
    //       console.log('in sucess else')
    //       this.resendCount = this.resendCount +1;
    //     }
    //   }, error =>{
    //     if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
    //       const popUpData = this.config['maxAttemptPopUp'];
    //       const popUp = this._dialog.open(
    //         GeneralizedPopUpComponent,
    //         popUpData
    //       );
    //       console.log('in error')
    //       popUp.afterClosed().subscribe((event) => {
    //         this._loadingService.closeLoadingIndicator();
    //         this.dialogRef.close();
    //         if(this.page == 'successUserInfo')
    //         this._router.navigate([this.config["clickHereLink"]]);
    //         if(this.page == 'setUserId')
    //         this._router.navigate([this.config["clickHereLink"]]);
    //       });
    //     }
    //     else {
    //       if(this.page == 'setUserId'){
    //         this._loadingService.closeLoadingIndicator();
    //       const popUpData = cloneDeep(this.serverError);
    //       const errorResponse = formatApiError(error);
    //       popUpData['data']['errorResponse'] = errorResponse;
    //       this._dialog.open(GeneralizedPopUpComponent, popUpData);
    //       }
    //     }
    //   });
    // } else {
    //   console.log('inerror else')
    //   const popUpData = this.config['maxAttemptPopUp'];
    //   const popUp = this._dialog.open(
    //     GeneralizedPopUpComponent,
    //     popUpData
    //   );
    //   popUp.afterClosed().subscribe((event) => {
    //     this._loadingService.closeLoadingIndicator();
    //     this.dialogRef.close();
    //     this._router.navigate([this.config["clickHereLink"]]);
    //   });
    // }
  }
  onBlurOtp() {
    if (this.otp == undefined || this.otp == null) {
      this.validationMessage = true;
      this.incorrectCode = false;
    }
    if (this.otp?.length !== 6) {
      this.validationMessage = true;
      this.incorrectCode = false;
    } else {
      this.validationMessage = false;
    }
  }
  callVerificiationCheck(otp) {
    if (this.data.page == 'successInfo') {
      if (otp && this.selectedOption) {
        const otpMethod = this.selectedOption.formValue.otpMethod;
        const ofType = this.selectedOption.type;
        const value = this.selectedOption.value;
        let payload = {};
        payload['code'] = otp;
        if (otpMethod !== null && otpMethod !== undefined) {
          if (ofType && ofType === 'email') {
            payload['emailAddress'] = value;
            payload['channel'] = ofType;
          } else {
            payload['channel'] =
              otpMethod === 'Text' ||
              otpMethod === 'Texto' ||
              otpMethod === 'Texte'
                ? 'sms'
                : 'call';
            payload['phoneNumber'] = value;
          }
        }
        this._loadingService.openLoadingIndicator({});
        this._service
          .ssVerificationCheck(payload, this.authTokenValue)
          .pipe(timeout(120000))
          .subscribe(
            (response) => {
              if (
                this.userEmailAddress &&
                this.shouldCallGenerateFourDigitOtpEmail
              ) {
                this.sendEmailForQuickOTPRegistration();
              } else {
                this._loadingService.closeLoadingIndicator();
                this.closeDialog();
                const popUpData = this.registrationConfig;
                const popUp = this._dialog.open(
                  RegistrationSuccessInfoPopupComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  this._router.navigate([this.config.nextRoute]);
                });
                //this._router.navigate([this.config.nextRoute]);
              }
            },
            (error) => {
              // this.dialogRef.close();
              // this._router.navigate([this.config.nextRoute]);
              if (
                error?.['error']?.['message'].toLowerCase() === 'incorrect code'
              ) {
                this.submitCount++;
                this.incorrectCode = true;
                // this.formGroup.controls["otp"].setErrors({
                //   invalid: true
                // });
                if (this.submitCount === environment.otpValidationAttempts) {
                  const popUpData = this.config['maxAttemptPopUp'];
                  const popUp = this._dialog.open(
                    GeneralizedPopUpComponent,
                    popUpData
                  );
                  popUp.afterClosed().subscribe((event) => {
                    this.closeDialog();
                    this._loadingService.closeLoadingIndicator();
                    this._router.navigate([this.config['clickHereLink']]);
                  });
                } else {
                  this._loadingService.closeLoadingIndicator();
                }
              } else if (
                error?.['error']?.['message'].toLowerCase() ===
                'no pending verification requests'
              ) {
                // this.showResendText = true;
                this._loadingService.closeLoadingIndicator();
              } else if (
                error?.['error']?.['message'] ===
                'Unknown error. Provider returned : TooManyRequests'
              ) {
                const popUpData = this.config['maxAttemptPopUp'];
                const popUp = this._dialog.open(
                  GeneralizedPopUpComponent,
                  popUpData
                );
                popUp.afterClosed().subscribe((event) => {
                  this.closeDialog();
                  this._loadingService.closeLoadingIndicator();
                  this._router.navigate([this.config['clickHereLink']]);
                });
              }
            }
          );
      }
    } else if (this.data.page == 'setUserId') {
      if (otp) {
        let payload = {};
        payload['code'] = otp;
        payload['channel'] = 'email';
        if (this.selectedOption?.value) {
          payload['emailAddress'] = this.selectedOption.value;
          this._loadingService.openLoadingIndicator({});
          this._service
            .ssVerificationCheck(payload, this.authTokenValue)
            .pipe(timeout(120000))
            .subscribe(
              (response) => {
                this._loadingService.closeLoadingIndicator();
                this.registrationTokenAPI();
              },
              (error) => {
                if (
                  error?.['error']?.['message'].toLowerCase() ===
                  'incorrect code'
                ) {
                  this.submitCount++;
                  this.incorrectCode = true;
                  // this.formGroup.controls["otp"].setErrors({
                  //   invalid: true
                  // });
                  if (this.submitCount === environment.otpValidationAttempts) {
                    const popUpData = this.config['maxAttemptPopUp'];
                    const popUp = this._dialog.open(
                      GeneralizedPopUpComponent,
                      popUpData
                    );
                    popUp.afterClosed().subscribe((event) => {
                      this._loadingService.closeLoadingIndicator();
                      this._router.navigate([this.config['policyRoute']]);
                    });
                  } else {
                    this._loadingService.closeLoadingIndicator();
                  }
                } else if (
                  error?.['error']?.['message'].toLowerCase() ===
                  'no pending verification requests'
                ) {
                  this._loadingService.closeLoadingIndicator();
                } else if (
                  error?.['error']?.['message'] ===
                  'Unknown error. Provider returned : TooManyRequests'
                ) {
                  const popUpData = this.config['maxAttemptPopUp'];
                  const popUp = this._dialog.open(
                    GeneralizedPopUpComponent,
                    popUpData
                  );
                  popUp.afterClosed().subscribe((event) => {
                    this._loadingService.closeLoadingIndicator();
                    this._router.navigate([this.config['policyRoute']]);
                  });
                } else {
                  this._loadingService.closeLoadingIndicator();
                }
              }
            );
        }
      }
    }
    // if(otp && this.selectedOption){
    //   const otpMethod = this.selectedOption.formValue.otpMethod;
    //   const ofType = this.selectedOption.type;
    //   const value = this.selectedOption.value;
    //   let payload = {};
    //   payload["code"] = otp;
    //   if(otpMethod !== null && otpMethod !== undefined){
    //     if(ofType && ofType === "email"){
    //       payload["emailAddress"] = value;
    //       payload["channel"] = ofType;
    //     } else {
    //       payload["channel"] = otpMethod === "Text" || otpMethod === "Texto" || otpMethod === "Texte" ? 'sms' : 'call';
    //       payload["phoneNumber"] = value;
    //     }
    //   }
    //   this._loadingService.openLoadingIndicator({});
    //   this._service.ssVerificationCheck(payload, this.authTokenValue).pipe(timeout(120000)).subscribe( response =>{
    //     this._loadingService.closeLoadingIndicator();
    //     if(this.page == 'successInfo')
    //     this._router.navigate([this.config.nextRoute]);
    //     else if(this.page == 'setUserId')
    //     this.registrationTokenAPI();
    //     }, error =>{
    //       if(this.page === 'successInfo'){
    //         this.dialogRef.close();
    //         this._router.navigate([this.config["clickHereLink"]]);
    //       }
    //       if(error?.['error']?.['message'].toLowerCase() === "incorrect code") {
    //       this.submitCount++;
    //       if(this.submitCount === environment.otpValidationAttempts){
    //         const popUpData = this.config['maxAttemptPopUp'];
    //         const popUp = this._dialog.open(
    //           GeneralizedPopUpComponent,
    //           popUpData
    //         );
    //         popUp.afterClosed().subscribe((event) => {
    //           this._loadingService.closeLoadingIndicator();
    //           this._router.navigate([this.config["clickHereLink"]]);
    //         });
    //       } else {
    //         this._loadingService.closeLoadingIndicator();
    //       }
    //     } else if(error?.['error']?.['message'].toLowerCase() === 'no pending verification requests'){
    //       this._loadingService.closeLoadingIndicator();
    //     } else if (error?.['error']?.['message'] === 'Unknown error. Provider returned : TooManyRequests'){
    //       const popUpData = this.config['maxAttemptPopUp'];
    //       const popUp = this._dialog.open(
    //         GeneralizedPopUpComponent,
    //         popUpData
    //       );
    //       popUp.afterClosed().subscribe((event) => {
    //         this._loadingService.closeLoadingIndicator();
    //         this.dialogRef.close();
    //         this._router.navigate([this.config["clickHereLink"]]);
    //       });
    //     }
    //   });
    // }
  }

  sendEmailForQuickOTPRegistration() {
    const emailContent = this._appContext.get(
      'ecsrEmailContent'
    ) as EcsrEmailContent;
    let payload = {
      Subject: emailContent.subject,
      ToList: [this.userEmailAddress],
      TemplateName: null,
      SimpleBodyText: this.getEmailContent(emailContent),
      templateData: [],
      CCList: [environment.emailOTPQUickRegistration.cCList],
      APIKey: environment.emailOTPQUickRegistration.aPIKey,
      Identifier: environment.emailOTPQUickRegistration.identifier,
      FromId: environment.emailOTPQUickRegistration.fromId,
      Attachments: environment.emailOTPQUickRegistration.attachments,
      BCCList: [environment.emailOTPQUickRegistration.bCCList],
      Channel: environment.emailOTPQUickRegistration.channel,
    };
    // let simpleTextConetent = environment.emailOTPQUickRegistration.simpleBodyText + this.userRegistrationPin;
    // simpleTextConetent += '\r\nECSR registration link: ' + this.getEmailUrl();
    // payload["SimpleBodyText"] = simpleTextConetent;

    // to call an API to send email to user with 4 digit OTP and link to download mobile app
    this._service
      .ssSendEmailOTPQUickRegistrationCheck(payload, this.apiToken)
      .pipe(timeout(120000))
      .subscribe(
        (response) => {
          //To show a popup wich tells user that 4 digit OTP and link to download mobile app is sent
          this.dispatchOtpToStoreValue(false);
          const popUpData = this.ecsrConfig['fourDigitEmailPopUp'];
          const popUp = this._dialog.open(GeneralizedPopUpComponent, popUpData);
          popUp.afterClosed().subscribe((event) => {
            this._loadingService.closeLoadingIndicator();
            this.closeDialog();
            this._router.navigate([this.ecsrConfig['backToEcsrLoginLink']]);
          });
        },
        (error) => {
          const resendPopUpData = this.ecsrConfig['fourDigitEmailResendPopUp'];
          const resendPopUp = this._dialog.open(
            GeneralizedPopUpComponent,
            resendPopUpData
          );
          resendPopUp.afterClosed().subscribe((event) => {
            this.dispatchOtpToStoreValue(true);
            this._loadingService.closeLoadingIndicator();
            this.closeDialog();
          });
        }
      );
  }

  private getEmailContent(template: EcsrEmailContent): string {
    const base64EncodedData = btoa(
      JSON.stringify({
        userEmail: this.userEmailAddress,
        attemptId: this.userRegistrationAttemptId,
      })
    );

    const currentLang = this._router.url.split('/')[1];
    const redirectUrlEmail = `${window.location.protocol}//${window.location.host}/${currentLang}/registration/registrationMethodTwo/ecsr?data=${base64EncodedData}`;

    template.bodyHtml = template.bodyHtml.replace(
      '{OTP}',
      this.userRegistrationPin
    );
    template.bodyHtml = template.bodyHtml.replace(/{URL}/g, redirectUrlEmail);
    template.bodyHtml = template.bodyHtml.replace(
      '{SUPPORT}',
      template.customerSupportPhone
    );

    return template.bodyHtml;
  }
  dispatchOtpToStoreValue(value) {
    this.registrationDetailsAlternateObject['showOTP'] = value;
    this._store.dispatch(
      showOtpInRegisterationPage({
        payload: this.registrationDetailsAlternateObject,
      })
    );
  }

  registrationTokenAPI() {
    this._service
      .ssRegistrationTokenAPI()
      .pipe(take(1))
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.access_token !== undefined &&
            response.access_token !== ''
          ) {
            let authTokenVal =
              response.token_type + ' ' + response.access_token;
            this.registrationTokenBuilderAPI(authTokenVal);
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const popUpData = cloneDeep(this.serverError);
          const errorResponse = formatApiError(error);
          popUpData['data']['errorResponse'] = errorResponse;
          this._dialog.open(GeneralizedPopUpComponent, popUpData);
        }
      );
  }
  registrationTokenBuilderAPI(token) {
    const userDetails = this.registrationDetailsObject['userDetails'];
    const findCustomerResponse = this.registrationDetailsObject[
      'findCustomerResponse'
    ];
    console.log('findCustomerResponse---', findCustomerResponse);
    let isTrueGroup = '';

    if (
      findCustomerResponse !== undefined &&
      findCustomerResponse['IsTrueGroupMember'] !== undefined
    ) {
      if (findCustomerResponse['IsTrueGroupMember'] === true) {
        isTrueGroup = 'trueGroup';
      } else {
        isTrueGroup = '';
      }
    }
    let regTokeBuilderPayload = {
      ClientId: environment.registrationTokenBuilderConfig.clientId,
      B2CTenant: environment.registrationTokenBuilderConfig.tentantId,
      B2CPolicy: environment.registrationTokenBuilderConfig.policy,
      ReplyUrl: environment.registrationTokenBuilderConfig.replyUrl,
      Claim1Name: 'email',
      Claim1Value: this.selectedOption.value,
      Claim2Name: 'firstName',
      Claim2Value: userDetails['firstName'],
      Claim3Name: 'lastName',
      Claim3Value: userDetails['lastName'],
      Claim4Name: 'clientID',
      Claim4Value: environment.registrationTokenBuilderConfig.clientId,
      Claim5Name: 'extension_userType',
      Claim5Value: isTrueGroup,
    };
    sessionStorage.setItem('callSaveRegister', 'true');
    sessionStorage.setItem('RegisterId', findCustomerResponse['RegisterId']);
    if (findCustomerResponse['IsRegistered'] === true) {
      sessionStorage.setItem('IsReregister', 'true');
      sessionStorage.setItem(
        'RegisteredLoginId',
        this.selectedOption.value
      );
    } else {
      sessionStorage.setItem('IsReregister', 'false');
    }
    this._service
      .ssRegistrationTokenBuilderAPI(token, regTokeBuilderPayload)
      .pipe(timeout(120000))
      .subscribe(
        (response: any) => {
          if (
            response !== undefined &&
            response.TokenData !== undefined &&
            response.TokenData !== ''
          ) {
            this._loadingService.closeLoadingIndicator();
            this.authProvider = this._authProviderService.getRegistrationProvider();
            const path = this._router.url.split('/')[1];
            const extraQueryParameters = {
              id_token_hint: response.TokenData,
              ui_locales: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
              language: path === 'en-CA' ? 'en-CA' : path.substring(0, 2),
              prompt: 'login',
            };
            localStorage.setItem('language', path);

            this.closeDialog();
            const popUpData = this.registrationLinkSuccessConfig;
            const popUp = this._dialog.open(
              RegistrationSuccessInfoPopupComponent,
              popUpData
            );
            popUp.afterClosed().subscribe((event) => {
              this._loadingService.openLoadingIndicator({});
              this.authProvider.login(extraQueryParameters);
            });
          } else {
            this._loadingService.closeLoadingIndicator();
          }
        },
        (error) => {
          this._loadingService.closeLoadingIndicator();
          const popUpData = cloneDeep(this.serverError);
          const errorResponse = formatApiError(error);
          popUpData['data']['errorResponse'] = errorResponse;
          this._dialog.open(GeneralizedPopUpComponent, popUpData);
        }
      );
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
export interface EcsrEmailContent {
  subject: string;
  bodyHtml: string;
  customerSupportPhone: string;
}
