<style type="text/css">
  /* Responsive Font size with rem  starts
  // Base Font for HTML */
  :root {
      --base-font-size: 14px;
      --colorWhite: #ffffff;
      --colorDarkBlue: #003a63;
      --colorLightBlue: #106bad;
      --colorDarkBlack: #333333;
      --colorDarkGrey: #254f71;
      --colorDarkWhite: #f2f2f2;
      --colorRed: #ff0000;
      --colorPurple: #606FBE;
  }

  *,
  *::before,
  *::after {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -o-box-sizing: border-box;
      -ms-box-sizing: border-box;
      outline: none !important;
      -moz-outline: none;
      -o-outline: none;
      -ms-outline: none;
      -webkit-outline: none;
      margin: 0;
  }

  *,
  html,
  body {
      font-size: var(--base-font-size);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
  }

  * {
      margin: 0;
      padding: 0;
  }

  html,
  body {
      overflow-x: hidden;
      max-width: 1170px;
      margin: auto;
  }

  

  

  h1,
  .h1 {
      font-size: calc(1.375rem + 1.5vw);
  }

  @media (min-width: 1200px) {

      h1,
      .h1 {
          font-size: 2.5rem;
      }
  }

  h2,
  .h2 {
      font-size: calc(1.325rem + 0.9vw);
  }

  @media (min-width: 1200px) {

      h2,
      .h2 {
          font-size: 2rem;
      }
  }

  h3,
  .h3 {
      font-size: calc(1.3rem + 0.6vw);
  }

  @media (min-width: 1200px) {

      h3,
      .h3 {
          font-size: 1.75rem;
      }
  }

  h4,
  .h4 {
      font-size: calc(1.275rem + 0.3vw);
  }

  @media (min-width: 1200px) {

      h4,
      .h4 {
          font-size: 1.5rem;
      }
  }

  h5,
  .h5 {
      font-size: 1.25rem;
  }

  h6,
  .h6 {
      font-size: 1rem;
  }


  /* Responsive Font size with rem ends */

  /** common container styles with medias starts **/
  .container:before,
  .container:after {
      display: table;
      content: " ";
  }

  .container:after {
      clear: both;
  }

  .container {
      padding-right: 0.938rem;
      padding-left: 0.938rem;
      margin-right: 66px;
      margin-left: 80px;
      /* height: 561px; */
  }

  @media (max-width: 575px) {
            .container {
                margin-right: 0px !important;
                margin-left: 0px !important;
            }
        }
  @media (min-width: 576px) {
      .container {
          width: 33.75rem;
          height: auto;
      }
  }
  @media (min-width: 641px) {
            .container {
                width: 33.75rem;
                height: auto;
                margin: 0px 0px 0px 0px;
            }
        }

  @media (min-width: 768px) {
      .container {
          width: 45rem;
          /* height: 561px; */
          margin: 0px 0px 0px 60px;
      }
  }

  @media (min-width: 992px) {
      .container {
          width: 60rem;
          margin: 0px 0px 0px 60px;
      }
  }

  @media (min-width: 1200px) {
      .container {
          width: 75rem;
          margin: 0px 0px 0px 60px;
      }
  }

  @media (min-width: 1400px) {
      .container {
        width: auto;
        /* margin-left: 70px;
        margin-right: 70px; */
        margin: 0px 60px;
      }
  }

  /** common container styles with medias ends **/

  /* login styles starts */
  .login-page section {
      background: var(--colorDarkBlue);
      color: var(--colorWhite);
      position: relative;
      font-family: Gotham-Book;
      height : auto;
  }
  @media (min-width: 641px) {
      .login-page section {
        height: 561px;
      }
  }

  .banner-message {
    max-width: 600px;
    margin-bottom: 20px;
  }

   .bannerMessage {
        font-size: 16px;
        font-style: italic;
        padding: 10px 0;
        background-color: #ffc107;
        color: black;
        font-weight: 600;
        font-family: "Lato";
        line-height: 18px;
    }
    .systemDownContainer {
        font-family: 'Lato';
        max-width: 600px;
        line-height: 18px;
        margin-bottom: 10px;
    }

  .login-page section h2 {
      margin-bottom: 2rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }

  .login-page section h2 span {
      font-family: Gotham-Light;
      font-size: 2.5rem;
      line-height: 3.00rem;
  }

  .titleBold {
    font-family: "Lato" !important;
    font-size: 36px !important;
    color: #00B8B8 !important;
    letter-spacing: 0 !important;
    line-height: 38px !important;
    font-weight: 700 !important;
  }

  .title1 {
    font-family: "Lato" !important;
    font-size: 36px !important;
    color: #FFFFFF !important;
    letter-spacing: 0 !important;
    line-height: 38px !important;
    font-weight: 300 !important;
  }

  .login-page section h3 {
      margin-bottom: 2rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }

  .login-page section h3 span {
      font-family: Gotham-Bold;
      font-size: 1.375rem;
      line-height: 1.625rem;
      white-space: nowrap;
  }

  /* .login-page section p, */
  .login-page section label,
  .login-page section span,
  .login-page section a,
  .login-page section button {
        font-size: 14px;
        line-height: 1.125rem;
        font-family: "Lato";
  }
  .login-page section button span {
    font-weight: 700;
    letter-spacing: 2px;
  }

  .login-page section a, .login-page .forgot-email-container .forgot-email-label, .login-page .link-container .sign-up-link{
        text-decoration: underline;
        color: var(--colorWhite);
        cursor: pointer;
        font-family: "Lato";
        font-size: 14px;
        /* color: #FFFFFF; */
        letter-spacing: 0;
        text-align: right;
        line-height: 18px;
        font-weight: 400;
        /* margin-bottom: 3px */
  }

  .login-page .forgot-email-container .forgot-email-label-CA{
        text-decoration: underline;
        color: var(--colorWhite);
        cursor: pointer;
        font-family: "Lato";
        letter-spacing: 0;
        text-align: right;
        line-height: 18px;
        font-weight: 400;
        @media (max-width: 639px) {
            font-size: 10.5px !important;         
        }
        @media (min-width: 640px) {
            font-size: 14px !important;      
        }
  }

  .login-page .sign-up-link {
      margin-right: 20px;
    }

  .sign-up-text {
      font-family: "Lato";
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: right;
      line-height: 18px;
      font-weight: 400;
      /* margin-bottom: -8px; */
      margin-top: 7px;
      margin-right: 20px;
  }

  .login-page section header .logoContainer {
      display: table;
      padding: 1rem;
      background: var(--colorWhite);
      @media (max-width: 767px) {
            /* margin: 0px 40px;          */
            /* width: 400px; */
            margin: 0 auto; 
    }
  }
  .login-page section header .logo-fr {
     padding: 0;
  }
  .login-page section header .logoContainer img {
        @media (max-width: 767px) {
                width: 100%;          
        }
        @media (min-width: 768px) {
            width: 365px;       
        }
   }
  .login-page section .promo-banner {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 40%;
      height: 100%;
      /* border-right: 18px solid #00B8B8; */
  }

  .login-page section .promo-mobile-banner {
      display: none;
  }

  .login-page section main {
      padding-top: 4rem;
  }

  .login-page section main .login-form #api {
      min-height: 15vh;
      margin-top: 2rem;
  }

  .login-container #api .localAccount {
      width: 40%;
  }

  .login-container #api .localAccount .intro {
      margin-top: 2rem;
  }

  .login-container #api .entry-item {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 2rem;
      position: relative;
  }

  .login-container #api .loginFlexbox{
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      position: relative;
  }

  .login-container #api crux-form-builder {
    width: 100%;
  }

  .checkbox-div .checkbox-label {
        font-family: Gotham-Book;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: 400;
    }

    .mat-checkbox-inner-container {
        border: 1px solid #FFFFFF;
    }

    .mat-checkbox-frame {
        border-width: 0px;
    }

    .maintentanceMessage {
        font-family: "Lato" !important;
            font-size: 14px !important;
            color: #FFFFFF !important;
            letter-spacing: 0 !important;
            line-height: 18px !important;
            font-weight: 400 !important;
    }

  .login-container #api .forgot-email-container{
    position: absolute;
    right: 20px;
  }

  .login-container #api .crux-input--legacy .crux-input__field .mat-form-field-flex input {
      border: 0.063rem solid var(--colorWhite);
      height: 2.875rem;
      background: transparent;
      width: 100%;
      padding: 0.938rem;
      position: relative;
      border-radius: 0;
      font-family: "Lato";
      font-size: 16px;
      color: var(--colorDarkBlue);
      line-height: 1.5rem;
      font-weight: 400;
  }

  .login-container #api .crux-input--legacy .crux-input__field .mat-form-field-flex input:focus{
    border: 0 none !important;
    outline: 0 none !important;
  }

  .login-container #api .mat-form-field-label-wrapper{
    /* display: none; */
    font-family: "Gotham Book" !important;
    font-size: 13px;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    margin-left: 15px;
    margin-top: 5px;
    width: -webkit-fill-available;
    text-transform: capitalize;
  }

  .login-container #api .crux-form-builder__component--column {
    margin-right: 0;
    width: 100%;
    padding-bottom: 2rem;
  }

  .login-container #api .mat-form-field-appearance-legacy .mat-form-field-infix,
  .login-container #api .crux-input--legacy .crux-input__field .mat-form-field-flex,
  .login-container #api .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding: 0;
      width: 97.5%;
      border-radius: 0;
  }

  .login-container #api .entry-item input#password {
      background: var(--colorWhite);
      color: var(--colorDarkGrey);
  }

  .login-container #api mat-label {
      /* font-family: Gotham-Book;
        font-size: 0.875rem;
        color: var(--colorWhite);
        line-height: 1.25rem;
        font-weight: 400; */
        font-family: "Lato";
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
        font-weight: 400;
  }
  .login-container #api .loginFlexboxCA .crux-input--legacy .crux-input__label{
    padding-bottom: 5px !important;
    font-size: 16px !important;
    color: #4b4e52 !important;
  }

  .login-container #api .loginFlexboxCA mat-label {
      
        font-family: "Lato";
        
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
        font-weight: 400;
        @media (max-width: 639px) {
            font-size: 10.5px !important;         
        }
        @media (min-width: 640px) {
            font-size: 14px !important;      
        }
  }

  .login-container #api .button-flex {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin-bottom: 153px;
  }

  .login-container #api .buttons {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin: 1rem 0;
  }

  .login-container #api .buttons button {
      border: 0 none;
      color: var(--colorWhite);
      padding: 1rem 2.5rem;
      font-size: 0.875rem;
      letter-spacing: 0.063rem;
      text-align: center;
      font-family: Gotham-Medium;
      box-shadow: none;
      border-radius: 0;
      background: var(--colorPurple);

  }

  .login-container #api .buttons button[disabled] {
        background: #C3C7CB;
  }
  .login-container #api .buttons button:focus {
    border: 0 none !important;
  }

  .login-container #api .divider {
      margin-top: 2rem;
  }

  .login-container #api .divider h2 {
      font-size: 0.875rem;
  }

  .login-container #api .create {
      font-family: Gotham-Book;
      font-size: 0.875rem;
      color: var(--colorWhite);
      letter-spacing: 0;
      line-height: 1.125rem;
      font-weight: 400;
  }

  .login-container #api .create p #createAccount {
      padding-left: 0.375rem;
  }

  .login-container #api .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
      top: 3rem;
  }

  .login-container #api .mat-error {
      font-size: 0.875rem;
      color: var(--colorRed);
      line-height: 1.125rem;
      font-family: "Lato";
  }

  .login-container #api .error.pageLevel {
      margin-bottom: 1rem;
  }

  .login-container #api .error.itemLevel {
      position: absolute;
      bottom: -1.5rem;
  }

  .login-page section main .login-form .app-links {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: flex-end;
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end;
  }

  .login-page section main .login-form .app-links .mobileApp-download {
      border-top: 0.063rem solid rgba(219, 219, 219, 0.5);
      margin-top: 4rem;
      width: 60%;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin-top: 2rem;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .qr-img {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding-bottom: 2rem;      
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .mobile-apps {
      display: none;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .scanimg {
      width: 5.313rem;
      object-fit: contain;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-promo .mobile-img {
      width: 14.625rem;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-text {
      margin-left: 2rem;
      margin-right: 2rem;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-text p{
    width: 200px;
    word-break: break-word;
  }
  
  .login-page section main .login-form .app-links .mobileApp-download .qr-text .double-arrow {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;      
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
  }

  .login-page section main .login-form .app-links .mobileApp-download .qr-text .double-arrow img:last-child{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .login-page section main .login-form .app-links a {
      margin-bottom: 4rem;
  }

  .login-container #api .localAccount .intro h2, .login-container #api .localAccount .divider , .login-container #api .localAccount .create {
      display: none;
  }

  /* footer style starts */

  .footerMainContainer .footer_container {
      margin: 0 auto;
      width: 100%;
      background-color: white;
      color: var(--colorLightBlue);
      height: auto;
      padding-top: 0.625rem;
  }

  .footerMainContainer .footer_container__getInTouchSection {
      background-color: var(--colorDarkWhite);
      height: 4.375rem;
      /* display: none; */
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      margin: 0 auto;
      padding-top: 1.25rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__leftSection .headingContainer .headingText {
      font-family: Gotham-Light;
      font-size: 1.375rem;
      color: var(--colorGrey);
      letter-spacing: 0;
      line-height: 1.875rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList {
      margin-right: 3.125rem;
      min-width: 10rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .leftListImg {
      margin-right: 0.625rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .leftListImg img {
      width: 1.25rem;
      height: 1.25rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .leftListImg .rightListImg0 {
      width: 1.438rem;
      height: 0.938rem;
      padding-top: 0.375rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .rightTextContainer {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      position: relative;
      top: 0.375rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .rightTextContainer .text {
      font-family: Gotham-Book;
      font-size: 1rem;
      color: var(--colorGrey);
      line-height: 1.063rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .rightTextContainer .text {
      font-family: Gotham-Book;
      font-size: 1rem;
      color: var(--colorGrey);
      line-height: 1.063rem;
  }

  .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer .rightTextContainer .rightText2 {
      margin-left: 0.375rem;
  }

  .footerMainContainer .footer_container__mainDiv__logo {
      margin: 0.938rem 0 0.625rem 0;
  }

  .footerMainContainer .footer_container__mainDiv__logo .logoDiv {
      color: var(--colorWhite);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;      
        }
      
  }

  .footerMainContainer .footer_container__mainDiv__logo .logoDiv .logo {
      
        @media (max-width: 767px) {
            /* width: 19.5rem;       */
            width: 300px;      
        }
        @media (min-width: 768px) {
            width: 12.5rem;      
        }
  }

  .footerMainContainer .footer_container__mainDiv__logo .logoDiv .logoLeftCA {
      
      @media (min-width: 768px) {
            width: 12.5rem;
            margin-left: -15px;    
        }
  }

  .footerMainContainer .footer_container__mainDiv__logo .logoDiv .logo-left img {
      
        @media (max-width: 767px) {
                width: 100%;          
        }
        @media (min-width: 768px) {
            width: 23.125rem;      
        }
  }
  .footerMainContainer .footer_container__mainDiv__logo .logoDiv .logo-right {
            display: block;
            background: #C3C7CB;
             /* height: auto !important; */
             height: 47px !important;
            padding: 10px 0 10px 10px;
            margin-top: 0px;
            border: 1px solid 4B4E52;
            
            @media screen and (max-width: 767px) {
              padding: 3px 0 10px 10px;
              margin-top: 10px;
              width: 165px;
            align-self: flex-end;
            }
            @media screen and (min-width: 768px) {
              margin-left: 200px;
            }
            .verticalImage {
              width: 5px;
              height: 25px;
            }
            .country-selector {
              display: flex;
              align-items: center;
              flex-direction: row;
              cursor: pointer;
              width: 150px;
              @media screen and (max-width: 767px) {
                height: 42px;
              }
              .spanLabel {
                color: #4B4E52;
                width: 93%;
                font-family: "Lato"
              }
            }
            /* .country-selector:focus {
              display: flex;
              border: 1px solid #007bff;
              outline: 3px solid #FFFFFF;
              border-radius: 5px;
              margin-left: -10px;
              margin-top: -10px;
              padding-bottom: 18px;
              align-items: center;
              flex-direction: row;
              cursor: pointer;
              .spanLabel {
                color: #4b4e52;
                width: 93%;
                margin: 12px -12px 2px 10px;
                font-family: "Lato"
              }
            } */
          }

  .footerMainContainer .footer_container__mainDiv__content {
      /* font-size: 0.75rem;
        font-family: Gotham-Light;
        margin: 0.938rem 0 0.5rem 0;
        padding-bottom: 0.938rem;
        letter-spacing: 0;
        text-align: left;
        line-height: 0.938rem; */
        font-family: "Lato";
        font-size: 12px;
        color: #003A63;
        letter-spacing: 0;
        line-height: 15px;
        font-weight: 400;
        margin: 15px 0px 10px 0px;
  }

  .footerMainContainer .border {
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .footerMainContainer .footer_container__mainDiv__link {
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .footerMainContainer .footer_container__mainDiv__link a {
    font-family: "Lato";
      font-size: 14px;
      color: #003A63;
      letter-spacing: 0;
      text-decoration: none;
      font-weight: 400;
      /* @media screen and (max-width: 639px){
        line-height: 20px;
        }
    @media screen and (min-width: 640px){ */
        line-height: 20px;
        display: inline-block;
        vertical-align: top;

    /* } */

  }
  .footerMainContainer .footer_container__mainDiv__link a:hover {
    text-decoration: underline;
  }


  .footerMainContainer .footer_container__mainDiv__link a .separatorAlign {
    
    /* @media screen and (max-width: 639px){
            display: none;
        }
    @media screen and (min-width: 640px){ */
        margin: 0 5px 0 5px;
        /* } */
  }
  
/* .footerMainContainer .footer_container__mainDiv__link span {
    
    @media screen and (max-width: 640px){
        display: block;
    }
} */


  /* footer style ends */

  /* media queries starts */
  @media (max-width: 1199px) {
      .login-page section .promo-banner{
          width: 40%;
      }
      .login-container #api .localAccount {
          width: 65%;
      }
      body.isMobileApp{
          background: var(--colorDarkBlue);
      }
      body.isMobileApp .login-page section main .login-form .app-links, body.isMobileApp footer {
          display: none;
      }
  }

  @media (max-width: 767px) {
      .login-page section h3 span {
          white-space: pre-wrap;
      }

      .login-container #api .localAccount {
          width: 100%;
      }

      .login-page section main .login-form .app-links {
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          position: relative;
      }

      .login-page section main .login-form .app-links .mobileApp-download {
          width: 100%;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo {
          position: relative;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg {
          width: 60%;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .mobile-img {
          position: absolute;
          right: 0;
          bottom: 0;
      }

      .login-page section main .login-form .app-links a {
          position: absolute;
          bottom: 1rem;
          left: -5rem;
          right: 0;
          margin: 0 auto;
          text-align: center;
      }
    
      .login-page section main .login-form .app-links .mobileApp-download .qr-text p {
            width: 100px;
      }

    .login-page section main .login-form .app-links .mobileApp-download .qr-promo .mobile-img {
        width: 11.625rem;
    }

      .footerMainContainer .footer_container__getInTouchSection {
          height: 100%;
          width: 100%;
      }

      .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground {
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
      }

      .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection {
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
          margin-top: 0.938rem;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
      }

      .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer {
          padding-bottom: 0.938rem;
      }
  }

  @media (max-width: 640px) {

      .login-page section h3 span {
          font-size: 0.875rem;
          line-height: 1.125rem;
          text-align: left;
          white-space: nowrap;
      }

      .login-page section header {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          -ms-justify-content: center;
          justify-content: center;
      }

      .login-page section main {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -moz-align-items: center;
          -ms-align-items: center;
          align-items: center;
          -webkit-flex-direction: column-reverse;
          -moz-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
      }

      .login-page section main .login-form {
          margin-top: 2rem;
          max-width: 22.5rem;
      }

      .login-container #api .localAccount {
          margin-bottom: 2rem;
      }

      .login-container #api .localAccount .intro,
      .login-container #api .create {
          margin-top: 2rem;
      }

      .login-container #api .buttons {
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
      }

      .login-container #api .crux-form-builder__component--column{
          padding-bottom: 2.5rem;
      }

      .login-container #api .button-flex{
        -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
      }
      .login-page section main .login-form .app-links {
          -webkit-flex-direction: column-reverse;
          -moz-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
      }

      .login-page section main .login-form .app-links a {
          position: static;
          margin: 0;
      }

      .login-page section main .login-form .app-links .mobileApp-download {
          margin-top: 2rem;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .qr-img {
          display: none;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .mobile-apps {
          display: block;
          text-align: left;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .qr-msg .qr-code .mobile-apps img {
          border: 0.063rem solid var(--colorWhite);
          border-radius: 0.313rem;
          margin-bottom: 1rem;
      }

      .login-page section main .login-form .app-links .mobileApp-download .qr-promo .mobile-img {
          width: 10rem;
      }

      .login-page section .promo-banner {
          display: none;
      }

      .login-page section .promo-mobile-banner {
          display: block;
      }

      .footerMainContainer .footer_container__getInTouchSection {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
      }

      .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground {
          -webkit-align-items: flex-start;
          -moz-align-items: flex-start;
          -ms-align-items: flex-start;
          align-items: flex-start;
          -webkit-justify-content: flex-start;
          -moz-justify-content: flex-start;
          -ms-justify-content: flex-start;
          justify-content: flex-start;
      }

      .footerMainContainer .footer_container__getInTouchSection .getInTouchBackground .footer_container__getInTouchSection__rightSection .rightList .rightListContainer {
          -webkit-justify-content: flex-start;
          -moz-justify-content: flex-start;
          -ms-justify-content: flex-start;
          justify-content: flex-start;
      }

      .separatorAlign {
        margin-left: 3px;
      }
  }

  /* media queries ends */

  /* login styles ends */
</style>
<div class="login-page">
  <section>
      <div class="container">
          <header>
              <div class="logoContainer" [ngClass]="config?.country === 'fr-CA' ? 'logo-fr' : ''">
                  <!-- <img src="https://my-sit.combinedinsurance.com/assets/Combined-Logo_Blue.png"
                      alt="Combined Logo"> -->
                      <img src="{{config?.logo}}" alt="Combined Logo">
              </div>
          </header>
          <main>
              <div class="login-form">
                <div class="banner-message" *ngIf="systemDownFlag === true && systemDownData?.module?.main?.downtime?.trim() !== '' && systemDownData?.module?.main?.msg !== ''">
                    <p class="bannerMessage" *ngIf="config?.country === 'en-CA' || config?.country === 'en-US'">
                        {{systemDownData?.bannerMessage?.msgEN}}
                    </p>
                    <p class="bannerMessage" *ngIf="config?.country === 'es-US'">
                        {{systemDownData?.bannerMessage?.msgES}}
                    </p>
                    <p class="bannerMessage" *ngIf="config?.country === 'fr-CA'">
                        {{systemDownData?.bannerMessage?.msgFR}}
                    </p>
                </div>
                <div class="systemDownContainer" *ngIf="systemDownFlag === true && systemDownData?.module?.main?.downtime?.trim() !== ''">
                {{systemDownText?.systemDownMsgWithTime1}}{{systemDownData?.module?.main?.downtime?.trim()}}{{systemDownText?.systemDownMsgWithTime2}}
                </div>
                <div class="systemDownContainer" *ngIf="systemDownFlag === true && systemDownData?.module?.main?.downtime?.trim() === ''">
                {{systemDownText?.systemDownMsg}}
                </div>
                <h2>
                    <span class="title1">{{config?.title1}}</span>
                    <!-- <span class="titleBold">{{config?.midTitle}}</span> -->
                    <span class="titleBold">{{config?.title2}}</span>
                </h2>
                <div class="login-message">
                    <p class="maintentanceMessage">
                        {{config.subHeading}}
                    </p>
                </div>
                  <div class="login-container">
                      <div id="api" class="login-formcontrol">
                        <div class="localAccount messageLoginContainer">
                          <div class="loginContainer">
                              <div class="loginFormContainer" *ngIf="systemDownFlag === false">
                                  <div class="loginFlexbox" [ngClass]="(navbarConfig?.country === 'fr-CA' || navbarConfig?.country === 'es-US') ? 'loginFlexboxCA' : ''">
                                    <crux-form-builder [config]="formBuilderConfig" [formGroup]="formGroup" (valueChange)="valueChange($event)"></crux-form-builder>
                                    <div class="forgot-email-container">
                                      <span class="forgot-email-label" [ngClass]="(navbarConfig?.country === 'fr-CA' || navbarConfig?.country === 'es-US') ? 'forgot-email-label-CA' : ''" (click)="onClickSignUp('forgot')" appSharedNew tabindex="0" role="button">
                                        {{config.forgotEmailLabel}}
                                      </span>
                                    </div>
                                  </div>
                                  <!-- <div class="checkbox-div">
                                    <crux-checkbox #checkBox ngDefaultControl (change)="getCheckboxValue($event)" labelPosition="after" class="checkbox">
                                        <span class="checkbox-label" [innerHTML]="config.formConfig.rememberMeLabel"></span>
                                    </crux-checkbox>
                                </div> -->
                                  <div class="button-flex">
                                    <div class="buttons">
                                        <crux-button (click)="OnSubmit()" mode="primary" [disabled]="isDisabled">
                                            {{config?.buttonLabel}}
                                        </crux-button>
                                    </div>
                                    <div class="link-container">
                                        <div class="sign-up-text">
                                            {{config?.signUpText}}
                                        </div>
                                        <!-- <br> -->
                                        <div class="sign-up-link" (click)="onClickSignUp('signup')" appSharedNew tabindex="0" role="button">
                                          {{config?.signUpLink}}
                                        </div>
                                    </div>
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                      </div>
                      <!-- <div class="app-links">
                          <div class="mobileApp-download">
                              <div class="qr-promo">
                                  <div class="qr-msg">
                                      <h3>
                                        <span>{{config.mobileSection.heading1}}</span>
                                        <span>{{config.mobileSection.heading2}}</span>
                                      </h3>
                                      <div class="qr-code">
                                          <div class="qr-img">
                                              <img class="scanimg"
                                                  src="/assets/Login_QR-code_retina.png"
                                                  alt="App Screenshot">
                                              <div class="qr-text">
                                                <p>{{config?.mobileSection.subHeading}}</p>
                                                   <div class="double-arrow">
                                                      <img src="/assets/Arrow_illustration_orange.svg"
                                                      alt="arrowImgText">
                                                      <img src="/assets/Arrow_illustration_orange.svg"
                                                      alt="arrowImgText">
                                                   </div>
                                              </div>
                                              <img class="scanimg"
                                                  src="/assets/Login_QR-code_googleplay_retina.png"
                                                  alt="App Screenshot">
                                          </div>
                                          <div class="mobile-apps">
                                            <a href="https://play.google.com/store/apps/details?id=com.chubb.mycombined&pli=1">
                                              <img src="/assets/googlePlay.png"
                                                  alt="Google Play store Link button">
                                            </a>
                                            <a href="https://appstore.com/mycombined">
                                            <img src="/assets/appleStore.png"
                                            alt="Apple store Link button">
                                            </a>

                                          </div>
                                      </div>
                                  </div>
                                  <img class="mobile-img"
                                      src="/assets/Login_iphone-mockup_retina.png"
                                      alt="App Screenshot">
                              </div>
                          </div>
                          <a href="#">Quick Links</a>
                      </div> -->
                  </div>
              </div>
              <img class="promo-banner" src="{{loginImage}}"
                  alt="ForeGround Image" />
              <img class="promo-mobile-banner"
                  src="{{loginImage}}"
                  alt="ForeGround Image" />
          </main>
      </div>
  </section>
  <footer class="footer">
      <div class="login-footer">
          <div class="footerMainContainer">
            <!-- Code was already commented(Start)-->
              <!-- <div class="footer_container__getInTouchSection">
                  <div class="container getInTouchBackground">
                      <div class="footer_container__getInTouchSection__leftSection">
                          <div class="headingContainer"><span class="headingText">Get in touch</span></div>
                      </div>
                      <div class="footer_container__getInTouchSection__rightSection">
                          <div class="rightList">
                              <div class="rightListContainer">
                                  <div class="leftListImg"><img class="rightListImg0"
                                          src="https://my-sit.combinedinsurance.com/assets/OC_Combined_CX_assets_footer_envelope@2x.png"
                                          alt="Email Icon"></div>
                                  <div class="rightTextContainer"><span class="text rightText1"></span><span
                                          role="button" class="text rightText2 addCursor">Send us a message</span>
                                  </div>
                              </div>
                          </div>
                          <div class="rightList">
                              <div class="rightListContainer">
                                  <div class="leftListImg"><img class="rightListImg1"
                                          src="https://my-sit.combinedinsurance.com/assets/OC_Combined_CX_assets_footer_call@2x.png"
                                          alt="Phone Icon"></div>
                                  <div class="rightTextContainer"><span class="text rightText1">Call us : </span>
                                      <span role="button" class="text rightText2"></span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->
              <!-- Code was already commented(End)-->
              <div class="footer_container">
                  <div class="container footer_container__mainDiv">
                      <div class="footer_container__mainDiv__logo">
                          <div class="logoDiv">
                              <div class="logo logo-left" [ngClass]="navbarConfig.country === 'fr-CA' ? 'logoLeftCA' : ''">
                                <img src="{{config.footerLogo}}"
                                      alt="Combined Insurance logo">
                              </div>
                              <div class="logo logo-right" *ngIf="footerConfig.languagePopUp.isShow">
                                <span class="country-selector" (click)="onCountrySelection()" appSharedNew tabindex="0" role="button">
                                    <span class="spanLabel">{{footerConfig.languagePopUp.label}}</span>
                                    <span class="spanImage"><img class="verticalImage" src="{{footerConfig.languagePopUp.selectorIcon}}" alt="{{footerConfig.languagePopUp.altSelectorIcon}}" /></span>
                                </span>
                             </div>
                          </div>
                      </div>
                      
                      <div class="footer_container__mainDiv__content">{{config.footerText}}
                        <div class="footer_container__mainDiv__content" style="margin: 0" *ngIf="!(navbarConfig.country === 'en-CA' || navbarConfig.country === 'fr-CA')">{{config.footerSubText}}</div>
                      </div>
                      <div class="border"></div>
                      <div class="footer_container__mainDiv__link">
                        <span *ngFor="let link of footerConfig.footerLinkList;let i=index">
                            <a (mousedown)="$event.preventDefault()" target="_blank" href="{{link.path}}">{{link.name}}<span class="separatorAlign" *ngIf="i != footerConfig.footerLinkList.length-1">|</span></a>    
                        </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</div>
